import Papa from "papaparse";
import { setSales, setPurchases, setContacts, setInventory, LIST_INVENTORY, LIST_PERSON, LIST_SALES, LIST_PURCHASES, isPurchase, setLastSalesID, data, contacts, salesHistory, purchasesHistory, contains, setTempDB, LIST_LOG, LIST_CAT, LIST_SHOP, updateCategories, setItemImage, LIST_KURIR, mprint, addMPrint, LIST_PO, isPO, printSaleDone, FEE, tagAdjusted, getSaleFromClientShopee, getSaleTokopedia, LIST_GHEIM, LIST_BIASINVENTORY, LIST_BIASSALES, inventoryID, isBias, updateLabel, LIST_NOTES, notes, existsNotes, konten, shops, LIST_USER, printSimpleDate, printSalesDone, printmode, getSaleLazada, LIST_INVOICES, existsInvoice, invoices } from "./DataFunctions";
import { Item, Person, Sale, Shop, Category, SalesItem, courier, SHOPEE, TOKOPEDIA, UserL, DEV, DEVP, USER_SAVED, Gheim, BIAS, Note, OFFLINE, LAZADA, Invoice } from "./DataModel";
import PouchDB from 'pouchdb';
import htmlToImage from 'html-to-image';
import { konto, isKonto } from "./Log";
import { user } from "../components/AppContextProvider";
import { isPlatform } from "@ionic/react";
import { SocialSharing } from '@ionic-native/social-sharing';
import { downloadShare, generateReceiptLabel, printBT } from "./Output";
import { STR_ErrorNeedLogin, STR_ErrorTruncate, STR_MyID, STR_upload, STR_UserSaved } from "../lang/en";
import { setSavedUser, setUsers } from "../components/Login";
import { updateTransactionTab } from "../pages/Tab2";
import { noteImageID } from "../components/NoteCard";
import { updateList } from "../components/List";
import { invLogoID } from "../components/InvoiceCard";

const location = /*isPlatform('hybrid') ? */'192.168.100.27' /*'192.168.100.37' : window.location.hostname*/;
const port = '6984'; //'5984';

const DB_SALES = 'sales';
const DB_PO = 'po';

var localSetting = makelocalDB('setting');

var localGheim = makelocalDB('gheim');
var remoteGheim = makeremoteDB('gheim');

var localUser = makelocalDB('user');
var remoteUser = makeremoteDB('user');

var localDB = makelocalDB('inventory');
var remoteDB = makeremoteDB('inventory');

var localContacts = makelocalDB('contacts');
var remoteContacts = makeremoteDB('contacts');

var localSales = makelocalDB(DB_SALES);
var remoteSales = makeremoteDB(DB_SALES);

var localPurchases = makelocalDB('purchases');
var remotePurchases = makeremoteDB('purchases');

var localCategory = makelocalDB('category');
var remoteCategory = makeremoteDB('category');

var localKonten = makelocalDB('konten');
var remoteKonten = makeremoteDB('konten');

var localShop = makelocalDB('shop');
var remoteShop = makeremoteDB('shop');

var localKurir = makelocalDB('kurir');
var remoteKurir = makeremoteDB('kurir');

var localPO = makelocalDB(DB_PO);
var remotePO = makeremoteDB(DB_PO);

var localBiasDB = makelocalDB('bias-inventory');
var remoteBiasDB = makeremoteDB('bias-inventory');

var localBiasSales = makelocalDB('bias-' + DB_SALES);
var remoteBiasSales = makeremoteDB('bias-' + DB_SALES);

var localNotes = makelocalDB('notes');
var remoteNotes = makeremoteDB('notes');

var localInvoices = makelocalDB('invoices');
var remoteInvoices = makeremoteDB('invoices');

const limit = 2000;

function makelocalDB(name: string) {
  return new PouchDB('mekuya' + STR_MyID + name, { auto_compaction: true });
}

function makeremoteDB(name: string) {
  return new PouchDB('https://' + location + ':' + port + '/' + name);
}

export function truncateLogs() {
  try {
    for (var i = 0; i < shops.length; i++) {
      truncate(shops[i]);
    }
    for (var i = 0; i < konten.length; i++) {
      truncate(konten[i]);
    }
    saveToDB(shops, LIST_SHOP);
    saveToDB(konten, LIST_LOG);
  } catch (error) {
    alert(STR_ErrorTruncate);
  }
}

function truncate(konto: Shop | konto) {
  konto.trx.splice(limit, konto.trx.length - limit);
  konto.transfer.splice(limit, konto.transfer.length - limit);
}

export function cleanSalesHistory() {
  try {
    checkLogIn().then(() => {
      if (user.isLoggedIn) {
        user.setLoading(true);
        backup();

        remoteSales.destroy().then(function () {
          localSales.destroy().then(function () {
            localSales = makelocalDB(DB_SALES);
            remoteSales = makeremoteDB(DB_SALES);

            var remoteBUSales = makeremoteDB(DB_SALES + '_bu');

            remoteBUSales.allDocs({
              include_docs: true,
              descending: true,
              limit: 10000
            }).then(function (result) {
              let list: any[] = [];
              result.rows.forEach(e => {
                list.push(e.doc);
              });
              setTempDB(list, LIST_SALES);
              saveToDB(list, LIST_SALES).then(() => { user.setLoading(false); console.log("Sales limited to 10.000 entry") });
            });
          }).catch(function (err) {
            user.setLoading(false);
            alert("destroy : " + err);
          })
        }).catch(function (err) {
          user.setLoading(false);
          alert("destroy : " + err);
        })
        /*remoteSales.destroy().then(function () {
          localSales.destroy().then(function () {
            localSales = makelocalDB(DB_SALES);
            remoteSales = makeremoteDB(DB_SALES);
            var localYDB = makelocalDB(DB_SALES + new Date().getFullYear());
            localYDB.replicate.to(localSales).on('error', function (err) {
              alert("replicate : " + err);
            }).then(() => getDB(LIST_SALES)).then(() => {
              console.log("Sales only " + new Date().getFullYear())
              uploadDB(LIST_SALES)?.then(() => { user.setLoading(false); console.log("Sales saved") });
            });
          }).catch(function (err) {
            user.setLoading(false);
            alert("destroy : " + err);
          })
        }).catch(function (err) {
          user.setLoading(false);
          alert("destroy : " + err);
        })*/
      }
      else alert('Please login first.');
    });
  } catch (error) {
    alert("clean : " + error);
  }
}

export function showPrevYear(year: number) {
  checkLogIn().then(() => {
    if (user.isLoggedIn) {
      user.setLoading(true);
      var remoteYDB = makeremoteDB(DB_SALES + year);
      localSales.replicate.from(remoteYDB)
        .on('error', function (err) {
          user.setLoading(false);
          alert("repl : " + err);
        }).then(() => getDB(LIST_SALES)).then(() => { user.setLoading(false); alert("Sales " + year + " added") });
    }
    else user.setLogin(true);
  });
}

function getSalesYear(year: number) {
  let list: any[] = [];
  var localYDB = makelocalDB(DB_SALES + year);
  var remoteYDB = makeremoteDB(DB_SALES + year);
  return localYDB.replicate.from(remoteYDB)
    .on('error', function (err) {
      alert("repl : " + err);
    }).then(function () {
      return localYDB.allDocs({
        include_docs: true,
      }).then(function (result) {
        result.rows.forEach(e => {
          list.push(e.doc);
        });
        return list.reverse();
      });
    })
}

export function makeSalesYearArchiv(year: number, sales: Sale[]) {
  var localYDB = makelocalDB(DB_SALES + year);
  var remoteYDB = makeremoteDB(DB_SALES + year);
  var list: Sale[] = [];
  for (var i = 0; i < sales.length; i++) {
    let date = new Date(sales[i].date);
    if (date.getFullYear() < year) break;
    else if (date.getFullYear() === year) {
      let e = sales[i];
      (e as any)._rev = '';
      list.push(e);
    }
  }
  localYDB.bulkDocs(list).then(function (result) {
    localYDB.info().then(function (result) {
      console.log('local saved: ' + result.doc_count);
    }).catch(function (err) {
      alerterrorUpload("check : ", err);
    });
    localYDB.replicate.to(remoteYDB)
      .on('error', function (err) {
        alerterrorUpload("repl : ", err);
      }).then(() => alert("Sales " + year + ': ' + list.length + " saved"));
  }).catch(function (err) {
    alerterrorUpload("bulkwrite : ", err);
  });
}

export const SEPARATOR = '#';

export const BOOK_ABENG = 0;
export const BOOK_KING = 1;
export const BOOK_BABY = 2;
export const BOOK_DE = 3;

export const itemImageID = "pimg";

export function login(userName: string, password: string) {
  user.setLoading(true);
  return fetch('https://' + location + ':' + port + '/_session', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'content-type': 'application/json',//'application/x-www-form-urlencoded',
      authorization: `Basic ${btoa(userName + ':' + password)}`
    },
    body: JSON.stringify({ name: userName, password: password }) //'name='+userName+'&password='+password
  })
}

export function isLogged() {
  user.setLoading(true);
  return checkLogIn().then(() => user.setLoading(false));
}

export function checkLogIn() {
  return remoteCategory.info().then((function (info) {
    if ('error' in info) {
      user.setIsLoggedIn(false);
      return false;
    }
    else {
      user.setIsLoggedIn(true);
      return true;
    }
  })).catch(err => {
    user.setIsLoggedIn(false);
    user.setLoading(false);
    return false;
    //console.error(JSON.stringify(err));
  });
}

function alerterrorUpload(info: string, err: any) {
  user.setIsLoggedIn(false);
  user.setLogin(true);
  //alert(info + ' ' + err);
}

function toCSV(data: any[], id: number) {
  switch (id) {
    case LIST_INVENTORY: {
      data = data.map(item => ({
        _id: item._id,
        ProductName: item.ProductName,
        Quantity: item.Quantity,
        PurchasePrice: item.PurchasePrice,
        SalePrice: item.SalePrice,
        Reserve: item.Reserve,
        AdditionalSalesPrices: item.AdditionalSalesPrices,
        Info: item.Info,
        Category: item.Category,
        MSRPPrice: item.MSRPPrice,
        description: item.description,
        dimensionP: item.dimensionP,
        dimensionL: item.dimensionL,
        dimensionT: item.dimensionT,
        weight: item.weight
      }))
      break;
    }
    case LIST_PERSON: {
      data = data.map(p => ({
        _id: p._id,
        name: p.name,
        telp: p.telp,
        address: p.address,
        info: p.info,
        staff: p.staff
      }))
      break;
    }
    case FEE: {
      data = data.map(p => ({
        Username: p.Username,
        Ongkir: p.Ongkir,
        Administrasi: p.Administrasi,
        Layanan: p.Layanan,
        Total: p.Total
      }))
      break;
    }
    case FEE + 100: {
      data = data.map(p => ({
        CustomerName: p.CustomerName,
        Courier: p.Courier,
        Fee: p.Fee,
        TotalFee: p.TotalFee,
        Total: p.Total,
        AWB: p.AWB,
        BebasOngkir: p.BebasOngkir
      }))
      break;
    }
    default: break;
  }

  var csv = Papa.unparse(data);
  return csv;
}

function extractData(res: string) {
  const json = Papa.parse(res, {
    header: true,
    transformHeader: h => h.trim(),
    skipEmptyLines: true
  });
  return json.data;
}

function extractFromFile(file: File, id: number, shop: string) {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      transformHeader: h => h.trim(),
      skipEmptyLines: true,
      complete: function (results) {
        switch (id) {
          case LIST_INVENTORY: {
            resolve(updateInventory(results.data));
            break;
          }
          case LIST_PERSON: {
            resolve(updateContacts(results.data));
            break;
          }
          case LIST_PURCHASES: {
            resolve(updatePurchases(results.data));
            break;
          }
          case LIST_SALES: {
            resolve(updateSales(results.data));
            break;
          }
          case FEE: {
            resolve(updateFee(results.data, shop));
            break;
          }
          default: {
            console.log(results.data);
            break;
          }
        }
      }
    });
  });
}

function openFile(file: File, id: number) {
  new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onloadend = function (evt) {
      let csv = evt.target?.result as string;
      switch (id) {
        case LIST_PERSON: {
          resolve(updateContacts(extractData(csv)));
          break;
        }
        case LIST_PURCHASES: {
          resolve(updatePurchases(extractData(csv)));
          break;
        }
        case LIST_SALES: {
          resolve(updateSales(extractData(csv)));
          break;
        }
        default: {
          resolve(updateInventory(extractData(csv)));
          break;
        }
      }
    };
    reader.readAsText(file);
  })
}

export function getCSV(id: number) {
  var filename = '';
  var list: any[];
  switch (id) {
    case LIST_PERSON: {
      filename = "Contacts.csv";
      list = contacts;
      break;
    }
    case LIST_PURCHASES: {
      filename = "Purchases.csv";
      list = purchasesHistory;
      break;
    }
    case LIST_SALES: {
      filename = "Sales.csv";
      list = salesHistory;
      break;
    }
    default: {
      filename = "Inventory.csv";
      list = data;
      break;
    }
  }
  const csvContent = toCSV(list, id);
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const base64 = csvContent;

  return { filename: filename, blob: blob, base64: base64 };
}

function toArrayBuffer(canvas: HTMLCanvasElement) {
  // helper method to move current buffer position
  function setU16(data: number) { view.setUint16(pos, data, true); pos += 2 }
  function setU32(data: number) { view.setUint32(pos, data, true); pos += 4 }

  if (canvas) {
    var w = canvas.width,
      h = canvas.height,
      w4 = w * 4,
      ctx = canvas.getContext("2d");
    if (ctx) {
      var idata = ctx.getImageData(0, 0, w, h),
        data32 = new Uint32Array(idata.data.buffer), // 32-bit representation of canvas

        stride = Math.floor((32 * w + 31) / 32) * 4, // row length incl. padding
        pixelArraySize = stride * h,                 // total bitmap size
        fileLength = 122 + pixelArraySize,           // header size is known + bitmap

        file = new ArrayBuffer(fileLength),          // raw byte buffer (returned)
        view = new DataView(file),                   // handle endian, reg. width etc.
        pos = 0, x, y = 0, p, s = 0, a, v;

      // write file header
      setU16(0x4d42);          // BM
      setU32(fileLength);      // total length
      pos += 4;                // skip unused fields
      setU32(0x7a);            // offset to pixels

      // DIB header
      setU32(108);             // header size
      setU32(w);
      setU32(-h >>> 0);        // negative = top-to-bottom
      setU16(1);               // 1 plane
      setU16(32);              // 32-bits (RGBA)
      setU32(3);               // no compression (BI_BITFIELDS, 3)
      setU32(pixelArraySize);  // bitmap size incl. padding (stride x height)
      setU32(2835);            // pixels/meter h (~72 DPI x 39.3701 inch/m)
      setU32(2835);            // pixels/meter v
      pos += 8;                // skip color/important colors
      setU32(0xff0000);        // red channel mask
      setU32(0xff00);          // green channel mask
      setU32(0xff);            // blue channel mask
      setU32(0xff000000);      // alpha channel mask
      setU32(0x57696e20);      // " win" color space

      // bitmap data, change order of ABGR to BGRA
      while (y < h) {
        p = 0x7a + y * stride; // offset + stride x height
        x = 0;
        while (x < w4) {
          v = data32[s++];                     // get ABGR
          a = v >>> 24;                        // alpha channel
          view.setUint32(p + x, (v << 8) | a); // set BGRA
          x += 4;
        }
        y++
      }

      return file;
    }
  }
}

function toBMPDataURL(canvas: HTMLCanvasElement) {
  let b = toArrayBuffer(canvas);
  if (b) {
    var buffer = new Uint8Array(b),
      bs = "", i = 0, l = buffer.length;
    while (i < l) bs += String.fromCharCode(buffer[i++]);
    return "data:image/bmp;base64," + btoa(bs);
  }
  return '';
}

export function htmltoimage(domNode: HTMLElement) {
  return htmlToImage.toJpeg(domNode, {
    width: 480,
    cacheBust: true
  }).then(function (dataUrl) {
    domNode.remove();
    return dataUrl;
  })
}

export function printpos(dataUrl: string) {
  var canvas = document.createElement('canvas');
  var ctx = canvas.getContext("2d");

  var img = new Image;
  img.onload = function () {
    if (ctx) {
      canvas.width = 560/*165/*384*/;
      canvas.height = (560/*165/*384*/ / img.width) * img.height;

      ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);

      var idata = ctx.getImageData(0, 0, canvas.width, canvas.height),
        buffer = idata.data,
        len = buffer.length,
        threshold = 180/*204/*127*/,
        i, luma;

      for (i = 0; i < len; i += 4) {
        // get approx. luma value from RGB
        luma = buffer[i] * 0.3 + buffer[i + 1] * 0.59 + buffer[i + 2] * 0.11;

        // test against some threshold
        luma = luma < threshold ? 0 : 255;

        // wri0te result back to all components
        buffer[i] = luma;
        buffer[i + 1] = luma;
        buffer[i + 2] = luma;
      }

      // update canvas with the resulting bitmap data
      ctx.putImageData(idata, 0, 0);

      var link = document.createElement('a');
      link.download = 'pos.bmp';
      link.href = toBMPDataURL(canvas);
      link.click();

      //if (isPlatform("desktop")) {
      //printImage(toBMPDataURL(canvas))
      //}
      //else {
      //printBT(toBMPDataURL(canvas));
      //}
      /*SocialSharing.share(
        'P: ',
        'Receipt Label',
        [toBMPDataURL(canvas)])*/
    }
  }
  img.src = dataUrl;
}

export function downloadJpg(sale: Sale | null, domNode: HTMLElement, addToMPrint: boolean, withShipping : boolean) {
  let saleID = sale === null ? '' : sale._id;
  return new Promise((resolve, reject) => {
    htmlToImage.toJpeg(domNode, {
      width: 480,
      cacheBust: true
    }).then(function (dataUrl) {
      domNode.remove();
      if (isPlatform('hybrid') && !mprint && saleID !== '') {
        if (!isPO(sale!) && printmode.state) {
          resolve(printBT(sale!, dataUrl));
        }
        else {
          resolve(SocialSharing.share(
            '',
            'Receipt Label',
            [dataUrl]).then(() => {
              printSalesDone([{ sale: sale!, data: '' }], false, OFFLINE, printSimpleDate(new Date().toISOString()))
            })
          )
        }
      }
      else if (!isPO(sale!) && mprint && saleID !== '') {
        resolve(addMPrint({ sale: sale!, data: dataUrl }, addToMPrint));
      }
      else if (!isPO(sale!) && printmode.state && saleID !== '') {
        resolve(generateReceiptLabel(sale!, withShipping));
        //resolve(printpos(dataUrl));
      }
      else {
        var link = document.createElement('a');
        link.download = 'label.jpeg';
        link.href = dataUrl;
        printSaleDone(sale!, false);
        //addSaveList(printSimpleDate(new Date().toISOString()), [{ sale: sale!, data: '' }]);
        resolve(link.click());
      }
    })
      .catch(function (error) {
        reject(console.error('oops, something went wrong!', error));
      });
  });
}

export function download(filename: string, blob: any) {
  /*if (navigator.msSaveBlob) { // In case of IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {*/
  const link = document.createElement('a');
  if (link.download !== undefined) {
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  //}
}

function downloadDB(id: number) {
  return new Promise((resolve, reject) => {
    checkLogIn().then(() => {
      if (user.isLoggedIn) {
        switch (id) {
          case LIST_INVENTORY: {
            return localDB.replicate.from(remoteDB)
              .on('complete', function () {
                resolve(getDB(LIST_INVENTORY));
              }).on('error', function (err) {
                reject(err);
              })
          }
          case LIST_SALES: {
            return localSales.replicate.from(remoteSales)
              .on('complete', function () {
                resolve(getDB(LIST_SALES));
              }).on('error', function (err) {
                reject(err);
              });
          }
          case LIST_PURCHASES: {
            return localPurchases.replicate.from(remotePurchases)
              .on('complete', function () {
                resolve(getDB(LIST_PURCHASES));
              }).on('error', function (err) {
                reject(err);
              });
          }
          case LIST_PERSON: {
            return localContacts.replicate.from(remoteContacts)
              .on('complete', function () {
                resolve(getDB(LIST_PERSON));
              }).on('error', function (err) {
                reject(err);
              });
          }
          case LIST_LOG: {
            return localKonten.replicate.from(remoteKonten)
              .on('complete', function () {
                resolve(getDB(LIST_LOG));
              }).on('error', function (err) {
                reject(err);
              });
          }
          case LIST_CAT: {
            return localCategory.replicate.from(remoteCategory)
              .on('complete', function () {
                resolve(getDB(LIST_CAT));
              }).on('error', function (err) {
                reject(err);
              });
          }
          case LIST_SHOP: {
            return localShop.replicate.from(remoteShop)
              .on('complete', function () {
                resolve(getDB(LIST_SHOP));
              }).on('error', function (err) {
                reject(err);
              });
          }
          case LIST_INVOICES: {
            return localInvoices.replicate.from(remoteInvoices)
              .on('complete', function () {
                resolve(getDB(LIST_INVOICES));
              }).on('error', function (err) {
                reject(err);
              });
          }
          default: break;
        }
      }
      else alerterrorUpload('DLDB', STR_ErrorNeedLogin);
    });
  });
}

export function uploadDB(id: number) {
  return checkLogIn().then(() => {
    if (user.isLoggedIn) {
      switch (id) {
        case LIST_INVENTORY: {
          return localDB.replicate.to(remoteDB)
            .on('error', function (err) {
              alert('inv-' + STR_upload + err);
            })
        }
        case LIST_SALES: {
          return localSales.replicate.to(remoteSales)
            .on('error', function (err) {
              alert('sales-' + STR_upload + err);
            });
        }
        case LIST_PURCHASES: {
          return localPurchases.replicate.to(remotePurchases)
            .on('error', function (err) {
              alert('purchases-' + STR_upload + err);
            });
        }
        case LIST_BIASINVENTORY: {
          return localBiasDB.replicate.to(remoteBiasDB)
            .on('error', function (err) {
              alert('biasinv-' + STR_upload + err);
            })
        }
        case LIST_BIASSALES: {
          return localBiasSales.replicate.to(remoteBiasSales)
            .on('error', function (err) {
              alert('biassales-' + STR_upload + err);
            });
        }
        case LIST_PERSON: {
          return localContacts.replicate.to(remoteContacts)
            .on('error', function (err) {
              alert('contacts-' + STR_upload + err);
            });
        }
        case LIST_LOG: {
          return localKonten.replicate.to(remoteKonten)
            .on('error', function (err) {
              alert('konten-' + STR_upload + err);
            });
        }
        case LIST_CAT: {
          return localCategory.replicate.to(remoteCategory)
            .on('error', function (err) {
              alert('cat-' + STR_upload + err);
            });
        }
        case LIST_SHOP: {
          return localShop.replicate.to(remoteShop)
            .on('error', function (err) {
              alert('shops-' + STR_upload + err);
            });
        }
        case LIST_KURIR: {
          return localKurir.replicate.to(remoteKurir)
            .on('error', function (err) {
              alert('kurir-' + STR_upload + err);
            });
        }
        case LIST_PO: {
          return localPO.replicate.to(remotePO)
            .on('error', function (err) {
              alert('po-' + STR_upload + err);
            });
        }
        case LIST_GHEIM: {
          return localGheim.replicate.to(remoteGheim)
            .on('error', function (err) {
              alert('gheim-' + STR_upload + err);
            });
        }
        case LIST_NOTES: {
          return localNotes.replicate.to(remoteNotes)
            .on('error', function (err) {
              alert('notes-' + STR_upload + err);
            });
        }
        case LIST_INVOICES: {
          return localInvoices.replicate.to(remoteInvoices)
            .on('error', function (err) {
              alert('invoices-' + STR_upload + err);
            });
        }
        case LIST_USER: {
          return localUser.replicate.to(remoteUser)
            .on('error', function (err) {
              alert('user-' + STR_upload + err);
            });
        }
        default: break;
      }
    }
  });
}

export function backup() {
  var remoteBUDB = makeremoteDB('inventory' + '_bu');
  var remoteBUContacts = makeremoteDB('contacts' + '_bu');
  var remoteBUSales = makeremoteDB(DB_SALES + '_bu');
  var remoteBUPurchases = makeremoteDB('purchases' + '_bu');
  var remoteBUCategory = makeremoteDB('category' + '_bu');
  var remoteBUKonten = makeremoteDB('konten' + '_bu');
  var remoteBUShop = makeremoteDB('shop' + '_bu');
  var remoteBUKurir = makeremoteDB('kurir' + '_bu');
  var remoteBUPO = makeremoteDB(DB_PO + '_bu');
  var remoteBUUser = makeremoteDB('user' + '_bu');
  var remoteBUGheim = makeremoteDB('gheim' + '_bu');
  var remoteBUBiasDB = makeremoteDB('bias-inventory' + '_bu');
  var remoteBUBiasSales = makeremoteDB('bias-' + DB_SALES + '_bu');
  var remoteBUNotes = makeremoteDB('notes' + '_bu');
  var remoteBUInvoices = makeremoteDB('invoices' + '_bu');

  checkLogIn().then(() => {
    if (user.isLoggedIn) {
      localUser.replicate.to(remoteBUUser).on('complete', function () {
        localBiasDB.replicate.to(remoteBUBiasDB);
        localBiasSales.replicate.to(remoteBUBiasSales);
        localNotes.replicate.to(remoteBUNotes);
        localInvoices.replicate.to(remoteBUInvoices);
        localCategory.replicate.to(remoteBUCategory).on('complete', function () {
          localShop.replicate.to(remoteBUShop).on('complete', function () {
            localKurir.replicate.to(remoteBUKurir).on('complete', function () {
              localPO.replicate.to(remoteBUPO).on('complete', function () {
                localContacts.replicate.to(remoteBUContacts);
                localKonten.replicate.to(remoteBUKonten);
                localPurchases.replicate.to(remoteBUPurchases);
                localGheim.replicate.to(remoteBUGheim);
                localDB.replicate.to(remoteBUDB).on('complete', function () {
                  localSales.replicate.to(remoteBUSales).on('complete', function () {
                    user.setLoading(false);
                    alert('backup completed!');
                  }).on('error', function (err) {
                    alert("backup : " + err);
                  })
                })
              })
            })
          })
        })
      });
    } else {
      user.setLogin(true);
    }
  });
}

export function syncManual() {
  checkLogIn().then(() => {
    if (user.isLoggedIn) {
      user.setLoading(true);

      localUser.sync(remoteUser).on('complete', function () {
        localBiasDB.sync(remoteBiasDB)
        localBiasSales.sync(remoteBiasSales);
        localKonten.sync(remoteKonten);
        localCategory.sync(remoteCategory).on('complete', function () {
          localShop.sync(remoteShop).on('complete', function () {
            localKurir.sync(remoteKurir).on('complete', function () {
              localPO.sync(remotePO).on('complete', function () {
                localContacts.sync(remoteContacts);
                localPurchases.sync(remotePurchases);
                localGheim.sync(remoteGheim);
                localNotes.sync(remoteNotes);
                localInvoices.sync(remoteInvoices);
                localDB.sync(remoteDB).on('complete', function () {
                  localSales.sync(remoteSales).on('complete', function () {
                    fillFromLocalDB();
                  }).on('error', function (err) {
                    user.setLoading(false);
                    if (data.length === 0) alert('Please restart / reload the App : ' + err);
                  })
                })
              })
            })
          })
        })
      });
    } else {
      user.setLoading(false);
      user.setLogin(true);
      //alert(STR_ErrorNeedLogin);
    }
  });
}

export function fillFromLocalDB() {
  user.setLoading(true);

  getDB(LIST_CAT)?.then(function () {
    getLocalUsers();
    getDB(LIST_INVOICES);
    getDB(LIST_NOTES);
    getDB(LIST_GHEIM);
    getDB(LIST_SHOP)
    getDB(LIST_KURIR)
    getDB(LIST_PERSON)
    getDB(LIST_PO)
    getDB(LIST_PURCHASES)
    getDB(LIST_LOG)
    getDB(LIST_BIASSALES)
    getDB(LIST_BIASINVENTORY)
    getDB(LIST_INVENTORY)?.then(function () {
      getDB(LIST_SALES)?.then(() => { updateTransactionTab(); user.setLoading(false) });
    })
  });

  /*getLocalUsers().then(() =>
    getDB(LIST_CAT)?.then(() =>
      getDB(LIST_GHEIM)).then(() =>
        getDB(LIST_SHOP)).then(() =>
          getDB(LIST_KURIR)).then(() =>
            getDB(LIST_PERSON)).then(() =>
              getDB(LIST_PO)).then(() =>
                getDB(LIST_SALES)).then(() =>
                  getDB(LIST_PURCHASES)).then(() =>
                    getDB(LIST_LOG)).then(() =>
                      getDB(LIST_BIASSALES)).then(() =>
                        getDB(LIST_BIASINVENTORY)).then(() =>
                          getDB(LIST_INVENTORY))).then(() => { updateTransactionTab(); user.setLoading(false) });*/
}

function setAllSalesImage(sales: Sale[]) {
  return Promise.all(sales.map(function (sale) {
    return setAllSalesItemImage(sale.items);
  }));
}

function setAllNotesImage(notes: Note[]) {
  return Promise.all(notes.map(function (note) {
    return setImageNote(note);
  }));
}

function setAllSalesItemImage(items: SalesItem[]) {
  return Promise.all(items.map(function (item) {
    return setImage(item.item);
  }));
}

export async function setItemsImage(list: any[]) {
  if (list.length > 0) {
    if ('ProductName' in list[0]) await setAllImage(list);
    else if ('items' in list[0]) await setAllSalesImage(list);
    else if ('content' in list[0]) await setAllNotesImage(list);
  }
}

function setAllImage(items: Item[]) {
  return Promise.all(items.map(function (item) {
    return setImage(item);
  }));
}

function setImage(item: Item) {
  if (item.Image != null && item.Image.size) return;
  if (inventoryID === LIST_INVENTORY && !isBias()) {
    return localDB.getAttachment(item._id, 'file').then(function (blob) {
      item.Image = blob as Blob;
    }).catch(() => { });
  }
  else {
    return localBiasDB.getAttachment(item._id, 'file').then(function (blob) {
      item.Image = blob as Blob;
    }).catch(() => { });
  }
}

function setImageNote(note: Note) {
  if (note.img != null && note.img.size) return;
  return localNotes.getAttachment(note._id, 'file').then(function (blob) {
    note.img = blob as Blob;
  }).catch(() => { });
}

function setInvLogo(inv: Invoice) {
  if (inv.logo != null && inv.logo.size) return;
  return localInvoices.getAttachment(inv._id, 'file').then(function (blob) {
    inv.logo = blob as Blob;
  }).catch(() => { });
}

export function resizeImageAttachment(item: Item) {
  return localDB.getAttachment(item._id, 'file').then(function (blob) {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext('2d');

    let img = new Image();
    img.src = URL.createObjectURL(blob as Blob);
    img.onload = function () {
      if (ctx) {
        canvas.width = 120;
        canvas.height = (120 / img.width) * img.height;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      }
      canvas.toBlob(function (blob) {
        item.Image = blob!;
        saveImageAttachment(blob!, item._id);
      });
    };
  });
}

export function saveImageAttachment(getFile: File | Blob, id: string) {
  if (inventoryID === LIST_BIASINVENTORY) {
    return localBiasDB.get(id).then(function (doc) {
      (doc as unknown as Item).Image = getFile;
      doc._attachments = {
        "file": {
          content_type: getFile.type,
          data: getFile
        }
      }
      return localBiasDB.put(doc);
    }).then(() => uploadDB(LIST_BIASINVENTORY).then(() => console.log(id + "--Bias Image saved."))).catch(function (err) {
      console.error(id + "--Image--" + err)
    }).then(() => setItemImage(id, getFile));
  }
  else {
    return localDB.get(id).then(function (doc) {
      (doc as unknown as Item).Image = getFile;
      doc._attachments = {
        "file": {
          content_type: getFile.type,
          data: getFile
        }
      }
      return localDB.put(doc);
    }).then(() => uploadDB(LIST_INVENTORY).then(() => console.log(id + "--Image saved."))).catch(function (err) {
      console.error(id + "--Image--" + err)
    }).then(() => setItemImage(id, getFile));
  }
}

function b64toBlob(b64Data: string, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export async function getBLOB(base64Data: string) {
  let b64Data = base64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');
  return b64toBlob(b64Data);
}

function saveBLOBAttachment(blob: Blob, id: string, mode: number) {
  if (mode === LIST_INVENTORY) {
    return localDB.get(id).then(function (doc) {
      doc._attachments = {
        "file": {
          content_type: blob.type,
          data: blob
        }
      }
      return localDB.put(doc);
    }).then(() => uploadDB(LIST_INVENTORY)?.then(() => console.log(id + "--Image saved."))).catch(function (err) {
      console.error(id + "--Image--" + err);
    });
  }
  else {
    return localBiasDB.get(id).then(function (doc) {
      doc._attachments = {
        "file": {
          content_type: blob.type,
          data: blob
        }
      }
      return localBiasDB.put(doc);
    }).then(() => uploadDB(LIST_BIASINVENTORY)?.then(() => console.log(id + "--Image in Bias saved."))).catch(function (err) {
      console.error(id + "--Image BIAS--" + err);
    });
  }
}

export function removeNoteImage(note: Note) {
  note.img = null as unknown as Blob;
  var img = document.getElementById(noteImageID) as HTMLImageElement;
  if (img) img.src = '';

  updateList();

  let id = note._id;
  return localNotes.get(id).then(function (doc) {
    localNotes.removeAttachment(id, 'file', doc._rev, function (err, res) {
      if (err) { return alert("removeattch : " + err); }
      uploadDB(LIST_NOTES).then(() => note.img = null as unknown as Blob).then(() => console.log(id + "--Image removed."));
    })
  });
}

export function removeInvLogo(inv: Invoice) {
  inv.logo = null as unknown as Blob;
  var img = document.getElementById(invLogoID) as HTMLImageElement;
  if (img) img.src = '';

  updateList();

  let id = inv._id;
  return localInvoices.get(id).then(function (doc) {
    localInvoices.removeAttachment(id, 'file', doc._rev, function (err, res) {
      uploadDB(LIST_INVOICES).then(() => inv.logo = null as unknown as Blob).then(() => console.log(id + "--Image removed."));
    })
  });
}

export function removeItemImage(item: Item, neu: boolean) {
  item.Image = null as unknown as Blob;
  var inputImg = document.getElementById("fileImg") as HTMLInputElement;
  if (inputImg) inputImg.value = '';

  var img = document.getElementById(itemImageID) as HTMLImageElement;
  if (img) img.src = '';
  if (!neu) return removeImageAttachment(item._id);
}

function removeImageAttachment(id: string) {
  return localDB.get(id).then(function (doc) {
    localDB.removeAttachment(id, 'file', doc._rev, function (err, res) {
      if (err) { return alert("removeattch : " + err); }
      uploadDB(LIST_INVENTORY).then(() => setItemImage(id, null as unknown as Blob)).then(() => console.log(id + "--Image removed."));
    })
  });
}

export function copyImageAttachment(alt: string, neu: string, mode: number) {
  if (mode === LIST_INVENTORY) {
    localDB.getAttachment(alt, 'file').then(function (blob) {
      return saveBLOBAttachment(blob as Blob, neu, mode);
    }).catch(() => { });
  }
  else {
    localBiasDB.getAttachment(alt, 'file').then(function (blob) {
      return saveBLOBAttachment(blob as Blob, neu, mode);
    }).catch(() => { });
  }
}

export function setImageAttachment(id: string) {
  var img = document.getElementById(itemImageID);
  if (img) {
    localDB.getAttachment(id, 'file').then(function (blob) {
      var url = URL.createObjectURL(blob as Blob);
      if (img) (img as HTMLImageElement).src = url;
    }).catch(err => { });
  }
}

export function openinvLogo(file: File | Blob | null, inv: Invoice) {
  var canvas = document.createElement("canvas");
  var ctx = canvas.getContext('2d');

  let img = new Image();
  img.src = URL.createObjectURL(file!);
  img.onload = function () {
    if (ctx) {
      canvas.width = 120;
      canvas.height = (120 / img.width) * img.height;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    }
    canvas.toBlob(function (blob) {
      inv.logo = blob!;
      if (file !== null) {
        var img = document.getElementById(invLogoID);
        if (img) {
          var url = URL.createObjectURL(file);
          (img as HTMLImageElement).src = url;
        }
        updateList();
        return saveInvoiceLogoAttachment(file, inv);
      }
    });
  };
}

function saveInvoiceLogoAttachment(getFile: File | Blob, inv: Invoice) {
  let id = inv._id;
  return localInvoices.get(id).then(function (doc) {
    (doc as unknown as Invoice).logo = getFile;
    doc._attachments = {
      "file": {
        content_type: getFile.type,
        data: getFile
      }
    }
    return localInvoices.put(doc);
  }).then(() => uploadDB(LIST_INVOICES).then(() => console.log(id + "--Image saved."))).catch(function (err) {
    console.error(id + "--Image--" + err)
  }).then(() => inv.logo = getFile);
}

export function openNoteImage(file: File | Blob | null, note: Note) {
  var canvas = document.createElement("canvas");
  var ctx = canvas.getContext('2d');

  let img = new Image();
  img.src = URL.createObjectURL(file!);
  img.onload = function () {
    if (ctx) {
      canvas.width = 120;
      canvas.height = (120 / img.width) * img.height;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    }
    canvas.toBlob(function (blob) {
      note.img = blob!;
      if (file !== null) {
        var img = document.getElementById(noteImageID);
        if (img) {
          var url = URL.createObjectURL(file);
          (img as HTMLImageElement).src = url;
        }
        updateList();
        return saveNoteImageAttachment(file, note);
      }
    });
  };
}

function saveNoteImageAttachment(getFile: File | Blob, note: Note) {
  let id = note._id;
  return localNotes.get(id).then(function (doc) {
    (doc as unknown as Note).img = getFile;
    doc._attachments = {
      "file": {
        content_type: getFile.type,
        data: getFile
      }
    }
    return localNotes.put(doc);
  }).then(() => uploadDB(LIST_NOTES).then(() => console.log(id + "--Image saved."))).catch(function (err) {
    console.error(id + "--Image--" + err)
  }).then(() => note.img = getFile);
}

export function openItemImage(file: File | Blob | null, item: Item, neu: boolean) {
  var canvas = document.createElement("canvas");
  var ctx = canvas.getContext('2d');

  let img = new Image();
  img.src = URL.createObjectURL(file!);
  img.onload = function () {
    if (ctx) {
      canvas.width = 120;
      canvas.height = (120 / img.width) * img.height;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    }
    canvas.toBlob(function (blob) {
      item.Image = blob!;
      openImage(blob, item._id, !neu);
    });
  };
}

function openImage(file: File | Blob | null, id: string, save: boolean) {
  if (file !== null) {
    var img = document.getElementById(itemImageID);
    if (img) {
      var url = URL.createObjectURL(file);
      (img as HTMLImageElement).src = url;
    }
    if (save) return saveImageAttachment(file, id);/*.then(() => setImageAttachment(id))*/
  }
}

export function openImages(filelist: null | FileList) {
  if (filelist === null) return new Promise((resolve, reject) => { reject() });
  else {
    let array = Array.from(filelist);
    return Promise.all(
      array.map(function (file) {
        var id = file.name.substr(0, file.name.lastIndexOf('.'));
        return openImage(file, id, true);
      }));
  }
}

export async function openInventory(filelist: null | FileList) {
  if (filelist !== null) {
    let file = filelist.item(0) as File;
    if (file !== null) {
      return extractFromFile(file, LIST_INVENTORY, '');
    }
  }
  else {
    return loadInventory().then(res => setInventory(res));
  }
}

export async function openContacts(filelist: null | FileList) {
  if (filelist !== null) {
    let file = filelist.item(0) as File;
    if (file !== null) {
      return extractFromFile(file, LIST_PERSON, '');
    }
  }
}

export async function openPurchases(filelist: null | FileList) {
  if (filelist !== null) {
    let file = filelist.item(0) as File;
    if (file !== null) {
      return extractFromFile(file, LIST_PURCHASES, '');
    }
  }
}

export async function openSales(filelist: null | FileList) {
  if (filelist !== null) {
    let file = filelist.item(0) as File;
    if (file !== null) {
      return extractFromFile(file, LIST_SALES, '');
    }
  }
}

export async function openFee(filelist: null | FileList, shop: string) {
  if (filelist !== null) {
    let file = filelist.item(0) as File;
    if (file !== null) {
      return extractFromFile(file, FEE, shop);
    }
  }
}

export async function loadInventory() {
  const csvSales = await fetch('assets/data/Sales.csv');
  setSales(extractData((await csvSales.text()).toString()));

  const csvPSales = await fetch('assets/data/Purchases.csv');
  setPurchases(extractData((await csvPSales.text()).toString()));

  const csvP = await fetch('assets/data/Contacts.csv');
  setContacts(extractData((await csvP.text()).toString()));

  const csv = await fetch('assets/data/Inventory.csv');
  const parsedData = extractData((await csv.text()).toString());
  return parsedData;
}


function formatItem(parsedJSON: any[]) {
  for (var i = 0; i < parsedJSON.length; i++) {
    var obj = parsedJSON[i];
    obj._id = obj.id;
    delete obj.id;
  }
}

function updateInventory(res: any[]) {
  return localDB.allDocs().then(function (result) {
    return Promise.all(result.rows.map(function (row) {
      if (!contains(res, row.id)) {
        return removeFromDB(row.id, LIST_INVENTORY);
      }
    }));
  }).then(function () {
    setInventory(res);
  }).catch(function (err) {
    alert("updateinv : " + err);
  });
}

function updateContacts(res: any[]) {
  return localContacts.allDocs().then(function (result) {
    return Promise.all(result.rows.map(function (row) {
      if (!contains(res, row.id)) {
        return removeFromDB(row.id, LIST_PERSON);
      }
    }));
  }).then(function () {
    setContacts(res);
  }).catch(function (err) {
    alert("updatecontact : " + err);
  });
}

function updateFee(res: any[], shop: string) {
  try {
    if (shop === SHOPEE) {
      let fail: any[] = [];
      let success = [];
      for (var i = 0; i < res.length; i++) {
        let toAdjust = res[i];
        let biayadm = -1 * toAdjust.Administrasi;
        let biayalayanan = -1 * toAdjust.Layanan;
        let sale = getSaleFromClientShopee(toAdjust.Username, biayadm, toAdjust.Total);
        if (sale) {
          if (/*sale.fee === biayadm && */Number.parseFloat(toAdjust.Total) === sale.total - biayadm - biayalayanan + +toAdjust.Ongkir) {
            sale.fee += biayalayanan;
            sale.fee -= +toAdjust.Ongkir;
            tagAdjusted(sale, toAdjust.Layanan, toAdjust.Ongkir);
            success.push(sale);
          }
          else if (Number.parseFloat(toAdjust.Total) !== sale.total - sale.fee) fail.push(toAdjust);
        }
        else fail.push(toAdjust);
      }
      saveSalesLocal(success).then(() => {
        uploadDB(LIST_SALES);
        const csvContent = toCSV(fail, FEE);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const base64 = csvContent;
        alert(success.length + ' fee adjusted | ' + fail.length + ' failed');
        downloadShare(base64, blob, 'shopeefailedToAdjust.csv');
      });
    }
    else if (shop === TOKOPEDIA) {
      let fail: any[] = [];
      let success = [];
      for (var i = 0; i < res.length; i++) {
        let toAdjust = res[i];
        let total = +toAdjust.Total - toAdjust.TotalFee;
        let sale = getSaleTokopedia(toAdjust.CustomerName, total);
        if (sale) {
          sale.fee += +toAdjust.TotalFee - toAdjust.Fee;
          if (toAdjust.BebasOngkir === 'Yes') {
            //if (sale.fee === 0.01 * total) {
            let gratisOngkirFee = 0.015 * total;
            sale.fee += gratisOngkirFee;
            tagAdjusted(sale, gratisOngkirFee, 0);
            success.push(sale);
            //}
            //else fail.push(toAdjust);
          }
        }
        else fail.push(toAdjust);
      }
      saveSalesLocal(success).then(() => {
        uploadDB(LIST_SALES);
        const csvContent = toCSV(fail, FEE + 100);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const base64 = csvContent;
        alert(success.length + ' fee adjusted | ' + fail.length + ' failed');
        downloadShare(base64, blob, 'tokpedfailedToAdjust.csv');
      });
    }
    else if (shop === LAZADA) {
      let fail: any[] = [];
      let success = [];
      for (var i = 0; i < res.length; i++) {
        let toAdjust = res[i];
        let total = +toAdjust.Total - toAdjust.TotalFee;
        let sale = getSaleLazada(toAdjust.CustomerName, total);
        if (sale) {
          sale.fee += +toAdjust.TotalFee - toAdjust.Fee;
          if (toAdjust.BebasOngkir === 'Yes') {
            //if (sale.fee === 0.01 * total) {
            let gratisOngkirFee = 0.015 * total;
            sale.fee += gratisOngkirFee;
            tagAdjusted(sale, gratisOngkirFee, 0);
            success.push(sale);
            //}
            //else fail.push(toAdjust);
          }
        }
        else fail.push(toAdjust);
      }
      saveSalesLocal(success).then(() => {
        uploadDB(LIST_SALES);
        const csvContent = toCSV(fail, FEE + 100);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const base64 = csvContent;
        alert(success.length + ' fee adjusted | ' + fail.length + ' failed');
        downloadShare(base64, blob, 'tokpedfailedToAdjust.csv');
      });
    }
  } catch (error) {
    alert("updatefee : " + error);
  }
}

function updatePurchases(res: any[]) {
  return localPurchases.allDocs().then(function (result) {
    return Promise.all(result.rows.map(function (row) {
      if (!contains(res, row.id)) {
        return removeFromDB(row.id, LIST_PURCHASES);
      }
    }));
  }).then(function () {
    setPurchases(res);
  }).catch(function (err) {
    alert("updatepurchases : " + err);
  });
}

function updateSales(res: any[]) {
  return localSales.allDocs().then(function (result) {
    return Promise.all(result.rows.map(function (row) {
      if (!contains(res, row.id)) {
        return removeFromDB(row.id, LIST_SALES);
      }
    }));
  }).then(function () {
    setSales(res);
  }).catch(function (err) {
    alert("updatesales : " + err);
  });
}

function getDB(id: number) {
  let list: any[] = [];
  switch (id) {
    case LIST_INVENTORY: {
      return localDB.allDocs({
        include_docs: true,
      }).then(function (result) {
        result.rows.forEach(e => {
          list.push(e.doc);
        });
        return setTempDB(list, id);
      });
    }
    case LIST_PERSON: {
      return localContacts.allDocs({
        include_docs: true,
      }).then(function (result) {
        result.rows.forEach(e => {
          list.push(e.doc);
        });
        return setTempDB(list, id);
      });
    }
    case LIST_SALES: {
      return localSales.allDocs({
        include_docs: true,
        descending: true,
        //limit: 5000
      }).then(function (result) {
        result.rows.forEach(e => {
          list.push(e.doc);
          setLastSalesID(e.doc?._id as string);
        });
        return setTempDB(list, id);
      });
    }
    case LIST_PURCHASES: {
      return localPurchases.allDocs({
        include_docs: true,
        descending: true,
        limit: 5000
      }).then(function (result) {
        result.rows.forEach(e => {
          list.push(e.doc);
          setLastSalesID(e.doc?._id as string);
        });
        return setTempDB(list, id);
      });
    }
    case LIST_BIASINVENTORY: {
      return localBiasDB.allDocs({
        include_docs: true,
      }).then(function (result) {
        result.rows.forEach(e => {
          list.push(e.doc);
        });
        return setTempDB(list, id);
      });
    }
    case LIST_BIASSALES: {
      return localBiasSales.allDocs({
        include_docs: true,
        descending: true,
        limit: 5000
      }).then(function (result) {
        result.rows.forEach(e => {
          list.push(e.doc);
          setLastSalesID(e.doc?._id as string);
        });
        return setTempDB(list, id);
      });
    }
    case LIST_LOG: {
      return localKonten.allDocs({
        include_docs: true,
      }).then(function (result) {
        result.rows.forEach(e => {
          list.push(e.doc);
        });
        return setTempDB(list, id);
      });
    }
    case LIST_SHOP: {
      return localShop.allDocs({
        include_docs: true,
      }).then(function (result) {
        result.rows.forEach(e => {
          list.push(e.doc);
        });
        return setTempDB(list, id);
      });
    }
    case LIST_CAT: {
      return localCategory.allDocs({
        include_docs: true,
      }).then(function (result) {
        result.rows.forEach(e => {
          list.push(e.doc);
        });
        return setTempDB(list, id);
      });
    }
    case LIST_KURIR: {
      return localKurir.allDocs({
        include_docs: true,
      }).then(function (result) {
        result.rows.forEach(e => {
          list.push(e.doc);
        });
        return setTempDB(list, id);
      });
    }
    case LIST_PO: {
      return localPO.allDocs({
        include_docs: true,
        descending: true,
      }).then(function (result) {
        result.rows.forEach(e => {
          list.push(e.doc);
        });
        return setTempDB(list, id);
      });
    }
    case LIST_GHEIM: {
      return localGheim.allDocs({
        include_docs: true,
      }).then(function (result) {
        result.rows.forEach(e => {
          list.push(e.doc);
        });
        return setTempDB(list, id);
      });
    }
    case LIST_NOTES: {
      return localNotes.allDocs({
        include_docs: true,
      }).then(function (result) {
        result.rows.forEach(e => {
          list.push(e.doc);
        });
        return setTempDB(list, id);
      });
    }
    case LIST_INVOICES: {
      return localInvoices.allDocs({
        include_docs: true,
      }).then(function (result) {
        result.rows.forEach(e => {
          list.push(e.doc);
        });
        return setTempDB(list, id);
      });
    }
    default: break;
  }
}

export function saveToDB(list: any[], id: number) {
  return new Promise((resolve, reject) => {
    switch (id) {
      case LIST_INVENTORY: {
        return resolve(localDB.bulkDocs(list).then(() => uploadDB(LIST_INVENTORY)?.then(() => console.log("Inventory saved"))).catch(function (err) {
          alert("bulkw-inv : " + err);
        }));
      }
      case LIST_PERSON: {
        return resolve(localContacts.bulkDocs(list).then(() => uploadDB(LIST_PERSON)?.then(() => console.log("Contacts saved"))).catch(function (err) {
          alert("bulkw-person : " + err);
        }));
      }
      case LIST_SALES: {
        return resolve(localSales.bulkDocs(list).then(() => uploadDB(LIST_SALES)?.then(() => console.log("Sales saved"))).catch(function (err) {
          alert("bulkw-sales : " + err);
        }));
      }
      case LIST_PURCHASES: {
        return resolve(localPurchases.bulkDocs(list).then(() => uploadDB(LIST_PURCHASES)?.then(() => console.log("Purchases saved"))).catch(function (err) {
          alert("bulkw-purchases : " + err);
        }));
      }
      case LIST_BIASINVENTORY: {
        return resolve(localBiasDB.bulkDocs(list).then(() => uploadDB(LIST_BIASINVENTORY)?.then(() => console.log("Bias Inventory saved"))).catch(function (err) {
          alert("bulkw-binv : " + err);
        }));
      }
      case LIST_BIASSALES: {
        return resolve(localBiasSales.bulkDocs(list).then(() => uploadDB(LIST_BIASSALES)?.then(() => console.log("Bias Sales saved"))).catch(function (err) {
          alert("bulkw-bsales : " + err);
        }));
      }
      case LIST_LOG: {
        return resolve(localKonten.bulkDocs(list).then(() => uploadDB(LIST_LOG)?.then(() => console.log("Accounts saved"))).catch(function (err) {
          alert("bulkw-log : " + err);
        }));
      }
      case LIST_SHOP: {
        return resolve(localShop.bulkDocs(list).then(() => uploadDB(LIST_SHOP)?.then(() => console.log("Shop saved"))).catch(function (err) {
          alert("bulkw-shop : " + err);
        }));
      }
      case LIST_CAT: {
        return resolve(localCategory.bulkDocs(list).then(() => uploadDB(LIST_CAT)?.then(() => console.log("Categories saved"))).catch(function (err) {
          alert("bulkw-cat : " + err);
        }));
      }
      case LIST_KURIR: {
        return resolve(localKurir.bulkDocs(list).then(() => uploadDB(LIST_KURIR)?.then(() => console.log("Courier saved"))).catch(function (err) {
          alert("bulkw-kurir : " + err);
        }));
      }
      case LIST_PO: {
        return resolve(localPO.bulkDocs(list).then(() => uploadDB(LIST_PO)?.then(() => console.log("PO saved"))).catch(function (err) {
          alert("bulkw-po : " + err);
        }));
      }
      default: break;
    }
  });
}

export function saveUser(username: string, usefingerprint: boolean) {
  localSetting.get(USER_SAVED).then(function (d) {
    let doc: any = d;
    // update
    doc.userid = username;
    doc.usefingerprint = usefingerprint
    // put them back
    return localSetting.put(doc);
  }).catch(function () {
    localSetting.put({ _id: USER_SAVED, userid: username, usefingerprint: usefingerprint });
  });
}

export function updateUser(user1: UserL) {
  localUser.get(user1._id).then(function (d) {
    let doc: any = d;
    // update
    doc.pwd = user1.pwd;
    doc.info = user1.info;
    doc.isMGProvided = user1.isMGProvided;
    doc.profitMG = user1.profitMG;
    doc.books = user1.books;
    doc.savedList = user1.savedList;
    doc.shopkonto = user1.shopkonto;
    doc.defaultkonto = user1.defaultkonto;
    // put them back
    return localUser.put(doc);
  }).then(() => uploadDB(LIST_USER)?.then(() => console.log(user1._id + " saved updated")))
    .catch(function (err) {
      if (err.name === 'not_found') {
        let doc = user1 as any;
        return localUser.put(doc).then(() => uploadDB(LIST_USER)?.then(() => console.log(user1._id + " saved neu")))
      } else {
        throw err;
      }
    })
}

export function saveLocalUser(user: UserL) {
  return localUser.put(user).then(() => alert(STR_UserSaved));
}

export function getGheimID(item: Gheim) {
  return item.loc + '|' + item.userid;
}

export function saveNote(note: Note) {
  if (!existsNotes(note)) notes.push(note);
  localNotes.get(note._id).then(function (d) {
    let doc: any = d;
    doc.ionitem = note.ionitem;
    doc.img = note.img;
    doc.title = note.title;
    doc.subtitle = note.subtitle;
    doc.content = note.content;
    doc.ionfooter = note.ionfooter;
    return localNotes.put(doc).then(() => localNotes.replicate.to(remoteNotes));
  }).catch(function () {
    localNotes.put({ _id: note._id, ionitem: note.ionitem, img: note.img, title: note.title, subtitle: note.subtitle, content: note.content, ionfooter: note.ionfooter }).then(() => localNotes.replicate.to(remoteNotes));
  });
}

export function saveInvoice(inv: Invoice) {
  if (!existsInvoice(inv)) invoices.push(inv);
  localInvoices.get(inv._id).then(function (d) {
    let doc: any = d;
    doc.logo = inv.logo;
    doc.company = inv.company;
    doc.website = inv.website;
    doc.telp = inv.telp;
    doc.bankaccount = inv.bankaccount;
    doc.dateDue = inv.dateDue;
    doc.customer = inv.customer;
    doc.note = inv.note;
    doc.footnote = inv.footnote;
    doc.sale = inv.sale;
    return localInvoices.put(doc).then(() => localInvoices.replicate.to(remoteInvoices));
  }).catch(function () {
    localInvoices.put({ 
      _id: inv._id,
      logo: inv.logo,
      company: inv.company,
      website: inv.website,
      telp: inv.telp,
      bankaccount: inv.bankaccount,
      dateDue: inv.dateDue,
      customer: inv.customer,
      note: inv.note,
      footnote: inv.footnote,
      sale: inv.sale
     }).then(() => localInvoices.replicate.to(remoteInvoices));
  });
}

export function saveGheim(item: Gheim) {
  localGheim.get(item._id).then(function (d) {
    let doc: any = d;
    doc.loc = item.loc;
    doc.userid = item.userid;
    doc.pwd = item.pwd;
    doc.email = item.email;
    doc.telp = item.telp;
    doc.info = item.info;
    return localGheim.put(doc).then(() => localGheim.replicate.to(remoteGheim));
  }).catch(function () {
    localGheim.put({ _id: item._id, loc: item.loc, userid: item.userid, pwd: item.pwd, email: item.email, telp: item.telp, info: item.info }).then(() => localGheim.replicate.to(remoteGheim));
  });
}

export function getUsers() {
  return new Promise((resolve, reject) => {
    localUser.get(DEV).then((function (info) {
      resolve(true);
    })).catch(() => {
      login(DEV, DEVP).then((response) => response.json())
        .then((responseData) => {
          if (responseData.ok) {
            localUser.replicate.from(remoteUser).then(() => resolve(user.setLoading(false)));
          }
          else if (responseData.error) resolve(user.setLoading(false));
        }).catch(() => resolve(user.setLoading(false)));
    });
  });
}

export function getLocalUsers() {
  localSetting.get(USER_SAVED).then(function (d) {
    setSavedUser((d as any).userid, (d as any).usefingerprint);
  });

  let list: any[] = [];
  return localUser.allDocs({
    include_docs: true,
  }).then(function (result) {
    result.rows.forEach(e => {
      list.push(e.doc);
    });
    setUsers(list);
  });
}

export function saveItem(item: Item) {
  let key = item._id;
  localDB.get(key).then(function (d) {
    let doc = d as unknown as Item;
    // update
    doc.ProductName = item.ProductName;
    doc.Quantity = item.Quantity;
    doc.PurchasePrice = item.PurchasePrice;
    doc.SalePrice = item.SalePrice;
    doc.Reserve = item.Reserve;
    doc.AdditionalSalesPrices = item.AdditionalSalesPrices;
    doc.Info = item.Info;
    doc.Category = item.Category;
    if (item.Image != null) {
      (doc as any)._attachments = {
        "file": {
          content_type: item.Image.type,
          data: item.Image
        }
      }
    }
    doc.MSRPPrice = item.MSRPPrice;
    doc.description = item.description;
    doc.dimensionP = item.dimensionP;
    doc.dimensionL = item.dimensionL;
    doc.dimensionT = item.dimensionT;
    doc.weight = item.weight
    // put them back
    return localDB.put(doc);
  }).then(() => uploadDB(LIST_INVENTORY)?.then(() => console.log(item.ProductName + " saved updated")))
    .catch(function (err) {
      if (err.name === 'not_found') {
        let doc = item as any;
        if (item.Image != null) {
          doc._attachments = {
            "file": {
              content_type: item.Image.type,
              data: item.Image
            }
          }
        }
        return localDB.put(doc).then(() => uploadDB(LIST_INVENTORY)?.then(() => console.log(item.ProductName + " saved neu")))
      } else {
        throw err;
      }
    })
}

export function saveBiasItem(item: Item) {
  let key = item._id;
  localBiasDB.get(key).then(function (d) {
    let doc = d as unknown as Item;
    // update
    doc.ProductName = item.ProductName;
    doc.Quantity = item.Quantity;
    doc.PurchasePrice = item.PurchasePrice;
    doc.SalePrice = item.SalePrice;
    doc.Reserve = item.Reserve;
    doc.AdditionalSalesPrices = item.AdditionalSalesPrices;
    doc.Info = item.Info;
    doc.Category = item.Category;
    if (item.Image != null) {
      (doc as any)._attachments = {
        "file": {
          content_type: item.Image.type,
          data: item.Image
        }
      }
    }
    doc.MSRPPrice = item.MSRPPrice;
    doc.description = item.description;
    doc.dimensionP = item.dimensionP;
    doc.dimensionL = item.dimensionL;
    doc.dimensionT = item.dimensionT;
    doc.weight = item.weight
    // put them back
    return localBiasDB.put(doc);
  }).then(() => uploadDB(LIST_BIASINVENTORY)?.then(() => console.log(item.ProductName + " in BIAS updated")))
    .catch(function (err) {
      if (err.name === 'not_found') {
        let doc = item as any;
        if (item.Image != null) {
          doc._attachments = {
            "file": {
              content_type: item.Image.type,
              data: item.Image
            }
          }
        }
        return localBiasDB.put(doc).then(() => uploadDB(LIST_BIASINVENTORY)?.then(() => console.log(item.ProductName + " in BIAS saved neu")))
      } else {
        throw err;
      }
    })
}

export function updateItem(item: Item) {
  let key = item._id;
  localDB.get(key).then(function (d) {
    let doc = d as unknown as Item;
    // update
    doc.ProductName = item.ProductName;
    doc.Quantity = item.Quantity;
    doc.PurchasePrice = item.PurchasePrice;
    doc.SalePrice = item.SalePrice;
    doc.Reserve = item.Reserve;
    doc.AdditionalSalesPrices = item.AdditionalSalesPrices;
    doc.Info = item.Info;
    doc.Category = item.Category;
    doc.MSRPPrice = item.MSRPPrice;
    // put them back
    return localDB.put(doc);
  }).then(() => uploadDB(LIST_INVENTORY)?.then(() => console.log(item.ProductName + " updated"))).catch(function (err) {
    alert("updItem : " + err);
  });
}

export function updateBiasItem(item: Item) {
  let key = item._id;
  localBiasDB.get(key).then(function (d) {
    let doc = d as unknown as Item;
    // update
    doc.ProductName = item.ProductName;
    doc.Quantity = item.Quantity;
    doc.PurchasePrice = item.PurchasePrice;
    doc.SalePrice = item.SalePrice;
    doc.Reserve = item.Reserve;
    doc.AdditionalSalesPrices = item.AdditionalSalesPrices;
    doc.Info = item.Info;
    doc.Category = item.Category;
    doc.MSRPPrice = item.MSRPPrice;
    // put them back
    return localBiasDB.put(doc);
  }).then(() => uploadDB(LIST_BIASINVENTORY)?.then(() => console.log(item.ProductName + "in BIAS updated"))).catch(function (err) {
    alert("updBItem : " + err);
  });
}

export function updatePerson(p: Person) {
  let key = p._id;
  localContacts.get(key).then(function (d) {
    let doc = d as unknown as Person;
    doc.name = p.name;
    doc.telp = p.telp;
    doc.address = p.address;
    doc.info = p.info;
    doc.staff = p.staff;
    return localContacts.put(doc);
  }).then(() => uploadDB(LIST_PERSON)?.then(() => console.log(p.name + " updated"))).catch(function (err) {
    alert("updContact : " + err);
  });
}

export function addToDBShop(s: Shop) {
  localShop.put(s).then(() => uploadDB(LIST_SHOP)?.then(() => console.log(s._id + "-shop added"))).catch(function (err) {
    alert("addShop : " + err);
  });
}

export function addToDBKurir(k: courier) {
  localKurir.put(k).then(() => uploadDB(LIST_KURIR)?.then(() => console.log(k._id + "-kurir added"))).catch(function (err) {
    alert("addKurir : " + err);
  });
}

export function addToDBKonto(k: konto) {
  localKonten.put(k).then(() => uploadDB(LIST_LOG)?.then(() => console.log(k._id + "-konto added"))).catch(function (err) {
    alert("addKonto : " + err);
  });
}

export function addToDBPerson(p: Person) {
  localContacts.put(p).then(() => uploadDB(LIST_PERSON)?.then(() => console.log(p._id + "-contacts added"))).catch(function (err) {
    alert("addContact : " + err);
  });
}

export function addToDBCategory(c: Category) {
  localCategory.put(c).then(() => uploadDB(LIST_CAT)?.then(() => console.log(c._id + "-category added"))).catch(function (err) {
    //alert("addCat : " + err);
  });
}

export function saveKurir(k: courier) {
  let key = k._id;
  localKurir.get(key).then(function (d) {
    let doc = d as unknown as courier;
    doc.startsWith = k.startsWith;
    doc.cashless = k.cashless;
    return localKurir.put(doc);
  }).then(() => uploadDB(LIST_KURIR)?.then(() => console.log(k._id + " updated"))).catch(function (err) {
    alert("saveKurir : " + err);
  });
}

export function updateCategory(c: Category) {
  let key = c._id;
  localCategory.get(key).then(function (d) {
    let doc = d as unknown as Category;
    doc.name = c.name;
    doc.img = c.img;
    doc.count = c.count;
    return localCategory.put(doc).then(() => updateCategories());
  }).then(() => uploadDB(LIST_CAT)?.then(() => console.log(c.name + " updated"))).catch(function (err) {
    alert("updCat : " + err);
  });
}

export function updateAccount(a: konto | Shop) {
  if (isKonto(a)) updateKonto(a as konto);
  else updateShop(a as Shop);
}

function updateKonto(k: konto) {
  let key = k._id;
  localKonten.get(key).then(function (d) {
    let doc = d as unknown as konto;
    doc.currency = k.currency;
    doc.name = k.name;
    doc.icon = k.icon;
    doc.balance = k.balance;
    doc.trx = k.trx;
    //doc.in = k.in;
    //doc.out = k.out;
    doc.transfer = k.transfer;
    doc.calculate = k.calculate;
    doc.hidden = k.hidden;
    return localKonten.put(doc);
  }).then(() => uploadDB(LIST_LOG)?.then(() => console.log(k.name + " updated")));
}

function updateShop(s: Shop) {
  let key = s._id;
  localShop.get(key).then(function (d) {
    let doc = d as unknown as Shop;
    doc.name = s.name;
    doc.fee = s.fee;
    doc.balance = s.balance;
    doc.trx = s.trx;
    //doc.in = s.in;
    //doc.out = s.out;
    doc.transfer = s.transfer;
    return localShop.put(doc).then(() => uploadDB(LIST_SHOP));
  }).then(() => uploadDB(LIST_SHOP)?.then(() => console.log(s.name + " updated")));
}

export function saveItemsLocal(item: Item[]) {
  return Promise.all(data.map(function (i) {
    return saveItemLocal(i);
  }));
}

function saveItemLocal(item: Item) {
  let key = item._id;
  return localDB.get(key).then(function (d) {
    let doc = d as unknown as Item;
    // update
    doc.ProductName = item.ProductName;
    doc.Quantity = item.Quantity;
    doc.PurchasePrice = item.PurchasePrice;
    doc.SalePrice = item.SalePrice;
    doc.Reserve = item.Reserve;
    doc.AdditionalSalesPrices = item.AdditionalSalesPrices;
    doc.Info = item.Info;
    doc.Category = item.Category;
    doc.MSRPPrice = item.MSRPPrice;
    return localDB.put(doc);
  })
}

export function saveSalesLocal(sales: Sale[]) {
  return Promise.all(sales.map(function (s) {
    return saveSaleLocal(s);
  }));
}

function saveSaleLocal(s: Sale) {
  let key = s._id;
  if (isPO(s)) {
    return localPO.get(key).then(function (d) {
      let doc = d as unknown as Sale;
      doc.items = s.items;
      doc.date = s.date;
      doc.shop = s.shop;
      doc.client = s.client;
      doc.total = s.total;
      doc.fee = s.fee;
      doc.discount = s.discount;
      doc.info = s.info;
      doc.paid = s.paid;
      doc.profit = s.profit;
      doc.shipment = s.shipment;
      doc.shipmentNo = s.shipmentNo;
      doc.shipping = s.shipping;
      doc.dropshipper = s.dropshipper;
      doc.print = s.print;
      doc.safepack = s.safepack;
      doc.dp = s.dp;
      doc.note = s.note;
      doc.status = s.status;
      doc.staff = s.staff;
      doc.cod = s.cod
      return localPO.put(doc);
    }).catch(function () {
      return localPO.put(s);
    });
  }
  else if (isPurchase(s)) {
    return localPurchases.get(key).then(function (d) {
      let doc = d as unknown as Sale;
      doc.items = s.items;
      doc.date = s.date;
      doc.shop = s.shop;
      doc.client = s.client;
      doc.total = s.total;
      doc.fee = s.fee;
      doc.discount = s.discount;
      doc.info = s.info;
      doc.paid = s.paid;
      doc.profit = s.profit;
      doc.shipment = s.shipment;
      doc.shipmentNo = s.shipmentNo;
      doc.shipping = s.shipping;
      doc.dropshipper = s.dropshipper;
      doc.print = s.print;
      doc.safepack = s.safepack;
      doc.dp = s.dp;
      doc.note = s.note;
      doc.status = s.status;
      doc.staff = s.staff;
      doc.cod = s.cod
      return localPurchases.put(doc);
    }).catch(function () {
      return localPurchases.put(s);
    });
  }
  else if (s.shop === BIAS) {
    return localBiasSales.get(key).then(function (d) {
      let doc = d as unknown as Sale;
      doc.items = s.items;
      doc.date = s.date;
      doc.shop = s.shop;
      doc.client = s.client;
      doc.total = s.total;
      doc.fee = s.fee;
      doc.discount = s.discount;
      doc.info = s.info;
      doc.paid = s.paid;
      doc.profit = s.profit;
      doc.shipment = s.shipment;
      doc.shipmentNo = s.shipmentNo;
      doc.shipping = s.shipping;
      doc.dropshipper = s.dropshipper;
      doc.print = s.print;
      doc.safepack = s.safepack;
      doc.dp = s.dp;
      doc.note = s.note;
      doc.status = s.status;
      doc.staff = s.staff;
      doc.cod = s.cod
      return localBiasSales.put(doc);
    }).catch(function () {
      return localBiasSales.put(s);
    });
  }
  else {
    return localSales.get(key).then(function (d) {
      let doc = d as unknown as Sale;
      doc.items = s.items;
      doc.date = s.date;
      doc.shop = s.shop;
      doc.client = s.client;
      doc.total = s.total;
      doc.fee = s.fee;
      doc.discount = s.discount;
      doc.info = s.info;
      doc.paid = s.paid;
      doc.profit = s.profit;
      doc.shipment = s.shipment;
      doc.shipmentNo = s.shipmentNo;
      doc.shipping = s.shipping;
      doc.dropshipper = s.dropshipper;
      doc.print = s.print;
      doc.safepack = s.safepack;
      doc.dp = s.dp;
      doc.note = s.note;
      doc.status = s.status;
      doc.staff = s.staff;
      doc.cod = s.cod
      return localSales.put(doc);
    }).catch(function () {
      return localSales.put(s);
    });
  }
}

export function saveUploadSale(s: Sale, inventoryID: number) {
  let db = inventoryID === LIST_PO ? LIST_PO : isPurchase(s) ? LIST_PURCHASES : inventoryID === LIST_INVENTORY ? LIST_SALES : LIST_BIASSALES;
  saveSaleLocal(s).then(() => uploadDB(db)?.then(() => console.log(s._id + ' ' + ((db === LIST_PO) ? "PO" + " added" : isPurchase(s) ? "PURCHASES" + " added" : inventoryID === LIST_INVENTORY ? "SALE" + " added" : 'BIASSALE' + " added"))));
}

export function updateSale(s: Sale, updateprint: boolean) {
  if (updateprint) updateLabel(s);
  let key = s._id;
  if (isPO(s)) {
    localPO.get(key).then(function (d) {
      let doc = d as unknown as Sale;
      doc.items = s.items;
      doc.date = s.date;
      doc.shop = s.shop;
      doc.client = s.client;
      doc.total = s.total;
      doc.fee = s.fee;
      doc.discount = s.discount;
      doc.info = s.info;
      doc.paid = s.paid;
      doc.profit = s.profit;
      doc.shipment = s.shipment;
      doc.shipmentNo = s.shipmentNo;
      doc.shipping = s.shipping;
      doc.dropshipper = s.dropshipper;
      doc.print = s.print;
      doc.safepack = s.safepack;
      doc.dp = s.dp;
      doc.note = s.note;
      doc.status = s.status;
      doc.staff = s.staff;
      doc.cod = s.cod
      return localPO.put(doc);
    }).then(() => uploadDB(LIST_PO)?.then(() => console.log(s._id + " PO updated"))).catch(function (err) {
      alert("updPO : " + err);
    });
  }
  else if (isPurchase(s)) {
    localPurchases.get(key).then(function (d) {
      let doc = d as unknown as Sale;
      doc.items = s.items;
      doc.date = s.date;
      doc.shop = s.shop;
      doc.client = s.client;
      doc.total = s.total;
      doc.fee = s.fee;
      doc.discount = s.discount;
      doc.info = s.info;
      doc.paid = s.paid;
      doc.profit = s.profit;
      doc.shipment = s.shipment;
      doc.shipmentNo = s.shipmentNo;
      doc.shipping = s.shipping;
      doc.dropshipper = s.dropshipper;
      doc.print = s.print;
      doc.safepack = s.safepack;
      doc.dp = s.dp;
      doc.note = s.note;
      doc.status = s.status;
      doc.staff = s.staff;
      doc.cod = s.cod
      return localPurchases.put(doc);
    }).then(() => uploadDB(LIST_PURCHASES)?.then(() => console.log(s._id + " updated"))).catch(function (err) {
      alert("updPsale : " + err);
    });
  }
  else if (s.shop === BIAS) {
    localBiasSales.get(key).then(function (d) {
      let doc = d as unknown as Sale;
      doc.items = s.items;
      doc.date = s.date;
      doc.shop = s.shop;
      doc.client = s.client;
      doc.total = s.total;
      doc.fee = s.fee;
      doc.discount = s.discount;
      doc.info = s.info;
      doc.paid = s.paid;
      doc.profit = s.profit;
      doc.shipment = s.shipment;
      doc.shipmentNo = s.shipmentNo;
      doc.shipping = s.shipping;
      doc.dropshipper = s.dropshipper;
      doc.print = s.print;
      doc.safepack = s.safepack;
      doc.dp = s.dp;
      doc.note = s.note;
      doc.status = s.status;
      doc.staff = s.staff;
      doc.cod = s.cod
      return localBiasSales.put(doc);
    }).then(() => uploadDB(LIST_BIASSALES)?.then(() => console.log(s._id + " bias updated"))).catch(function (err) {
      alert("updBSale : " + err);
    });
  }
  else {
    localSales.get(key).then(function (d) {
      let doc = d as unknown as Sale;
      doc.items = s.items;
      doc.date = s.date;
      doc.shop = s.shop;
      doc.client = s.client;
      doc.total = s.total;
      doc.fee = s.fee;
      doc.discount = s.discount;
      doc.info = s.info;
      doc.paid = s.paid;
      doc.profit = s.profit;
      doc.shipment = s.shipment;
      doc.shipmentNo = s.shipmentNo;
      doc.shipping = s.shipping;
      doc.dropshipper = s.dropshipper;
      doc.print = s.print;
      doc.safepack = s.safepack;
      doc.dp = s.dp;
      doc.note = s.note;
      doc.status = s.status;
      doc.staff = s.staff;
      doc.cod = s.cod
      return localSales.put(doc);
    }).then(() => uploadDB(LIST_SALES)?.then(() => console.log(s._id + " updated"))).catch(function (err) {
      alert("updSale : " + err);
    });
  }
}

export function removeFromDB(key: string, id: number) {
  return new Promise((resolve, reject) => {
    switch (id) {
      case LIST_INVENTORY: {
        localDB.get(key).then(function (doc) {
          resolve(localDB.remove(doc).then(() => uploadDB(LIST_INVENTORY)?.then(() => console.log(doc._id + " deleted"))));
        }).catch(function (err) {
          alert("rem-inv : " + err);
          reject(err);
        });
        break;
      }
      case LIST_SALES: {
        localSales.get(key).then(function (doc) {
          resolve(localSales.remove(doc).then(() => uploadDB(LIST_SALES)?.then(() => console.log(doc._id + " deleted"))));
        }).catch(function (err) {
          alert("rem-sale : " + err);
          reject(err);
        });
        break;
      }
      case LIST_PURCHASES: {
        localPurchases.get(key).then(function (doc) {
          resolve(localPurchases.remove(doc).then(() => uploadDB(LIST_PURCHASES)?.then(() => console.log(doc._id + " deleted"))));
        }).catch(function (err) {
          alert("rem-psale : " + err);
          reject(err);
        });
        break;
      }
      case LIST_BIASINVENTORY: {
        localBiasDB.get(key).then(function (doc) {
          resolve(localBiasDB.remove(doc).then(() => uploadDB(LIST_BIASINVENTORY)?.then(() => console.log(doc._id + " deleted from Bias"))));
        }).catch(function (err) {
          alert("rem-binv : " + err);
          reject(err);
        });
        break;
      }
      case LIST_BIASSALES: {
        localBiasSales.get(key).then(function (doc) {
          resolve(localBiasSales.remove(doc).then(() => uploadDB(LIST_BIASSALES)?.then(() => console.log(doc._id + " deleted from Bias"))));
        }).catch(function (err) {
          alert("rem-bsale : " + err);
          reject(err);
        });
        break;
      }
      case LIST_PO: {
        localPO.get(key).then(function (doc) {
          resolve(localPO.remove(doc).then(() => uploadDB(LIST_PO)?.then(() => console.log(doc._id + " deleted from PO"))));
        }).catch(function (err) {
          alert("rem-po : " + err);
          reject(err);
        });
        break;
      }
      case LIST_PERSON: {
        localContacts.get(key).then(function (doc) {
          resolve(localContacts.remove(doc).then(() => uploadDB(LIST_PERSON)?.then(() => console.log(doc._id + " deleted"))));
        }).catch(function (err) {
          alert("rem-person : " + err);
          reject(err);
        });
        break;
      }
      case LIST_LOG: {
        localKonten.get(key).then(function (doc) {
          resolve(localKonten.remove(doc).then(() => uploadDB(LIST_LOG)?.then(() => console.log(doc._id + " deleted"))));
        }).catch(function (err) {
          alert("rem-log : " + err);
          reject(err);
        });
        break;
      }
      case LIST_SHOP: {
        localShop.get(key).then(function (doc) {
          resolve(localShop.remove(doc).then(() => uploadDB(LIST_SHOP)?.then(() => console.log(doc._id + " deleted"))));
        }).catch(function (err) {
          alert("rem-shop : " + err);
          reject(err);
        });
        break;
      }
      case LIST_CAT: {
        localCategory.get(key).then(function (doc) {
          resolve(localCategory.remove(doc).then(() => uploadDB(LIST_CAT)?.then(() => console.log(doc._id + " deleted"))));
        }).catch(function (err) {
          alert("rem-cat : " + err);
          reject(err);
        });
        break;
      }
      case LIST_KURIR: {
        localKurir.get(key).then(function (doc) {
          resolve(localKurir.remove(doc).then(() => uploadDB(LIST_KURIR)?.then(() => console.log(doc._id + " deleted"))));
        }).catch(function (err) {
          alert("rem-kurir : " + err);
          reject(err);
        });
        break;
      }
      case LIST_GHEIM: {
        localGheim.get(key).then(function (doc) {
          resolve(localGheim.remove(doc).then(() => uploadDB(LIST_GHEIM)?.then(() => console.log(doc._id + " deleted from Gheim"))));
        }).catch(function (err) {
          alert("rem-gh : " + err);
          reject(err);
        });
        break;
      }
      case LIST_NOTES: {
        localNotes.get(key).then(function (doc) {
          resolve(localNotes.remove(doc).then(() => uploadDB(LIST_NOTES)?.then(() => console.log(doc._id + " deleted from Notes"))));
        }).catch(function (err) {
          alert("rem-notes : " + err);
          reject(err);
        });
        break;
      }
      case LIST_INVOICES: {
        localInvoices.get(key).then(function (doc) {
          resolve(localInvoices.remove(doc).then(() => uploadDB(LIST_INVOICES)?.then(() => console.log(doc._id + " deleted from Invoices"))));
        }).catch(function (err) {
          alert("rem-invs : " + err);
          reject(err);
        });
        break;
      }
      case LIST_USER: {
        localUser.get(key).then(function (doc) {
          resolve(localUser.remove(doc).then(() => uploadDB(LIST_USER)?.then(() => console.log(doc._id + " deleted from User"))));
        }).catch(function (err) {
          alert("rem-user : " + err);
          reject(err);
        });
        break;
      }
      default: break;
    }
  });
}
