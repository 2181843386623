import React, { SyntheticEvent, useRef, useState } from 'react';
import { IonItem, IonAvatar, IonLabel, IonInput, IonTextarea, IonIcon, IonButton, IonCheckbox, IonAlert, isPlatform, AlertInput, IonModal, IonHeader, IonToolbar, IonSearchbar, IonContent, IonTitle, IonFabButton } from '@ionic/react';
import { Sale, SalesItem, formatCurrency, DEFAULTSHOP, BIAS, setKurirFromResi, ALL, Item, RUPIAH } from '../functions/DataModel';
import { STR_Paid, STR_Quantity, STR_Price, STR_Items, STR_Info, STR_Discount, STR_Profit, STR_Shipment, STR_WarningInventoryNotUpdated, STR_additionalCost, STR_Cost, STR_extra, STR_OK, STR_CANCEL, STR_Resi, STR_Purchase, STR_Fee, STR_Add, STR_Shop, STR_Invoice, STR_Client, STR_ID, STR_Address, STR_hide, STR_show, STR_Dropshipper, STR_AddPO, STR_DP, STR_SaleNote, STR_NotPaid, STR_Name, STR_Key, STR_EditSale, STR_Pay, STR_COD, STR_ShipmentLabel, STR_Sure, STR_Retur, STR_Problem } from '../lang/en';
import { printDate, printDateS, updateTotal, isPurchase, setPaid, updateShopBalance, getShop, updateSaleShopFee, getImageURL, addShopBalance, removeShopBalance, isMPrint, toggleMPSale, mprint, insideSList, LIST_PO, materializePO, printSaleDone, kurir, cloneToPO, isAdmin, getNumberIDStaff, isMGProvided, isBias, LIST_PURCHASES, LIST_SALES, biasdata, data, getFilteredList, getPersonID, isPO, setstatus, printmode, getCurrentUserShopKonto, shops } from '../functions/DataFunctions';
import { send, close, trash, printOutline, pencil, checkmark, print, homeOutline, bagCheckOutline, boatOutline, warningOutline, copyOutline, busOutline, giftSharp, cashOutline, flashOutline } from 'ionicons/icons';
import { setItemsImage, updateSale } from '../functions/LocalStorage';
import { setToRemove } from './ListModal';
import { generatePurchasePDF, generateReceiptLabel, invoicePDF, printLabel, printShipmentLabel } from '../functions/Output';
import PersonCard from './PersonCard';
import { user } from './AppContextProvider';
import { users } from './Login';
import { setToULRemove, updateTransactionTab } from '../pages/Tab2';
import { updateList } from './List';
import { ItemList } from './ItemList';
import { SalesList } from './SalesList';
import { addBCA, addBCADP } from '../functions/Log';
import { setSelectedSale } from './LogModal';

interface ContainerProps {
  sale: Sale;
  type: number;
  rel: any;
  po: boolean
}

export const REMOVE = 0;
export const PAY = 1;
export const EDIT = 2;
export const SELECT = 3;
export const ULREMOVE = 4;

const SalesCard: React.FC<ContainerProps> = ({ sale, type, rel, po }) => {

  const [showYNAlert, setShowYNAlert] = useState(0);
  const [show, setShow] = useState(false);

  const updateTotalEl = useRef(null);
  const [sug, setSug] = useState<Item[]>([]);
  const updateAmountE = useRef(null);
  const [amount, setAmount] = useState('');

  const defaultImage = "assets/img/item.jpg";
  const productImage = sale.items[0] ? getImageURL(sale.items[0].item) : defaultImage;

  const [showItem, setShowItem] = useState(type === EDIT ? true : false);
  const [reload, setReload] = useState(0);
  const [wShippingL, setWShippingL] = useState(false);
  const [showAddress, setShowAddress] = useState(false);

  function img404(event: SyntheticEvent<HTMLImageElement, Event>) {
    (event.currentTarget as HTMLImageElement).src = defaultImage;
  }


  function enableEdit(e: React.MouseEvent, id: string, item: SalesItem) {
    e.stopPropagation();
    let el = e.currentTarget.previousElementSibling as HTMLInputElement;

    if (!el.disabled) {
      switch (id) {
        case 'q': {
          update(Number.parseInt(el.value), item, 0)
          break;
        }
        case 'p': {
          update(Number.parseFloat(el.value.replace(".", "")), item, 1);
          break;
        }
        case 'i': {
          sale.info = el.value;
          updateSale(sale, true);
          break;
        }
        case 'n': {
          sale.note = el.value;
          updateSale(sale, true);
          break;
        }
        case 'ds': {
          sale.dropshipper = el.value;
          updateSale(sale, true);
          break;
        }
        case 'name': {
          item.item.ProductName = el.value;
          updateSale(sale, true);
          break;
        }
        default: break;
      }
      el.disabled = true;
      let ic = e.currentTarget.firstElementChild as HTMLIonIconElement;
      ic.icon = pencil;
      ic.color = "primary";
    }
    else {
      el.disabled = false;
      let ic = e.currentTarget.firstElementChild as HTMLIonIconElement;
      ic.icon = checkmark;
      ic.color = "success";
      el.focus();
    }
  }

  function fillItem(idx: number, item: SalesItem) {
    return (
      <IonItem key={idx}>
        <IonAvatar slot="start">
          <img src={getImageURL(item.item)} alt='' onError={img404}></img>
        </IonAvatar>
        <IonLabel>
          <IonItem>
            <IonInput className='inputE' disabled={true} value={item.item.ProductName} />
            <IonButton hidden={!(isAdmin() || isBias())} slot="end" fill="clear" size="small" onClick={e => enableEdit(e, 'name', item)}><IonIcon icon={pencil} /></IonButton>
          </IonItem>
          <div className="quantity inputE"><span className="label">{STR_Quantity}</span><br />
            <IonItem>
              <IonInput className='center' disabled={true} type="number" min="1" value={item.quantity} />
              <IonButton hidden={!(isAdmin() || isBias())} className='saleQbutton' slot="end" fill="clear" size="small" onClick={e => enableEdit(e, 'q', item)}><IonIcon icon={pencil} /></IonButton></IonItem>
          </div>
          <div className="price inputE"><span className="label">{isPurchase(sale) ? STR_Purchase + ' ' + STR_Price : STR_Price}</span><br />
            <IonItem>
              <IonInput className='center' disabled={true} type="number" min="0" step={'500'} value={item.price} />
              <IonButton hidden={!(isAdmin() || isBias())} className='saleQbutton' slot="end" fill="clear" size="small" onClick={e => enableEdit(e, 'p', item)}><IonIcon icon={pencil} /></IonButton></IonItem>
          </div>
        </IonLabel>
      </IonItem>
    );
  }

  function update(value: number, item: SalesItem, n: number) {
    if (n === 0) {
      if (value >= 0) { item.quantity = value; }
      else {
        return;
      }
    }
    else if (n === 1) {
      if (value >= 0) { item.price = value; }
      else {
        return;
      }
    }
    updateSalesTotal();
  }

  function additionalCost(sale: Sale, cost: number) {
    sale.fee = sale.fee + +cost;
    const text = cost > 0 ? STR_Cost : STR_Profit;
    sale.info = sale.info + " [" + STR_extra + ' ' + text + " : " + Math.abs(cost) + "]";
    if (!isPurchase(sale)) updateShopBalance(sale);
    if (type !== REMOVE) rel.current.click();
  }

  function updateSalesTotal() {
    updateTotal(sale);
    sale.profit = sale.profit - sale.fee - sale.discount;
    if (!isPurchase(sale)) updateShopBalance(sale);
    setReload(reload + 1);
    if (type !== REMOVE) rel.current.click();
  }

  function addShop(sale: Sale, shop: string) {
    if (sale.shop !== DEFAULTSHOP[0] && sale.shop !== shop && getCurrentUserShopKonto().includes(sale.shop)) {
      removeShopBalance(sale);
      let prev = getShop(sale.shop);
      sale.shop = shop;
      let s = getShop(shop);
      if (prev && s) {
        let p = sale.profit + (prev.fee * sale.total);
        let f = sale.fee - (prev.fee * sale.total);
        sale.fee = f + (s.fee * sale.total);
        sale.profit = p - (s.fee * sale.total);
      }
      if (!isPurchase(sale)) {
        addShopBalance(sale, true);
        updateSale(sale, true);
      }
    }
    else {
      sale.shop = shop;
      let s = getShop(shop);
      if (s) {
        sale.fee = sale.fee + (s.fee * sale.total);
        sale.profit = sale.profit - (s.fee * sale.total);
      }
      if (!isPurchase(sale)) updateShopBalance(sale);
    }
  }

  const getHeader = () => {
    switch (showYNAlert) {
      case 1: return STR_Shipment;
      case 2: return STR_Shop + ' ' + STR_Fee;
      case 3: return STR_additionalCost;
      case 4: return STR_Shop;
      case 5: return STR_Client;
      case 6: return STR_Shipment;
      case 7: return STR_DP;
      case 11: return STR_Sure;
      case LIST_PO + 100: return STR_AddPO;
      default: return '';
    }
  }

  function selectshop() {
    let select: AlertInput[] = [];
    for (var i = 0; i < shops.length; i++) {
      select.push({
        type: 'radio',
        label: shops[i]._id,
        value: shops[i].name,
        checked: sale.shop === shops[i]._id
      });
    }
    return select;
  }

  function selectkurir() {
    let select: AlertInput[] = [];
    for (var i = 0; i < kurir.length; i++) {
      select.push({
        type: 'radio',
        label: kurir[i]._id,
        value: kurir[i]._id,
        checked: sale.shipment === kurir[i]._id
      });
    }
    return select;
  }

  function selectStaff() {
    let select: AlertInput[] = [];
    for (var i = 0; i < users.length; i++) {
      select.push({
        type: 'radio',
        label: users[i]._id,
        value: users[i]._id,
        checked: sale.staff === users[i]._id
      });
    }
    return select;
  }

  function searchItem(e: Event, text: string) {
    const popup = (e.currentTarget as HTMLElement).parentElement?.nextElementSibling as HTMLElement;
    if (text.length > 0) {
      let idata = (sale.shop === BIAS) ? biasdata : data;
      let filtered = getFilteredList(idata, ALL, text, 20);
      if (filtered.length > 0) popup.style.display = 'block';
      else popup.style.display = 'none';
      setItemsImage(filtered).then(() => setSug(filtered));
    }
    else {
      setSug([]);
      popup.style.display = 'none';
    }
  }

  if (isAdmin() || sale.staff === user.staff)
    return (
      <>
        <IonItem color={
          sale.status === STR_Retur ? 'secondary' : sale.status === STR_Problem ? 'warning' : ''
          //sale.cod > 0 ? 'secondary' : sale.dropshipper.length > 0 ? 'warning' : ''
        } lines="full" button onClick={e => { e.stopPropagation(); setShowItem(!showItem) }}>
          {printmode && !mprint ? <IonFabButton className="marginR8" slot="start" size="small" onClick={e => { e.stopPropagation(); isPlatform('hybrid') ? printLabel(sale, true, false) : generateReceiptLabel(sale!, true); }}><IonIcon icon={printOutline} /></IonFabButton> : ''}
          {type !== SELECT && mprint && isAdmin() && !isPurchase(sale) && !isPO(sale) && sale.shop !== BIAS ?
            <IonCheckbox className="marginR8" slot="start" checked={isMPrint(sale._id)} onClick={e => { e.stopPropagation(); toggleMPSale(sale, true, true).then((bool) => { e.currentTarget.checked = bool; type === REMOVE ? rel.current.click() : updateTransactionTab() }) }} /> : ''}
          {type !== SELECT && mprint && !isPurchase(sale) && !isPO(sale) ? isAdmin() && sale.shop === BIAS ? '' :
            <IonCheckbox className="inlineCheckbox marginR8" slot="start" color="tertiary" checked={insideSList(sale._id)} onClick={e => { e.stopPropagation(); toggleMPSale(sale, true, false).then((bool) => { e.currentTarget.checked = bool; type === REMOVE ? rel.current.click() : updateTransactionTab() }) }} /> : ''}
          {type === SELECT && isAdmin() && !isPurchase(sale) && !isPO(sale) && sale.shop !== BIAS ?
            <IonButton slot="start" onClick={e => { e.stopPropagation(); setSelectedSale(sale); rel.current.click() }}><IonIcon icon={checkmark} /></IonButton> : ''}
          {type === ULREMOVE ? <IonButton className="marginR8 no-print" slot="start" fill='clear' onClick={e => { e.stopPropagation(); setShowYNAlert(11); }}><IonIcon slot="icon-only" icon={trash} /></IonButton> : ''}
          <IonAvatar slot="start">
            <img src={productImage} alt='' onError={img404}></img>
          </IonAvatar>
          <IonLabel>
            <div>
              <div className="status">
                <span className='smallerx'>{formatCurrency(getShop(sale.shop)?.currency!, sale.shipping)} [{sale.shipmentNo}] {sale.shipment}</span>
                <IonButton slot="start" fill="clear" size="small" className={sale.status === STR_Retur ? "printactive inlineButton" : "printpassive inlineButton"} onClick={e => { e.stopPropagation(); setstatus(sale, STR_Retur); setReload(reload + 1); rel.current.click(); }}><IonIcon slot="icon-only" icon={homeOutline} /></IonButton>
                <IonButton slot="start" fill="clear" size="small" className={sale.status === STR_Problem ? "dangeractive inlineButton" : "dangerpassive inlineButton"} onClick={e => { e.stopPropagation(); setstatus(sale, STR_Problem); setReload(reload + 1); rel.current.click(); }}><IonIcon slot="icon-only" icon={flashOutline} /></IonButton>
                {sale.cod > 0 ? <IonButton slot="start" disabled fill="clear" size="small" className={sale.cod > 0 ? "disabledactive inlineButton" : "disabledpassive inlineButton"}><IonIcon slot="icon-only" icon={cashOutline} /></IonButton> : ''}
                {sale.dropshipper.length > 0 ? <IonButton slot="start" disabled fill="clear" size="small" className={sale.dropshipper.length > 0 ? "disabledactive inlineButton" : "disabledpassive inlineButton"}><IonIcon slot="icon-only" icon={giftSharp} /></IonButton> : ''}
                {sale.dp > 0 ? <IonButton slot="start" disabled fill="clear" size="small" className={sale.dp > 0 ? "disabledactive inlineButton" : "disabledpassive inlineButton"}><IonIcon slot="icon-only" icon={bagCheckOutline} /></IonButton> : ''}
                {!isPurchase(sale) ? <IonButton slot="start" fill="clear" size="small" className={sale.print ? "printactive inlineButton" : "printpassive inlineButton"} onClick={e => { e.stopPropagation(); printSaleDone(sale, !sale.print); setReload(reload + 1); rel.current.click(); }}>
                  <IonIcon slot="icon-only" icon={printOutline} /></IonButton> : ''}
                {sale.staff && sale.staff.length > 1 ? <span className="staff" onClick={() => isAdmin() ? setShowYNAlert(8) : ''}> {sale.staff}  </span> : ''}{getNumberIDStaff(sale._id)}</div>
            </div>
            <div className="leftalign fulltext">
              <h3>{printDate(sale.date)}</h3>
              <h2>{getPersonID(sale.client)}
                <IonButton className="inlineButton" size="small" fill="clear" onClick={e => { e.stopPropagation(); setShowYNAlert(5) }}>{sale.client._id !== '' ? <IonIcon icon={pencil} /> : STR_Add + ' ' + STR_Client}</IonButton>
              </h2>
              {sale.items.length} {STR_Items}
              {isAdmin() && sale.fee !== 0 ? ", " + STR_Fee + ": " + sale.fee : ''}<IonButton hidden={!isAdmin()} className="inlineButton" size="small" fill="clear" onClick={e => { e.stopPropagation(); setShowYNAlert(2) }}><IonIcon icon={pencil} /></IonButton>
              <span className='warning'>{sale.discount !== 0 ? ", " + STR_Discount + ": " + sale.discount : ''}</span>
              {isAdmin() && sale.profit !== 0 ? ", " + STR_Profit + ": " + sale.profit : ''}
              {isAdmin() ? <><span className='paidtext' >{sale.paid !== null ? ", " + STR_Paid + ": " + printDateS(sale.paid) : type === PAY ? ' ' : ''}</span>
                {type === PAY && isMGProvided(sale).bool ? <IonButton size="small" fill='clear' className={sale.paid == null ? "no-print inlineButton" : "no-print inlineButton"} onClick={e => {
                  e.stopPropagation();
                  setPaid(sale, new Date());
                  setReload(reload + 1);
                  rel.current.click();
                }}>
                  <IonIcon icon={sale.paid == null ? send : close} />
                </IonButton>
                  : ''}</> : ''}
            </div>
            <div className="rightalign smaller">{sale.shop === DEFAULTSHOP[0] ?
              <IonButton className="inlineButton" size="small" fill="clear" onClick={e => { e.stopPropagation(); setShowYNAlert(4) }}>{STR_Add} {STR_Shop}</IonButton>
              : <IonButton className="inlineButton" size="small" fill="clear" onClick={e => { e.stopPropagation(); setShowYNAlert(4) }}>{sale.shop}</IonButton>}</div>
            <div className="rightalign small">{formatCurrency(getShop(sale.shop)?.currency!, sale.total)}</div>
            {sale.cod > 0 ? <div className="rightalign smallerx2" onClick={e => { e.stopPropagation(); setShowYNAlert(10) }}>{STR_COD}{sale.cod && sale.cod > 0 ? ' : ' : ''}{formatCurrency(getShop(sale.shop)?.currency!, sale.cod ? sale.cod : 0)}</div>
              : sale.shipping > 0 ? <div className="rightalign smallerx2">{formatCurrency(getShop(sale.shop)?.currency!, sale.total + +sale.shipping)}</div> : ''}
          </IonLabel>
          <IonButton className="endButton no-print" slot="end" onClick={e => {
            e.stopPropagation();
            cloneToPO(sale);
          }}><IonIcon slot="icon-only" icon={copyOutline} /></IonButton>
          {type === REMOVE && (isAdmin() || isBias()) ? <IonButton className="endButton no-print" slot="end" onClick={e => {
            e.stopPropagation();
            setToRemove(sale);
            rel.current.click();
          }}><IonIcon slot="icon-only" icon={trash} /></IonButton>
            : ''}
          {po ? <IonButton slot="end" onClick={e => setShowYNAlert(LIST_PO + 100)}><IonIcon slot="icon-only" icon={bagCheckOutline} /></IonButton>
            : ''}
        </IonItem>
        <div hidden={!showItem} className={
          //sale.cod > 0 ? 'roundBorder margin10 borderblue' : sale.dropshipper.length > 0 ? 'roundBorder margin10 borderwarning' : "roundBorder margin10"
          sale.status === STR_Retur ? 'roundBorder margin10 borderblue' : sale.status === STR_Problem ? 'roundBorder margin10 borderwarning' : "roundBorder margin10"}>
          <div className="fleft middle">
            {isPurchase(sale) ? <IonButton slot="start" onClick={e => {
              e.stopPropagation();
              generatePurchasePDF(sale);
            }}><IonIcon slot="icon-only" icon={print} /></IonButton>
              :
              <>
                <IonButton slot="start" onClick={e => { e.stopPropagation(); printLabel(sale, wShippingL, true) }}>
                  <IonCheckbox className="marginlr" slot="start" checked={wShippingL} onClick={e => { e.stopPropagation(); setWShippingL(!wShippingL) }} />
                  {isPlatform('desktop') ? <IonLabel hidden={isPO(sale)}>{STR_Shipment}</IonLabel> : ''}
                  <IonIcon slot="icon-only" icon={printOutline} />
                </IonButton>
                <IonButton slot="start" onClick={e => { e.stopPropagation(); invoicePDF(sale, wShippingL) }}>
                  <IonCheckbox className="marginlr" slot="start" checked={wShippingL} onClick={e => { e.stopPropagation(); setWShippingL(!wShippingL) }} />
                  <IonLabel className="marginlr">{isPlatform('desktop') ? STR_Invoice : STR_Invoice.substring(0, 3)}</IonLabel>
                  <IonIcon slot="icon-only" icon={printOutline} />
                </IonButton>
                <IonButton slot="start" onClick={e => { e.stopPropagation(); printShipmentLabel(sale) }}>
                  {isPlatform('desktop') ? <IonLabel className="marginlr">{STR_ShipmentLabel}</IonLabel> : ''}
                  <IonIcon slot="icon-only" icon={busOutline} />
                </IonButton></>
            }
          </div>
          <div className="fleft middle">
            <IonButton slot="start" onClick={e => { e.stopPropagation(); setShowAddress(!showAddress) }}>
              <IonLabel className="marginlr">{showAddress ? STR_hide : STR_show}{isPlatform('desktop') ? ' ' + STR_Address : ''}</IonLabel>
              <IonIcon slot="icon-only" icon={homeOutline} />
            </IonButton>
          </div>
          <div hidden={!showAddress} className="clearboth roundBorder margin10">
            <PersonCard person={sale.client} rel={null} sale={sale} />
          </div>
          <IonItem className="fright">
            <IonLabel>{formatCurrency(getShop(sale.shop)?.currency!, sale.shipping)} [{sale.shipmentNo}] {sale.shipment}</IonLabel>
            <IonButton className="marginlr" onClick={e => { e.stopPropagation(); setShowYNAlert(6) }}><IonIcon icon={boatOutline} /></IonButton>
            <IonButton onClick={e => { e.stopPropagation(); setShowYNAlert(1) }}><IonIcon icon={pencil} /></IonButton>
            <IonButton className={sale.safepack ? "printactive" : "printpassive"} fill='clear' onClick={e => { e.stopPropagation(); sale.safepack = !sale.safepack; updateSale(sale, true); setReload(reload + 1) }}><IonIcon icon={warningOutline} /></IonButton>
          </IonItem>
          <IonItem className="fright">
            <IonButton slot="end" onClick={e => { e.stopPropagation(); setShowYNAlert(3) }}><IonLabel>{STR_additionalCost}</IonLabel></IonButton>
          </IonItem>
          <IonItem className="fright">
            <IonButton slot="end" fill='clear' onClick={e => { e.stopPropagation(); setShowYNAlert(10) }}><IonLabel>{STR_COD}{sale.cod && sale.cod > 0 ? ' : ' : ''}{formatCurrency(getShop(sale.shop)?.currency!, sale.cod ? sale.cod : 0)}</IonLabel></IonButton>
            <IonButton slot="end" fill='clear' onClick={e => { e.stopPropagation(); setShowYNAlert(9) }}><IonLabel>{STR_Discount}{sale.discount && sale.discount > 0 ? ' : ' : ''}{formatCurrency(getShop(sale.shop)?.currency!, sale.discount ? sale.discount : 0)}</IonLabel></IonButton>
          </IonItem>
          <IonItem className="fright">
            <IonButton slot="end" fill='clear' onClick={e => { e.stopPropagation(); setShowYNAlert(7) }}><IonLabel>{STR_DP}{sale.dp && sale.dp > 0 ? ' : ' : ''}{formatCurrency(getShop(sale.shop)?.currency!, sale.dp ? sale.dp : 0)}</IonLabel></IonButton>
            {sale.dp && sale.dp > 0 ? <IonButton hidden={!isAdmin()} slot="end" onClick={e => { e.stopPropagation(); addBCADP(sale) }}><IonLabel>{STR_DP} BCA</IonLabel></IonButton> : ''}
            <IonButton hidden={!isAdmin()} slot="end" onClick={e => { e.stopPropagation(); addBCA(sale) }}><IonLabel>{STR_Pay} BCA</IonLabel></IonButton>
          </IonItem>
          <IonItem className='clearboth'>
            <IonTextarea className="clearboth fullwidth" disabled={true} placeholder={STR_Info} value={sale.info} ></IonTextarea>
            <IonButton slot="end" fill="clear" size="large" onClick={e => enableEdit(e, 'i', null as unknown as SalesItem)}><IonIcon icon={pencil} /></IonButton>
          </IonItem>
          <div className="clearboth fullwidth">
            <IonLabel position='fixed' class="fulltext smaller">{STR_Dropshipper}</IonLabel>
            <IonItem>
              <IonInput className="fullwidth" disabled={true} placeholder={STR_Dropshipper} value={sale.dropshipper} ></IonInput>
              <IonButton slot="end" fill="clear" size="large" onClick={e => enableEdit(e, 'ds', null as unknown as SalesItem)}><IonIcon icon={pencil} /></IonButton>
            </IonItem>
          </div>
          <IonItem className='clearboth'>
            <IonTextarea className="clearboth fullwidth" disabled={true} placeholder={STR_SaleNote} value={sale.note} ></IonTextarea>
            <IonButton slot="end" fill="clear" size="large" onClick={e => enableEdit(e, 'n', null as unknown as SalesItem)}><IonIcon icon={pencil} /></IonButton>
          </IonItem>
          <IonLabel class="fulltext risk smaller">{STR_WarningInventoryNotUpdated}</IonLabel>
          {sale.items.map((item, idx) => fillItem(idx, item))}
          {isAdmin() ? <IonButton expand="full" onClick={() => setShow(true)}>+</IonButton> : ''}
        </div>
        <IonModal isOpen={show} swipeToClose={true} backdropDismiss={true} onDidDismiss={() => { setShow(false); updateSalesTotal() }}>
          <IonHeader>
            <IonToolbar>
              <IonButton slot="start" onClick={() => setShow(false)}><IonIcon slot="icon-only" icon={close} /></IonButton>
              <IonTitle>{STR_EditSale}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonHeader>
            <IonButton ref={updateAmountE} hidden onClick={() => setAmount(formatCurrency(RUPIAH, sale.total))} />
            <IonHeader className='center large'>{amount === '' ? formatCurrency(RUPIAH, sale.total) : amount}</IonHeader>
            <IonToolbar>
              <IonSearchbar slot="start" placeholder={STR_Name + "/" + STR_Key} onIonChange={e => searchItem(e, e.detail.value!)} ></IonSearchbar>
            </IonToolbar>
            <div className="suggestions hidden">
              <ItemList uRel={updateTotalEl} cat={ALL} data={sug} type={isPurchase(sale) ? LIST_PURCHASES : LIST_SALES} sale={sale} />
            </div>
          </IonHeader>
          <IonContent>
            <IonHeader collapse="condense">
            </IonHeader>
            <div>
              <SalesList mode={isPurchase(sale) ? LIST_PURCHASES : LIST_SALES} sales={sale.items} sale={sale}></SalesList>
            </div>
            <IonButton ref={updateTotalEl} hidden onClick={() => updateSalesTotal()} />
          </IonContent>
        </IonModal>
        <IonAlert isOpen={showYNAlert !== 0} onDidDismiss={() => setShowYNAlert(0)} header={getHeader()}
          inputs={
            showYNAlert === LIST_PO + 100 ? [] :
              showYNAlert === 10 ?
                [
                  {
                    name: 'cod',
                    placeholder: STR_COD,
                    value: sale.cod,
                    type: "number"
                  }
                ]
                :
                showYNAlert === 9 ?
                  [
                    {
                      name: 'discount',
                      placeholder: STR_Discount,
                      value: sale.discount,
                      type: "number"
                    }
                  ]
                  :
                  showYNAlert === 7 ?
                    [
                      {
                        name: 'dp',
                        placeholder: STR_DP,
                        value: sale.dp,
                        type: "number"
                      }
                    ]
                    :
                    showYNAlert === 5 ?
                      [
                        {
                          name: 'client',
                          placeholder: STR_Client + ' ' + STR_ID,
                          value: getPersonID(sale.client)
                        }
                      ]
                      :
                      showYNAlert === 4 ? selectshop()
                        :
                        showYNAlert === 1 ?
                          [
                            {
                              name: 'resi',
                              placeholder: STR_Resi,
                              value: sale.shipmentNo
                            },
                            {
                              name: 'cost',
                              placeholder: STR_Cost,
                              value: sale.shipping,
                              type: "number"
                            }]
                          :
                          showYNAlert === 6 ? selectkurir()
                            :
                            showYNAlert === 8 ? selectStaff()
                              :
                              showYNAlert !== 11 ?
                                [
                                  {
                                    name: 'fee',
                                    placeholder: STR_Fee,
                                    value: showYNAlert === 2 ? getShop(sale.shop)?.fee : 0,
                                    type: "number"
                                  }
                                ] : []}
          buttons={[
            {
              text: STR_CANCEL,
              role: 'cancel',
              cssClass: 'secondary'
            }, {
              text: STR_OK,
              handler: (data) => {
                try {
                  switch (showYNAlert) {
                    case 1: {
                      let resi = data.resi;
                      resi = resi.trim();
                      while (resi.includes('#')) {
                        resi = resi.replace('#', '').trim();
                      }
                      resi = resi.split(' ')[0].trim();
                      sale.shipmentNo = resi;
                      setKurirFromResi(sale.shipmentNo, sale);
                      if (data.cost) sale.shipping = Number.parseFloat(data.cost.replace(".", ""));
                      updateSale(sale, true);
                      break;
                    }
                    case 2: {
                      updateSaleShopFee(sale, data.fee);
                      break;
                    }
                    case 3: {
                      additionalCost(sale, Number.parseFloat(data.fee.replace(".", "")));
                      break;
                    }
                    case 4: {
                      addShop(sale, data);
                      break;
                    }
                    case 5: {
                      sale.client._id = user.user + '|' + data.client;
                      updateSale(sale, true);
                      break;
                    }
                    case 6: {
                      sale.shipment = data;
                      updateSale(sale, true);
                      break;
                    }
                    case 7: {
                      sale.dp = Number.parseFloat(data.dp.replace(".", ""));
                      if (sale.dp > 0 && sale.status === STR_NotPaid) sale.status = STR_DP;
                      else if (sale.dp === 0 && sale.status !== STR_Paid) sale.status = STR_NotPaid;
                      updateSale(sale, true);
                      break;
                    }
                    case 8: {
                      sale.staff = data;
                      updateSale(sale, false);
                      break;
                    }
                    case 9: {
                      let disc = Number.parseFloat(data.discount.replace(".", ""));
                      sale.total = sale.total + sale.discount - disc;
                      sale.discount = disc;

                      let oldfee = sale.fee;
                      let total = sale.total - sale.dp;
                      sale.fee = (getShop(sale.shop)!.fee * total);
                      sale.profit = (sale.profit + oldfee) - sale.fee;
                      sale.fee = sale.fee + +sale.dp;

                      updateShopBalance(sale);
                      break;
                    }
                    case 10: {
                      sale.cod = Number.parseFloat(data.cod.replace(".", ""));
                      updateSale(sale, true);
                      break;
                    }
                    case 11: {
                      setToULRemove(sale);
                      rel.current.click();
                      break;
                    }
                    case LIST_PO + 100: {
                      materializePO(sale);
                      updateTransactionTab();
                      updateList();
                      rel.current.click();
                      break;
                    }
                    default: break;
                  }
                }
                catch (error) {
                  alert(error);
                }
              }
            }
          ]}></IonAlert>
      </>
    );
  else return (
    <></>
  );
};

export default SalesCard;