import React, { useState, useRef } from 'react';
import { IonList, IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonFooter, IonButton, IonIcon, IonModal, IonAlert, IonLabel } from '@ionic/react';
import { STR_Balance, STR_Add, STR_Account, STR_CANCEL, STR_OK, STR_Name, STR_RP, STR_Wealth, STR_Debt, STR_LIMITDB } from '../lang/en';
import { INCOME, EXPENSE, TRANSFER, addKonto, konto } from '../functions/Log';
import LogCard from '../components/LogCard';
import { add, cutOutline, pencilOutline, remove, swapHorizontal } from 'ionicons/icons';
import LogModal from '../components/LogModal';
import { konten, getShop, LIST_KONTO, calcWealth, getCurrentUserShopKonto } from '../functions/DataFunctions';
import { updateList } from '../components/List';
import { EURO, formatCurrency, RUPIAH } from '../functions/DataModel';
import ListModal from '../components/ListModal';
import { truncateLogs } from '../functions/LocalStorage';

export var refresh = (kontoname: string, isdelete: boolean) => { }

const ADD = 1;
const TRUNCATE = 2;

function closeK(kontoto: string, kontofrom: string) {
  let array = document.getElementById('toCloseAll')?.children;
  if (array) {
    for (var i = 0; i < array.length; i++) {
      let e = array[i] as HTMLIonItemElement;
      let name = e.firstElementChild?.nextElementSibling?.textContent;
      if (name) {
        let isKonto = e.firstElementChild?.nextElementSibling?.textContent?.toLowerCase().includes(kontoto.toLowerCase());
        isKonto = isKonto || e.firstElementChild?.nextElementSibling?.textContent?.toLowerCase().includes(kontofrom.toLowerCase());
        let tohide = e.nextElementSibling as HTMLElement;
        if (isKonto && tohide && !tohide.hidden) {
          e.click();
        }
      }
    }
  }
}

const Tab4: React.FC = () => {

  const [show, setShow] = useState(-1);
  const [showYNAlert, setShowYNAlert] = useState(0);
  const [reload, setreload] = useState(false);

  const relModal = useRef(null);

  refresh = (kontoname: string, isdelete: boolean) => {
    let array = document.getElementById('toCloseAll')?.children;
    if (array) {
      for (var i = 0; i < array.length; i++) {
        let e = array[i] as HTMLIonItemElement;
        let name = e.firstElementChild?.nextElementSibling?.textContent;
        if (name) {
          let isKonto = name?.toLowerCase().includes(kontoname.toLowerCase());
          let tohide = e.nextElementSibling as HTMLElement;
          if (isKonto && tohide && !tohide.hidden) {
            if (isdelete) {
              e.click();
              e.click();
            }
            else updateList();
          }
          else if (tohide && !tohide.hidden) e.click();
        }
      }
    }
    setreload(!reload);
  }

  function fillShop() {
    return (
      <>
        {getCurrentUserShopKonto().map((s, i) => getShop(s) ? <LogCard key={i} konto={getShop(s)!} /> : '')}
      </>
    );
  }

  function closeAll() {
    let array = document.getElementById('toCloseAll')?.children;
    if (array) {
      for (var i = 0; i < array.length; i++) {
        let e = array[i];
        if (e.nextElementSibling && !(e.nextElementSibling as HTMLElement).hidden) (e as HTMLIonItemElement).click();
      }
    }
  }

  const compareName = (a: konto, b: konto) => {
    if (a.calculate && !b.calculate) return -1;
    else if (!a.calculate && b.calculate) return 1;
    else if (a.name < b.name) return -1;
    else return 1;
  }


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{STR_Balance}</IonTitle>
          <IonButton slot="end" onClick={() => setShowYNAlert(TRUNCATE)}><IonIcon icon={cutOutline} /></IonButton>
          <IonButton slot="end" onClick={() => setShow(3)}><IonIcon icon={pencilOutline} /></IonButton>
          <IonButton slot="end" onClick={() => setShowYNAlert(ADD)}><IonIcon icon={add} /></IonButton>
        </IonToolbar>
      </IonHeader>
      <IonHeader className={calcWealth() < 0 ? "DangerBar padding10" : "AvailableBar padding10"}>
        <IonTitle>
          <IonLabel className="fleft">{calcWealth() < 0 ? STR_Debt : STR_Wealth}</IonLabel>
          <IonLabel className="fright">{formatCurrency(RUPIAH, calcWealth())}</IonLabel>
        </IonTitle>
      </IonHeader>
      <IonContent>
        <IonList id='toCloseAll'>
          {fillShop()}
          {konten.sort(compareName).map((k, i) => k.hidden ? '' : <LogCard key={getCurrentUserShopKonto().length - 1 + i} konto={k} />)}
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar className="center">
          <IonButton onClick={() => setShow(INCOME)}><IonIcon icon={add} /></IonButton>
          <IonButton onClick={() => setShow(EXPENSE)}><IonIcon icon={remove} /></IonButton>
          <IonButton onClick={() => setShow(TRANSFER)}><IonIcon icon={swapHorizontal} /></IonButton>
        </IonToolbar>
        <IonModal ref={relModal} isOpen={show !== -1} swipeToClose={true} backdropDismiss={true} /*onWillPresent={() => closeAll()}*/ onDidDismiss={() => setShow(-1)}>
          {show === 3 ?
            <ListModal type={LIST_KONTO} shop='' />
            :
            <LogModal trxtype={show} txf={null} copy={false} rel={relModal} />
          }
        </IonModal>
        <IonAlert isOpen={showYNAlert > 0} onDidDismiss={() => setShowYNAlert(0)} header={showYNAlert === ADD ? STR_Add + ' ' + STR_Account : STR_LIMITDB}
          inputs={showYNAlert === ADD ? [
            {
              name: 'name',
              placeholder: STR_Name
            },
            {
              name: 'cur',
              placeholder: STR_RP + ' / EUR'
            }
          ] : []}
          buttons={[
            {
              text: STR_CANCEL,
              role: 'cancel',
              cssClass: 'secondary',
            }, {
              text: STR_OK,
              handler: (data) => {
                switch (showYNAlert) {
                  case ADD: {
                    addKonto(data.name, data.cur.toLowerCase() === 'eur' ? EURO : RUPIAH);
                    break;
                  }
                  case TRUNCATE: {
                    truncateLogs();
                    break;
                  }
                  default: break;
                }
              }
            }
          ]}></IonAlert>
      </IonFooter>
    </IonPage>
  );
};

export default Tab4;
