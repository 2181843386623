import { BIAS, BUKALAPAK, DEFAULTSHOP, initKonten, LAZADA, MEKUYAID, Sale, Shop, SHOPEE, TOKOPEDIA } from "./DataModel";
import { getShop, konten, isDropShip, isMGProvided, getNumberID, LIST_LOG, salesHistory, selfPayCourier, isPurchase, biassalesHistory, printSale, getPersonID, getUser, setAllPPrice, getCurrentUserDefaultKonto } from "./DataFunctions";
import { STR_DP, STR_Expense, STR_From, STR_MyShop, STR_Paid, STR_Payment, STR_Purchase, STR_Sale, STR_To } from "../lang/en";
import { updateAccount, removeFromDB, addToDBKonto, updateUser } from "./LocalStorage";
import { cardOutline } from "ionicons/icons";
import { refresh } from "../pages/Tab4";
import { user } from "../components/AppContextProvider";

export const BG = 0;
export const XD = 1;
export const DE = 3;

export var profitBG = getUser(user.staff)?.profitMG! > 0 ? getUser(user.staff)?.profitMG! : 0.2;
export var koinsbuy = 0.0013;
export var koinssell = 0.0023;

const kontoSign = 'K_';
export const ModalKonto = "K_Abeng";
export const MGProvider = createKontoID('abeng');
export const MainKonto = createKontoID('bca');
export const RDNKonto = createKontoID('koins');
export const ExpenseKonto = createKontoID(STR_Expense);
export const BiasMainKonto = createKontoID('PT Media Garuda Kontraktor');
export const RUNKonto = createKontoID('SeaBank');

export const EXPENSE = 0;
export const INCOME = 1;
export const TRANSFER = 2;

const saleSign = '[' + STR_Sale + '] ';
const transferSign = "[T] ";

export function setProfit(p: number) {
    let same = profitBG === p;
    profitBG = p;
    let u = getUser(user.staff);
    if (u) {
        u.profitMG = p;
        updateUser(u);
    }
    if (!same) setAllPPrice();
}

export function createKontoID(id: string) {
    return kontoSign + id;
}

export function isKonto(k: konto | Shop) {
    return (k._id.startsWith(kontoSign));
}

export interface konto {
    _id: string;
    currency: number;
    name: string;
    icon: string;
    balance: number;
    trx: trx[];
    //in: trx[];
    //out: trx[];
    transfer: transfer[];
    calculate: boolean;
    hidden: boolean
}

export interface trx {
    date: string;
    amount: number;
    account: string;
    note: string;
    type: number
}

export interface transfer {
    date: string;
    from: string;
    to: string;
    amount: number;
    fee: number;
    note: string;
}

export function setKontoCalc(konto: konto, bool: boolean) {
    konto.calculate = bool;
    updateAccount(konto);
}

export function setKontoHidden(konto: konto, bool: boolean) {
    konto.hidden = bool;
    updateAccount(konto);
}

export function addKonto(name: string, currency: number) {
    let neu = {
        _id: createKontoID(name),
        currency: currency,
        name: name,
        icon: cardOutline,
        balance: 0,
        trx: [],
        in: [],
        out: [],
        transfer: [],
        calculate: true,
        hidden: false
    }
    konten.push(neu);
    addToDBKonto(neu);
}

export function deleteKonto(id: string) {
    for (var i = 0; i < konten.length; i++) {
        if (konten[i]._id === id) {
            konten.splice(i, 1);
            removeFromDB(id, LIST_LOG);
            break;
        }
    }
}

export function isdeletableKonto(id: string) {
    if (id === getCurrentUserDefaultKonto().from || id === getCurrentUserDefaultKonto().to) return false;
    if (!id.startsWith(kontoSign)) return false;
    const req = initKonten();
    for (var i = 0; i < req.length; i++) {
        if (req[i]._id === id) {
            return false;
        }
    }
    return true;
}

export function updateShops() {
    updateAccount(getKonto(MGProvider)!);
    updateAccount(getShop(SHOPEE)!);
    updateAccount(getKonto(TOKOPEDIA)!);
    updateAccount(getShop(BUKALAPAK)!);
    updateAccount(getShop(LAZADA)!);
}

export function getKonto(key: string) {
    let shop = getShop(key);
    if (shop) { return shop }
    else {
        for (var i = 0; i < konten.length; i++) {
            if (konten[i]._id === key) return konten[i];
        }
        return null;
    }
}

export function getExpense(m: number, yyyy: number) {
    let exp = [];
    let total = 0;
    let expense = getKonto(ExpenseKonto);
    if (expense) {
        for (var i = 0; i < expense.trx.length; i++) {
            let trx = expense.trx[i];
            let date = new Date(trx.date);
            if (date.getFullYear() === yyyy && date.getMonth() === m) {
                exp.push(trx);
                if (trx.type === EXPENSE) total += +trx.amount;
                else total -= trx.amount;
            }
        }
    }
    return { total: total, expense: exp };
}


function addSorted(array: any[], entry: any) {
    for (var i = 0; i < array.length; i++) {
        if (new Date(entry.date).getTime() >= new Date(array[i].date).getTime()) {
            array.splice(i, 0, entry);
            return;
        }
    }
    array.push(entry);
}

export function addBCADP(sale: Sale) {
    let konto = getKonto(sale.shop === BIAS ? BiasMainKonto : MainKonto);
    if (konto) {
        let dp = {
            date: new Date().toISOString(),
            amount: sale.dp,
            account: konto._id,
            note: STR_DP + ' ' + printSale(sale),
            type: INCOME
        }
        addTxf(dp, true);
        alert(STR_DP + ' ' + STR_Paid + ' ' + STR_To + ' ' + 'BCA');
    }
}

export function removeBiasBalance(sale: Sale) {
    let konto1 = getKonto(ModalKonto);
    let konto2 = getKonto(BiasMainKonto);
    if (konto1 && konto2) {
        const dp = find(konto2.transfer, sale.date, saleNoteForTxf(getNumberID(sale._id)));
        if (dp) deleteTransfer(dp as transfer, true);
    }
}

export function addBCA(sale: Sale) {
    if (isPurchase(sale)) {
        let konto = getKonto(sale.shop === BIAS ? BiasMainKonto : MainKonto);
        if (konto) {
            let dp = {
                date: new Date().toISOString(),
                amount: sale.total - sale.dp + +sale.shipping,
                account: konto._id,
                note: saleNoteForTxf(getNumberID(sale._id)),
                type: EXPENSE
            }
            addTxf(dp, true);
            alert(STR_Purchase + ' ' + STR_Paid + ' ' + STR_From + ' ' + 'BCA');
        }
    }
    else if (sale.shop === BIAS && sale.client._id === MEKUYAID) {
        let konto1 = getKonto(ModalKonto);
        let konto2 = getKonto(BiasMainKonto);
        if (konto1 && konto2) {
            let dp = {
                date: sale.date,
                from: BiasMainKonto,
                to: ModalKonto,
                amount: sale.total - sale.dp + +sale.shipping,
                fee: 0,
                note: saleNoteForTxf(getNumberID(sale._id)),
            }
            addTxf(dp, true);
            /*let dp = {
                date: new Date().toISOString(),
                amount: sale.total - sale.dp + sale.shipping,
                account: konto1._id,
                note: saleNoteForTxf(getNumberID(sale._id)),
                type: EXPENSE
            }
            addTxf(dp, true);
            let mdp = {
                date: new Date().toISOString(),
                amount: sale.total - sale.dp + sale.shipping,
                account: konto2._id,
                note: saleNoteForTxf(getNumberID(sale._id)),
                type: EXPENSE
            }
            addTxf(mdp, true);*/
            alert(STR_Sale + ' ' + STR_Paid + ' ' + STR_To + ' ' + 'Bias BCA');
        }
    }
    else {
        let konto = getKonto(sale.shop === BIAS ? BiasMainKonto : MainKonto);
        if (konto) {
            let dp = {
                date: new Date().toISOString(),
                amount: sale.total - sale.dp + +sale.shipping,
                account: konto._id,
                note: saleNoteForTxf(getNumberID(sale._id)),
                type: INCOME
            }
            addTxf(dp, true);
            alert(STR_Sale + ' ' + STR_Paid + ' ' + STR_To + ' ' + 'BCA');
        }
    }
}

export function addExpense(name: string, amount: number) {
    let konto = getKonto(ExpenseKonto);
    if (konto) {
        let expense = {
            date: new Date().toISOString(),
            amount: amount,
            account: konto._id,
            note: name,
            type: EXPENSE
        }
        addTxf(expense, true);
    }
}

function plus(income: trx, save: boolean) {
    const k = getKonto(income.account);
    if (income.amount > 0 && k) {
        addSorted(k.trx, income);
        //addSorted(k.in, income);
        k.balance = k.balance + +income.amount;
        refresh(k.name, false);
        if (save) updateAccount(k);
    }
}

function minus(expense: trx, save: boolean) {
    const k = getKonto(expense.account);
    if (expense.amount > 0 && k) {
        addSorted(k.trx, expense);
        //addSorted(k.out, expense);
        k.balance = k.balance - expense.amount;
        refresh(k.name, false);
        if (save) updateAccount(k);
    }
}

function transfer(transfer: transfer, save: boolean) {
    const income = {
        date: transfer.date,
        amount: transfer.amount,
        account: transfer.to,
        note: transferSign + transfer.note,
        type: INCOME
    };

    const expense = {
        date: transfer.date,
        amount: transfer.amount + +transfer.fee,
        account: transfer.from,
        note: transferSign + transfer.note,
        type: EXPENSE
    }

    const to = getKonto(transfer.to);
    const from = getKonto(transfer.from);
    if (to && from) {
        addSorted(to.transfer, transfer);
        addSorted(from.transfer, transfer);
        plus(income, save);
        minus(expense, save);
    }
}

export function isTransfer(trx: trx) {
    return trx.note.startsWith(transferSign);
}

export function isSaleTransfer(trx: trx) {
    return trx.note.startsWith(transferSign + saleSign);
}

export function isSale(trx: trx) {
    return trx.note.includes(saleSign);
}

export function getTrfFromTrx(trx: trx) {
    const k = getKonto(trx.account);
    if (k) {
        return find(k.transfer, trx.date, trx.note.substring(transferSign.length));
    }
}

export function getTrxFromTrf(trf: transfer, type: number) {
    const f = getKonto(trf.from);
    const t = getKonto(trf.to);
    if (f && t) {
        if (type === INCOME) return find(t.trx, trf.date, transferSign + trf.note);
        else return find(f.trx, trf.date, transferSign + trf.note);
        /*if (type === INCOME) return find(t.in, trf.date, transferSign + trf.note);
        else return find(f.out, trf.date, transferSign + trf.note);*/
    }
}

function deleteTransfer(transfer: transfer, save: boolean) {
    const d = transfer.date;
    const n = transfer.note;
    const a = transfer.amount;
    const to = getKonto(transfer.to);
    const from = getKonto(transfer.from);
    if (to && from) {
        remove(to.transfer, d, n, a);
        remove(from.transfer, d, n, a);
        remove(to.trx, d, transferSign + n, a);
        //remove(to.in, d, transferSign + n);
        to.balance = to.balance - transfer.amount;
        remove(from.trx, d, transferSign + n, a);
        //remove(from.out, d, transferSign + n);
        from.balance = from.balance + +transfer.amount + +transfer.fee;
        refresh(to.name, true);
        refresh(from.name, true);
        if (save) {
            updateAccount(to);
            updateAccount(from);
        }
    }
}

function deleteTrx(trx: trx, save: boolean) {
    const d = trx.date;
    const n = trx.note;
    const a = trx.amount;
    const k = getKonto(trx.account);
    if (k) {
        remove(k.trx, d, n, a);
        if (trx.type === INCOME) {
            //remove(k.in, d, n);
            k.balance = k.balance - trx.amount;
        }
        else {
            //remove(k.out, d, n);
            k.balance = k.balance + +trx.amount;
        }
        refresh(k.name, true);
        if (save) updateAccount(k);
    }
}

export function addTxf(trx: trx | transfer, save: boolean) {
    if ("to" in trx) {
        transfer(trx, save);
    }
    else trx.type === INCOME ? plus(trx, save) : minus(trx, save);
}

export function deleteTxf(trx: trx | transfer, save: boolean) {
    try {
        if ("to" in trx) {
            deleteTransfer(trx, save);
        }
        else deleteTrx(trx, save);
    } catch (error) {
        alert('delete old trx failed: ' + error);
    }
}

export function removeExpenseLog(sale: Sale) {
    const k = getKonto(ExpenseKonto);
    if (k) {
        const expense = findExpense(/*k.out*/ k.trx, sale);
        if (expense) deleteTrx(expense as trx, true);
    }
}

export function removeSaleLog(sale: Sale, save: boolean) {
    const k = getKonto(sale.shop);
    const m = getKonto(MGProvider);
    if (k && m) {
        const income = find(/*k.in*/ k.trx, sale.date, saleNote(sale));
        if (income) deleteTrx(income as trx, save);

        const expense = find(/*m.out*/ m.trx, sale.date, saleNote(sale));
        if (expense) deleteTrx(expense as trx, save);
    }
}

export function updateSalesLog(sales: Sale[]) {
    try {
        var shops: string[] = [];
        for (var i = 0; i < sales.length; i++) {
            if (!shops.includes(sales[i].shop)) shops.push(sales[i].shop);
            updateSaleLog(sales[i], false);
        }
        updateAccount(getKonto(MGProvider)!);
        for (var j = 0; j < shops.length; j++) {
            updateAccount(getShop(shops[j])!);
        }
        alert(sales.length + ' logs updated');
    } catch (error) {
        alert(error);
    }
}

export function updateSaleLog(sale: Sale, save: boolean) {
    const k = getKonto(sale.shop);
    const m = getKonto(MGProvider);
    if (k && m) {
        const oldI = find(/*k.in*/ k.trx, sale.date, saleNote(sale));
        const income = createIncome(sale);
        if (selfPayCourier(sale.shipment)) income.amount = income.amount + +sale.shipping;
        updateTxf(oldI, income, save);

        const c = isMGProvided(sale);
        if (c.bool) {
            const oldE = find(/*m.out*/ m.trx, sale.date, saleNote(sale));
            const expense = createExpense(sale, c);
            if (isDropShip(sale) && selfPayCourier(sale.shipment)) expense.amount = expense.amount + +sale.shipping;
            updateTxf(oldE, expense, save);
        }
    }
}

export function transferPay(sale: Sale) {
    const c = isMGProvided(sale);
    if (c.bool) {
        const trf = createTransferPay(sale, c);
        if (isDropShip(sale) && selfPayCourier(sale.shipment)) trf.amount = trf.amount + +sale.shipping;
        addTxf(trf, true);
    }
}

export function createIncome(sale: Sale) {
    return ({
        date: sale.date,
        amount: sale.total - sale.discount - sale.fee,
        account: sale.shop,
        note: saleNote(sale) + ' ' + getPersonID(sale.client),
        type: INCOME
    });
}

export function createExpense(sale: Sale, c: { bool?: boolean; total: any; profit: any; }) {
    return ({
        date: sale.date,
        amount: c.total - c.profit,
        account: MGProvider,
        note: saleNote(sale) + ' ' + getPersonID(sale.client),
        type: EXPENSE
    });
}

export function payAll(total: number, date: Date) {
    let trf = {
        date: date.toISOString(),
        from: MainKonto,
        to: MGProvider,
        amount: total,
        fee: 0,
        note: STR_MyShop + ' ' + STR_Payment + ' ' + date.toISOString()
    }
    addTxf(trf, true);
}

function createTransferPay(sale: Sale, c: { bool?: boolean; total: any; profit: any; }) {
    return ({
        date: sale.paid as string,
        from: MainKonto,
        to: MGProvider,
        amount: c.total - c.profit,
        fee: 0,
        note: saleNote(sale)
    });
}

export function createTransfer(sale: Sale, c: { bool?: boolean; total: any; profit: any; }) {
    return ({
        date: sale.date,
        from: MainKonto,
        to: MGProvider,
        amount: c.total - c.profit,
        fee: 0,
        note: saleNote(sale)
    });
}

export function removeTransfer(sale: Sale) {
    const n = saleNote(sale);
    const k = getKonto(MGProvider);
    if (k) {
        const trf = find(k.transfer, sale.date, n);
        if (trf) deleteTransfer(trf as transfer, true);
    }
}

function saleNote(sale: Sale) {
    //return saleSign + getNumberID(sale._id);
    return saleSign + sale._id;
}

export function saleNoteForTxf(id: string) {
    return saleSign + id;
}

export function saleNoteNumberID(note: string) {
    if (note.includes(saleSign)) {
        let n = note.substring(note.indexOf(saleSign), note.length);
        n = n.substring(STR_Sale.length + 3);
        let username = n.indexOf(' ') > 0 ? n.substring(n.indexOf(' '), n.length) : '';
        if (note.startsWith(transferSign)) return transferSign + saleSign + getNumberID(n) + username;
        else return saleSign + getNumberID(n) + username;
    }
    else return note;
}

export function getSale(txf: trx) {
    let note = txf.note;
    if (note.includes(saleSign)) {
        let n = note.substring(note.indexOf(saleSign), note.length);
        n = n.substring(STR_Sale.length + 3);
        let numID = getNumberID(n);
        for (var j = 0; j < biassalesHistory.length; j++) {
            if (getNumberID(biassalesHistory[j]._id) === numID) {
                return biassalesHistory[j];
            }
        }
        for (var i = 0; i < salesHistory.length; i++) {
            if (getNumberID(salesHistory[i]._id) === numID) {
                return salesHistory[i];
            }
        }
    }
}

export function existTxf(txf: trx | transfer) {
    let array: trx[] | transfer[] = [];
    if ("to" in txf) {
        let t = getKonto(txf.to);
        if (t) array = t.transfer;
    }
    else {
        let k = getKonto(txf.account);
        if (k) array = k.trx;
    }
    const date = txf.date;
    const note = txf.note;
    return find(array, date, note) ? true : false;
}

function find(array: transfer[] | trx[], date: string, note: string) {
    for (var i = 0; i < array.length; i++) {
        if ((array[i].date === date && array[i].note.includes(note)) || (array[i].date === date && array[i].note.includes(saleNoteNumberID(note)))) {
            return array[i];
        }
    }
}

function findExpense(array: transfer[] | trx[], sale: Sale) {
    let res = isPurchase(sale) ? STR_Purchase : STR_Sale;
    res += ' ' + getNumberID(sale._id);
    res += sale.shop === DEFAULTSHOP[0] ? '' : ' from ' + sale.shop;
    res += sale.client && getPersonID(sale.client) !== '' ? ' ' + getPersonID(sale.client) : '';
    for (var i = 0; i < array.length; i++) {
        if (array[i].note.startsWith(res)) {
            return array[i];
        }
    }
}

function remove(array: trx[] | transfer[], date: string, note: string, amount: number) {
    for (var i = 0; i < array.length; i++) {
        if (array[i].date === date && array[i].note === note && array[i].amount === amount) {
            array.splice(i, 1);
            break;
        }
    }
}

export function getInvested() {
    let inv = 0;
    const k = getKonto(RDNKonto);
    if (k) {
        k.transfer.forEach(trf => {
            if (trf.to === RDNKonto) {
                inv = inv + +trf.amount;
            }
            else {
                inv = inv - trf.amount;
            }
        });
    }
    return inv;
}

export function updateTxf(alt: trx | transfer | undefined, neu: trx | transfer, save: boolean) {
    if (alt !== neu) {
        if (alt) deleteTxf(alt, save);
        addTxf(neu, save);
    }
}