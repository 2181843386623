import React, { MouseEvent, useState, useRef, useEffect } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSearchbar, IonButton, IonIcon, IonGrid, IonRow, IonCol, IonChip, IonItem, IonLabel, IonSegment, IonSegmentButton, IonAvatar, IonTextarea, IonInput, IonList, IonFooter, IonAlert, IonButtons, IonMenuButton, IonSelect, IonSelectOption, IonCheckbox, useIonViewWillEnter, IonModal, AlertInput, isPlatform, IonThumbnail } from '@ionic/react';
import { barcodeOutline, cashOutline, cardOutline, person, personAdd, close, checkmark, cut, cartOutline, basket, cart, printOutline, add, trash, checkboxOutline, listOutline, trashBinOutline, hourglassOutline, airplaneOutline, documentLockOutline, addOutline, printSharp, pencil, refresh } from 'ionicons/icons'
import './Tab2.css';
import { ItemList } from '../components/ItemList';
import { SalesList } from '../components/SalesList';
import { Person, initPerson, ALL, formatCurrency, Item, DEFAULTSHOP, TOKOPEDIA, SHOPEE, BUKALAPAK, BIAS, BIASREK, MEKUYAREK, MEGAKON, MEGAKONREK, Sale, cloneSale, MEKUYAID, setKurirFromResi, SHOPEE_EXPORT, SalesItem, DROPSHIPTAG, courier } from '../functions/DataModel';
import { sale, saveClient, containsClient, resetSale, contacts, updateInventory, markDropShip, setPrice, inStockForSale, LIST_SALES, LIST_PURCHASES, saveSale, savePurchase, getSalesID, isDropShip, shops, getShop, getFilteredList, getFilteredContact, kurir, selfPayCourier, getCourier, addKurir, removeKurir, last, toggleCashlessKurir, toPrint, mprint, toggleMPrint, toggleMPSale, toSList, printSale, savePOPurchase, savePOSale, po, LIST_PO, getPOID, clearMPrint, clearSList, sortShopPO, getLastPO, getContact, isPurchase, isAdmin, setID, DOMode, setDOMode, getUserTrx, salesHistory, biasdata, LIST_BIASINVENTORY, LIST_INVENTORY, setInventoryID, addInventoryItem, existKey, getCategory, getNumberID, isBias, getPersonID, isNumeric, toggleMPSales, addSaveList, getCurrentUserSavedList, removeSavedList, renameSavedList, biassalesHistory, isPO, sortToShipReport, removeUserList, printmode, toggleprintMode, addShop, getCurrentUserShopKonto, deleteResiStart } from '../functions/DataFunctions';
import { data } from '../functions/DataFunctions';
import { STR_CancelSale, STR_SaveClient, STR_Paid, STR_Instalments, STR_Total, STR_Discount, STR_Initial, STR_Debt, STR_CANCEL, STR_OK, STR_UserName, STR_Name, STR_tel, STR_Address, STR_Info, STR_Notes, STR_Key, STR_SaveSale, STR_SaveUpdateSale, STR_Payment, STR_Change, STR_Sale, STR_Purchase, STR_Shipment, STR_Resi, STR_Cashless, STR_Last, STR_dsList, STR_clear, STR_SaveExpenseSale, STR_Labels, STR_Dropshipper, STR_All, STR_No, STR_Export, STR_Code, STR_Client, STR_SaleNote, STR_DP, STR_NotPaid, STR_EmptyResi, STR_LoginUser, STR_print, STR_DeliveryOrder, STR_DOMode, STR_BankPayment, STR_To, STR_SelectSale, STR_toPrint, STR_SaveList, STR_Rename, STR_Remove, STR_WarningDoNOTUpdateHere, STR_Cost, STR_COD, STR_SAFE, STR_ChangeInventory, STR_outPrint, STR_inPrint, STR_printed, STR_show, STR_ThermalPrint, STR_Shop, STR_ConfirmDelete, STR_DeleteResiStart, STR_RemoveKurir, STR_Note } from '../lang/en';
import { BarcodeScanner } from '@ionic-native/barcode-scanner';
import { generatePurchasePDF, generateReceiptItemList, generateReceiptLabel, generateShipmentLabel, printLabel, printMultipleLabels } from '../functions/Output';
import { saveKurir, setItemsImage } from '../functions/LocalStorage';
import { getSelectKurir, update } from './Tab3';
import { addBCA, addExpense } from '../functions/Log';
import ListModal from '../components/ListModal';
import { InputChangeEventDetail } from '@ionic/core';
import { user } from '../components/AppContextProvider';
import { users } from '../components/Login';
import SalesCard, { PAY, ULREMOVE } from '../components/SalesCard';
import { List, updateList } from '../components/List';

export var updateTransactionTab = () => { };

var toULRemove: Sale | null = null;

export function setToULRemove(sale: Sale | null) {
  toULRemove = sale;
}

function removeString(text: string, toremove: string) {
  let s = '';
  let e = '';
  let res = text;
  let idx = text.toLowerCase().lastIndexOf(toremove.toLowerCase());
  if (idx > -1) {
    s = text.substring(0, idx);
    e = text.substring(idx + toremove.length, text.length);
    res = s + e;
  }
  return res;
}

export function formatShopee(input: string) {
  let name = '';
  let tel = '';
  let adr = '';
  input = input.replace('(+62) ', '0');
  if (isPlatform("hybrid")) {
    let br = input.indexOf(' ') + 1;
    let temp = input.substring(br, input.length);
    if (temp.startsWith('*')) temp = '0' + temp.substring(1);
    while (br < temp.length && temp.includes(' ') && !isNumeric(temp.substring(0, temp.indexOf(' ')))/*!isNumeric(input.substring(br, br + 1 + temp.indexOf(' ')))*/) {
      br = br + temp.indexOf(' ') + 1;
      temp = temp.substring(temp.indexOf(' ') + 1, temp.length);
    }
    name = input.substring(0, br);
    tel = temp.substring(0, temp.indexOf(' '));
    adr = temp.substring(tel.length + 1, temp.length).trim();
  }
  else {
    let br = input.indexOf(', ');
    let temp = input.substring(br + 2, input.length);
    if (temp.startsWith('*')) temp = '0' + temp.substring(1);
    while (br < temp.length && temp.includes(', ') && !isNumeric(temp.substring(0, temp.indexOf(' ')))) {
      br = br + temp.indexOf(', ') + 2;
      temp = temp.substring(temp.indexOf(', ') + 2, temp.length);
    }
    name = input.substring(0, br);
    tel = temp.substring(0, temp.indexOf(' '));
    adr = input.substring(name.length + 2 + tel.length + 1, input.length).replace(/(\r\n|\n|\r)/gm, ", ").trim();
  }
  if (tel.startsWith('62')) tel = '0' + tel.substr(2, tel.length);
  adr = adr.replace(', ID, ', ' ');
  let propinsi = adr.substring(adr.lastIndexOf(', '), adr.length);
  let t = adr.substring(0, adr.length - propinsi.length);
  let kecamatan = t.substring(t.lastIndexOf(', '), t.length);
  t = t.substring(0, t.length - kecamatan.length);
  let kabupaten = t.substring(t.lastIndexOf(', '), t.length);
  t = t.substring(0, t.length - kabupaten.length);

  let note2 = '';
  if (t.includes('((') && t.includes('))')) {
    note2 = t.substring(t.indexOf('((') + 2, t.indexOf('))'));
    t = t.replace('((' + note2 + '))', '').replace('  ', ' ');
  }

  let note = '';
  if (t.includes('(')) {
    let c = t.indexOf(')');
    while (t.charAt(c + 1) === ')') {
      c = c + 1;
    }
    note = t.substring(t.indexOf('(') + 1, c);
    t = t.replace('(' + note + ')', '').replace('  ', ' ');
  }

  note = note2.length > 1 ? note.length > 1 ? note + ', ' + note2 : note2 : note;

  while (note.includes('  ')) {
    note = note.replace('  ', ' ').trim();
  }

  t = removeString(t, kecamatan.substring(1).replace(',', ''));
  t = kabupaten.includes('kab. ') ? removeString(t, removeString(kabupaten.substring(1), 'kab. ').replace(',', '')) : t;
  t = removeString(t, removeString(kabupaten.substring(1), 'kota ').replace(',', ''));
  t = removeString(t, propinsi.substring(1, propinsi.length - 6).replace(',', ''));
  t = removeString(t, propinsi.substring(propinsi.length - 5, propinsi.length).replace(',', ''));

  t = removeString(t, "kode pos");
  t = removeString(t, 'kota ,');
  t = removeString(t, 'kota,');
  t = removeString(t, 'kota .');
  t = removeString(t, 'kota.');
  t = removeString(t, 'kota ');
  t = removeString(t, 'kabupaten ,');
  t = removeString(t, 'kabupaten .');
  t = removeString(t, 'kabupaten,');
  t = removeString(t, 'kabupaten.');
  t = removeString(t, 'kabupaten ');
  t = removeString(t, 'kabupaten');
  t = removeString(t, 'kecamatan ,');
  t = removeString(t, 'kecamatan .');
  t = removeString(t, 'kecamatan,');
  t = removeString(t, 'kecamatan.');
  t = removeString(t, 'kecamatan ');
  t = removeString(t, 'kecamatan');
  t = removeString(t, 'provinsi ,');
  t = removeString(t, 'provinsi .');
  t = removeString(t, 'provinsi,');
  t = removeString(t, 'provinsi.');
  t = removeString(t, 'provinsi');
  t = removeString(t, 'propinsi ,');
  t = removeString(t, 'propinsi .');
  t = removeString(t, 'propinsi,');
  t = removeString(t, 'propinsi.');
  t = removeString(t, 'propinsi');
  t = removeString(t, 'kec. ,');
  t = removeString(t, 'kec. .');
  t = removeString(t, 'kec.');
  t = removeString(t, 'kec ,');
  t = removeString(t, 'kec,');
  t = removeString(t, 'kec .');
  t = removeString(t, 'kec ');
  t = removeString(t, 'kab. ,');
  t = removeString(t, 'kab. .');
  t = removeString(t, 'kab.');
  t = removeString(t, 'kab ,');
  t = removeString(t, 'kab,');
  t = removeString(t, 'kab .');
  t = removeString(t, 'kab ');
  t = t.replace(/,(?=[^\s])/g, ", ");

  t = t + kecamatan + kabupaten;
  adr = t + propinsi;

  if (t.toLocaleLowerCase().includes('jakarta')) {
    adr = adr.replace(', DKI JAKARTA', '');
    adr = adr.replace('Kota Administrasi', '');
  }
  while (adr.includes('  ')) {
    adr = adr.replace('  ', ' ').trim();
  }
  while (adr.includes(' ,')) {
    adr = adr.replace(' ,', ',').trim();
  }
  while (adr.includes(',,')) {
    adr = adr.replace(',,', ',');
  }
  while (adr.includes('.,')) {
    adr = adr.replace('.,', '.');
  }
  return { name: name, tel: tel, adr: adr, info: note };
}

const Tab2: React.FC = () => {

  const EXPORTCLIENT = "sipbuyerid";

  const [npList, setNPList] = useState<{ name: string, list: Sale[] }>({ name: '', list: [] });

  const [bca, setBCA] = useState(false);
  const [showDSName, setShowDSName] = useState(sale.dropshipper.startsWith(DROPSHIPTAG));

  const [sug, setSug] = useState<Item[]>([]);
  const [psug, setPsug] = useState<Person[]>([]);
  const searchEl = useRef(null);

  const kurirCB = useRef(null);

  const updateUL = useRef(null);

  const updateTotalEl = useRef(null);
  const clientSugEl = useRef(null);

  const inputKey = useRef(null);
  const inputName = useRef(null);
  const inputTel = useRef(null);
  const inputAdr = useRef(null);
  const inputInfo = useRef(null);
  const inputSaleInfo = useRef(null);
  const inputSaleNote = useRef(null);
  const inputDropship = useRef(null);
  const popover = useRef(null);

  const savedListName = useRef(null);

  const selectKurirRef = useRef(null);

  const toPrintButton = useRef(null);

  const [show, setShow] = useState('');
  const [showNP, setShowNP] = useState(false);

  const [showPopover, setShowPopover] = useState(-1);
  const [wShippingL, setWShippingL] = useState(true);
  const [wlabels, setWlabels] = useState(true);
  const [showYNAlert, setShowYNAlert] = useState('');
  const [personToAdd, setPersonToAdd] = useState(null as unknown as Person);

  const [mode, setMode] = useState(LIST_SALES);
  const [poMode, setPOMode] = useState(false);

  const [tprice, setTPrice] = useState({ total: 0, rabatt: false, discount: 0, topay: 0, dp: 0, debt: 0 });
  const [payment, setPayment] = useState('');
  const [shop, setShop] = useState({ shop: isBias() ? DEFAULTSHOP[1] : DEFAULTSHOP[0], reload: false });

  const [withdetails, setWithdetails] = useState(false);

  const [reload, setReload] = useState(true);


  updateTransactionTab = () => {
    setShop({ shop: sale.shop, reload: !shop.reload });
    setModeTrx(isPurchase(sale) ? LIST_PURCHASES.toString() : LIST_SALES.toString());
    updateNewTotal();
    update();
  }

  function searchItem(e: Event, text: string) {
    const popup = (e.currentTarget as HTMLElement).parentElement?.nextElementSibling as HTMLElement;
    if (text.length > 0) {
      let idata = (sale.shop === BIAS) ? biasdata : data;
      let filtered = getFilteredList(idata, ALL, text, 20);
      if (filtered.length > 0) popup.style.display = 'block';
      else popup.style.display = 'none';
      setItemsImage(filtered).then(() => setSug(filtered));
    }
    else {
      setSug([]);
      popup.style.display = 'none';
    }
  }

  function searchClient(e: Event, text: string) {
    const popup = (e.currentTarget as HTMLElement).parentElement?.nextElementSibling as HTMLElement;
    if (text.length === 0) {
      setPsug([]);
      popup.style.display = 'none';
    }
    else if (text !== SHOPEE_EXPORT) {
      ((e.target as HTMLElement).firstElementChild as HTMLElement).style.border = "none";
      if (text.length > 0) {
        let filtered = getFilteredContact(contacts, text, 0);
        if (filtered.length > 0) popup.style.display = 'block';
        else popup.style.display = 'none';
        setPsug(filtered);
      }
      else {
        setPsug([]);
        popup.style.display = 'none';
      }
    }
  }

  function setClient(p: Person) {
    //(clientSugEl.current as unknown as HTMLElement).style.display = "none";
    //const list = (e.currentTarget as HTMLElement).parentElement?.parentElement;
    const list = clientSugEl.current as unknown as HTMLElement;
    list.style.display = "none";
    const inputKey = list?.previousElementSibling?.firstElementChild as HTMLInputElement;
    const inputName = list?.parentElement?.lastElementChild?.firstElementChild as HTMLInputElement;
    const inputTel = inputName?.nextElementSibling as HTMLInputElement;
    const inputAdr = inputTel?.nextElementSibling as HTMLInputElement;
    const inputInfo = inputAdr?.nextElementSibling as HTMLInputElement;
    inputKey.value = getPersonID(p);
    inputName.value = p.name;
    inputTel.value = p.telp;
    inputAdr.value = p.address;
    inputInfo.value = p.info;
    sale.client._id = p._id;
    if (sale.shop === BIAS && p._id === MEKUYAID) setBCA(true);
    else setBCA(false);
  }

  function setClientInfo(info: string) {
    let sip = getContact(EXPORTCLIENT);
    if (sip) {
      sale.shop = SHOPEE;
      setShop({ shop: sale.shop, reload: !shop.reload });
      (inputKey.current! as HTMLInputElement).value = sip._id;
      (inputName.current! as HTMLInputElement).value = sip.name;
      (inputTel.current! as HTMLInputElement).value = sip.telp;
      (inputAdr.current! as HTMLInputElement).value = sip.address;
      (inputInfo.current! as HTMLInputElement).value = info;
      sale.client._id = sip._id;
    }
  }

  function addClient(e: MouseEvent) {
    let p = initPerson();
    const inputKey = (e.currentTarget as HTMLElement).parentElement?.firstElementChild as HTMLInputElement;
    const inputName = (e.currentTarget as HTMLElement).parentElement?.parentElement?.lastElementChild?.firstElementChild as HTMLInputElement;
    const inputTel = inputName?.nextElementSibling as HTMLInputElement;
    const inputAdr = inputTel?.nextElementSibling as HTMLInputElement;
    const inputInfo = inputAdr?.nextElementSibling as HTMLInputElement;
    let key = inputKey.value;
    if (key !== '' && !containsClient(key)) {
      p._id = key;
      p.name = inputName.value;
      p.telp = inputTel.value;
      p.address = inputAdr.value;
      p.info = inputInfo.value;
      p.staff = user.staff;
      setPersonToAdd(p);
      setShowYNAlert(STR_SaveClient);
    }
    else {
      (inputKey.firstElementChild as HTMLElement).style.border = "1px solid red";
    }
  }

  const openScanner = async () => {
    const data = await BarcodeScanner.scan();
    (searchEl.current as unknown as HTMLInputElement).value = data.text;
  };

  function setPercentageRabatt(e: Event, percent: string) {
    let p = Number.parseInt(percent);
    if (percent === '') {
      return;
    }
    else if (p < 0 || p > 100) {
      (e.currentTarget as HTMLInputElement).value = '0';
    }
    else if (percent.length > 1 && percent.startsWith('0')) {
      (e.currentTarget as HTMLInputElement).value = percent.substring(1, percent.length);
    }
    else {
      p = p / 100 * tprice.total;
      setnRabatt(p);
    }
  }

  function setPercentageDP(e: Event, percent: string) {
    let p = Number.parseInt(percent);
    if (percent === '') {
      return;
    }
    else if (p < 0 || p > 100) {
      (e.currentTarget as HTMLInputElement).value = '0';
    }
    else if (percent.length > 1 && percent.startsWith('0')) {
      (e.currentTarget as HTMLInputElement).value = percent.substring(1, percent.length);
    }
    else {
      p = p / 100 * tprice.topay;
      setnDP(p);
    }
  }

  function setAmountRabatt(e: Event, p: number) {
    let input = e.currentTarget as HTMLInputElement;
    if (input.value === '' || p < 0 || p > tprice.total) {
      input.value = '0';
    }
    else if (input.value.length > 1 && input.value.startsWith('0')) {
      input.value = input.value.substring(1, input.value.length);
    }
    else {
      setnRabatt(p);
    }
  }

  function setnRabatt(p: number) {
    setTPrice({ total: tprice.total, rabatt: tprice.rabatt, discount: p, topay: tprice.total - p, dp: tprice.dp, debt: tprice.total - p - tprice.dp });
  }

  function setAmountDP(e: Event, p: number) {
    let input = e.currentTarget as HTMLInputElement;
    if (input.value === '' || p < 0 /*|| p > total*/) {
      input.value = '0';
    }
    else if (input.value.length > 1 && input.value.startsWith('0')) {
      input.value = input.value.substring(1, input.value.length);
    }
    else {
      setnDP(p);
    }
  }

  function setnDP(p: number) {
    sale.dp = p;
    if (sale.dp > 0) sale.status = STR_DP;
    else sale.status = STR_NotPaid;
    setTPrice({ total: tprice.total, rabatt: tprice.rabatt, discount: tprice.discount, topay: tprice.topay, dp: p, debt: tprice.topay - p });
  }

  function setCut() {
    setTPrice({
      total: tprice.total, rabatt: !tprice.rabatt, discount: tprice.discount, topay: tprice.rabatt ? tprice.total : tprice.total - tprice.discount, dp: tprice.dp,
      debt: tprice.rabatt ? tprice.total - tprice.dp : tprice.total - tprice.discount - tprice.dp
    });
  }

  function clear(e: Event) {
    (e.currentTarget as HTMLInputElement).value = '';
  }

  function clearInput(e: Event) {
    let input = e.currentTarget as HTMLInputElement;
    if (input.value === '') input.value = '0';
  }

  function fillShop(shopID: string, idx: number) {
    if (isBias()) return;
    if (!isAdmin() && (shopID === BIAS || !getCurrentUserShopKonto().includes(shopID)) && shopID !== DEFAULTSHOP[0]) return;
    if (mode === LIST_PURCHASES && shopID === BIAS) return;
    const imgpath = "assets/img/" + shopID + ".png";
    return (
      <IonChip key={idx} onClick={e => {
        e.stopPropagation();
        if (shopID === sale.shop) return;
        if (shopID === BIAS || sale.shop === BIAS) setShowYNAlert(STR_ChangeInventory + ' ' + STR_To + ' ' + shopID);
        else {
          sale.shop = shopID;
          setShop({ shop: shopID, reload: shop.reload });
        }
      }}>
        <IonAvatar className="marginR8"><img alt={shopID.toUpperCase()} src={imgpath}></img></IonAvatar>
      </IonChip>
    );
  }

  function updateNewTotal() {
    if (tprice.rabatt) setTPrice({ total: sale.total, rabatt: tprice.rabatt, discount: tprice.discount, topay: sale.total - tprice.discount, dp: tprice.dp, debt: sale.total - tprice.discount - tprice.dp });
    else setTPrice({ total: sale.total, rabatt: tprice.rabatt, discount: tprice.discount, topay: sale.total, dp: tprice.dp, debt: sale.total - tprice.dp });
  }

  function addPO(mode: number) {
    if (sale.items.length > 0) {
      sale.date = (new Date()).toISOString();
      let mark = isDropShip(sale);
      sale._id = setID(sale, getPOID());
      if (mark) markDropShip(sale);
      sale.total = tprice.topay;
      let olddiscount = sale.discount;
      sale.discount = tprice.discount;
      if (mode === LIST_PURCHASES) {
        savePOPurchase(sale);
      }
      else {
        let s = getShop(shop.shop);
        if (s) {
          let oldfee = sale.fee;
          sale.fee = (s.fee * sale.total);
          sale.profit = (sale.profit + oldfee + olddiscount) - sale.fee - sale.discount;
        }
        savePOSale(sale);
      }
      setShowPopover(0);
    }
  }

  function addPurchaseFromBias(biassale: Sale) {
    let sale = cloneSale(biassale);
    if (sale.items.length > 0) {
      sale.print = true;
      sale.paid = null;

      for (var i = 0; i < sale.items.length; i++) {
        let item = sale.items[i].item;
        let biascat = getCategory(BIAS);
        if (!existKey(item._id, LIST_INVENTORY)) {
          let biasitem = {
            _id: item._id,
            ProductName: item.ProductName,
            Quantity: 0,
            PurchasePrice: item.MSRPPrice - (0.2 * item.MSRPPrice), //item.SalePrice,
            SalePrice: item.MSRPPrice,
            Reserve: 0,
            AdditionalSalesPrices: '',
            Info: item.Info,
            Category: biascat._id,
            Image: item.Image,
            MSRPPrice: item.MSRPPrice,
            description: item.description,
            dimensionP: item.dimensionP,
            dimensionL: item.dimensionL,
            dimensionT: item.dimensionT,
            weight: item.weight
          };
          addInventoryItem(biasitem);
        }
      }

      updateInventory(LIST_PURCHASES, sale, LIST_INVENTORY);
      savePurchase(sale, true);
    }
  }

  function addSale(mode: number, inventoryID: number) {
    if (sale.items.length > 0) {
      sale.date = (new Date()).toISOString();
      let mark = isDropShip(sale);
      sale._id = setID(sale, getSalesID());
      if (mark) markDropShip(sale);
      sale.total = tprice.topay;
      sale.discount = tprice.discount;
      if (mode === LIST_PURCHASES) {
        savePurchase(sale, true);
      }
      else {
        let s = getShop(shop.shop);
        if (s) {
          let oldfee = sale.fee;

          let total = sale.total - sale.dp;
          sale.fee = (s.fee * total);
          sale.profit = (sale.profit + oldfee) - sale.fee;
          sale.fee = sale.fee + +sale.dp;
        }
        saveSale(sale, true, inventoryID);
      }
      if (bca) {
        addBCA(sale);
        setBCA(false);
      }
      if (!isPlatform("hybrid") && printmode.state && !mprint && !isPO(sale!) && !isPurchase(sale)) {
        generateReceiptLabel(sale!, wShippingL);
        reset();
        user.setLoading(false);
      }
      else setShowPopover(0);
    }
  }

  function reset() {
    setPersonToAdd(null as unknown as Person);
    resetSale();
    setPayment('');
    setTPrice({ total: 0, rabatt: false, discount: 0, topay: 0, dp: 0, debt: 0 });
    setShop({ shop: isBias() ? DEFAULTSHOP[1] : DEFAULTSHOP[0], reload: shop.reload });
    setPsug([]);
    try {
      ((searchEl.current as unknown as HTMLInputElement).parentElement?.nextElementSibling as HTMLElement).style.display = "none";
      (searchEl.current as unknown as HTMLInputElement).value = '';
      (clientSugEl.current as unknown as HTMLElement).style.display = "none";
      (inputKey.current as unknown as HTMLInputElement).value = '';
      (inputName.current as unknown as HTMLInputElement).value = '';
      (inputTel.current as unknown as HTMLInputElement).value = '';
      (inputAdr.current as unknown as HTMLInputElement).value = '';
      (inputInfo.current as unknown as HTMLInputElement).value = '';
      (inputSaleInfo.current as unknown as HTMLInputElement).value = '';
      (inputSaleNote.current as unknown as HTMLInputElement).value = '';
      (inputDropship.current as unknown as HTMLInputElement).value = '';
    } catch (error) { }
  }


  function getMessage() {
    let msg = showYNAlert;
    if (tprice.dp > 0 && ((msg === STR_SaveSale) || (msg === STR_SaveUpdateSale))) {
      let s = tprice.debt > 0 ? STR_Debt : STR_Change;
      msg = msg + "<br><br>" + STR_Payment + ": " + formatCurrency(getShop(shop.shop)?.currency!, tprice.dp) + "<br>" + s + ": <b>" + formatCurrency(getShop(shop.shop)?.currency!, tprice.debt) + "</b>";
    }
    else if (mode === LIST_PURCHASES) {
      msg = msg.replace(STR_Sale, STR_Purchase);
    }
    else if (msg === STR_DeliveryOrder) msg = STR_DOMode;
    else if (msg === STR_dsList) msg = STR_clear + ' ' + msg + '?';
    else if (msg === STR_Info) msg = STR_Info + ' ' + STR_Export + ':';
    else if (msg === STR_LoginUser) msg = user.staff + ' ' + STR_print;
    else if (msg === STR_SaveList) msg = STR_Rename + '?';
    else if (msg === STR_print) msg = printmode.state ? STR_outPrint : STR_inPrint;
    return msg;
  }

  function setModeTrx(value: string) {
    const m = Number.parseInt(value);
    setPrice(m, sale);
    setMode(m);
  }

  function popPrint(e: React.MouseEvent<HTMLIonButtonElement, globalThis.MouseEvent>, withsummary: boolean) {
    e.stopPropagation();
    e.currentTarget.disabled = true;
    if (mprint && !isPO(sale)) {
      if (withsummary) toggleMPSale(sale, true, false).then(() => closePopUp());
      else toggleMPSale(sale, wShippingL, true).then(() => closePopUp());
    }
    else {
      printLabel(sale, wShippingL, true).then(() => closePopUp());
    }
  }

  function closePopUp() {
    (popover.current! as HTMLIonPopoverElement).dismiss();
    reset();
    //setShop({ shop: sale.shop, reload: !shop.reload });
  }

  useIonViewWillEnter(() => {
    setShop({ shop: sale.shop, reload: shop.reload });
    setInventoryID(sale.shop === BIAS ? LIST_BIASINVENTORY : LIST_INVENTORY);
    setModeTrx(isPurchase(sale) ? LIST_PURCHASES.toString() : LIST_SALES.toString());
    updateNewTotal();
  });

  function fillPOShop() {
    let sort = sortShopPO();
    return (
      sort.map(({ shop: s, count: n }, idx) => isAdmin() || (isBias() && s === BIAS) ? <IonButton key={idx} onClick={() => setShow(s)}>
        <img className="icon marginr" alt={s.toUpperCase()} src={"assets/img/" + s + ".png"}></img>
        <IonLabel className="marginr">{n}</IonLabel>
      </IonButton> : '')
    );
  }

  function setClientDescBukalapak(input: string) {
    let br = input.indexOf(' ') + 1;
    let temp = input.substring(br, input.length);
    while (br < temp.length && temp.includes(' ') && !isNumeric(input.substring(br, br + 1 + temp.indexOf(' ')))) {
      br = br + temp.indexOf(' ') + 1;
      temp = temp.substring(temp.indexOf(' ') + 1, temp.length);
    }
    let name = input.substring(0, br);
    let tel = temp.substring(0, temp.indexOf(' '));
    let adr = temp.substring(tel.length + 1, temp.length).trim();
    if (tel.startsWith('62')) tel = '0' + tel.substr(2, tel.length);
    let note = '';
    if (adr.includes('(')) {
      note = adr.substring(adr.indexOf('(') + 1, adr.indexOf(')'));
      adr = adr.replace('(' + note + ')', '').replace('  ', ', ');
    }
    if (adr.toLocaleLowerCase().includes('jakarta')) {
      adr = removeString(adr, ', DKI JAKARTA');
      adr = removeString(adr, 'Kota Administrasi');
    }
    adr = adr.trim();
    adr = adr.replace(/,(?=[^\s])/g, ", ");
    while (adr.includes('  ')) {
      adr = adr.replace('  ', ' ').trim();
    }
    while (adr.includes(' ,')) {
      adr = adr.replace(' ,', ',').trim();
    }
    while (adr.includes(',,')) {
      adr = adr.replace(',,', ',');
    }
    while (adr.includes('.,')) {
      adr = adr.replace('.,', '.');
    }
    (inputName.current as unknown as HTMLInputElement).value = name;
    (inputTel.current as unknown as HTMLInputElement).value = tel;
    (inputAdr.current as unknown as HTMLInputElement).value = adr;
    (inputInfo.current as unknown as HTMLInputElement).value = note;
  }

  function setClientDescTokopedia(input: string) {
    let name, tel, adr = '';
    if (isPlatform("hybrid")) {
      let br = input.indexOf(' ') + 1;
      let temp = input.substring(br, input.length);
      while (br < temp.length && temp.includes(' ') && !isNumeric(input.substring(br, br + 1 + temp.indexOf(' ')))) {
        br = br + temp.indexOf(' ') + 1;
        temp = temp.substring(temp.indexOf(' ') + 1, temp.length);
      }
      name = input.substring(0, br);
      tel = temp.substring(0, temp.indexOf(' '));
      adr = temp.substring(tel.length + 1, temp.length).trim();
    }
    else {
      let br = input.indexOf(' (');
      let temp = input.substring(br + 2, input.length);
      while (br < temp.length && temp.includes(' (') && !isNumeric(input.substring(br + 2, br + temp.indexOf(' (')))) {
        br = br + temp.indexOf(' (') + 2;
        temp = temp.substring(temp.indexOf(' (') + 2, temp.length);
      }
      name = input.substring(0, br);
      tel = temp.substring(0, temp.indexOf(')'));

      if (tel.startsWith('62')) tel = '0' + tel.substr(2, tel.length);
      adr = temp.substring(temp.indexOf(')') + 1, temp.length).replace(/(\r\n|\n|\r)/gm, ", ").trim();
    }
    let note = '';
    if (adr.includes('[Tokopedia Note: ')) {
      note = adr.substring(adr.indexOf('[Tokopedia Note: ') + 17, adr.indexOf(']'));
      adr = adr.replace('[Tokopedia Note: ' + note + ']', '').replace('  ', ', ');
    }

    let t = adr;

    t = removeString(t, ', DKI JAKARTA');
    t = removeString(t, 'Kota Administrasi');
    t = removeString(t, 'Daerah Khusus Ibukota Jakarta');
    t = removeString(t, 'Ibukota Jakarta');

    let propinsi = t.substring(t.lastIndexOf(', '), t.length);
    t = t.substring(0, t.length - propinsi.length);
    let kabupaten = t.substring(t.lastIndexOf(', '), t.length);
    t = t.substring(0, t.length - kabupaten.length);

    t = kabupaten.toLowerCase().includes('kab. '.toLowerCase()) ? removeString(t, removeString(kabupaten.substring(1), 'kab. ').replace(',', '') + ',') + ',' : t;
    t = removeString(t, removeString(kabupaten.substring(1), 'kota ').replace(',', '') + ',') + ',';
    t = removeString(t, propinsi.substring(1, propinsi.length - 6).replace(',', '').trim());
    t = removeString(t, propinsi.substring(propinsi.length - 5, propinsi.length).replace(',', ''))

    t = removeString(t, "kode pos");
    t = removeString(t, 'kota ,');
    t = removeString(t, 'kota,');
    t = removeString(t, 'kota .');
    t = removeString(t, 'kota.');
    t = removeString(t, 'kota ');
    t = removeString(t, 'kabupaten ,');
    t = removeString(t, 'kabupaten .');
    t = removeString(t, 'kabupaten,');
    t = removeString(t, 'kabupaten.');
    t = removeString(t, 'kabupaten ');
    t = removeString(t, 'provinsi ,');
    t = removeString(t, 'provinsi .');
    t = removeString(t, 'provinsi,');
    t = removeString(t, 'provinsi.');
    t = removeString(t, 'provinsi');
    t = removeString(t, 'propinsi ,');
    t = removeString(t, 'propinsi .');
    t = removeString(t, 'propinsi,');
    t = removeString(t, 'propinsi.');
    t = removeString(t, 'propinsi');
    t = removeString(t, 'kab. ,');
    t = removeString(t, 'kab. .');
    t = removeString(t, 'kab.');
    t = removeString(t, 'kab ,');
    t = removeString(t, 'kab,');
    t = removeString(t, 'kab .');
    t = removeString(t, 'kab ');

    let kecamatan = t.substring(t.lastIndexOf(', '), t.length);
    while (kecamatan.includes(',,')) {
      kecamatan = kecamatan.replace(',,', ',');
    }
    if (kecamatan.length > 25) kecamatan = '';
    else {
      t = t.substring(0, t.length - kecamatan.length);
      t = removeString(t, kecamatan.substring(1).replace(',', '').trim());
    }

    t = removeString(t, 'kecamatan ,');
    t = removeString(t, 'kecamatan .');
    t = removeString(t, 'kecamatan,');
    t = removeString(t, 'kecamatan.');
    t = removeString(t, 'kecamatan ');
    t = removeString(t, 'kec. ,');
    t = removeString(t, 'kec. .');
    t = removeString(t, 'kec.');
    t = removeString(t, 'kec ,');
    t = removeString(t, 'kec,');
    t = removeString(t, 'kec .');
    t = removeString(t, 'kec ');
    t = t.replace(/,(?=[^\s])/g, ", ");
    adr = t + kecamatan + kabupaten + propinsi;

    while (adr.startsWith(',')) {
      adr = adr.substring(1, adr.length).trim();
    }

    if (adr.toLocaleLowerCase().includes('jakarta')) {
      adr = removeString(adr, ', DKI JAKARTA');
      adr = removeString(adr, 'Kota Administrasi');
    }
    adr = adr.trim();
    adr = adr.replace(/,(?=[^\s])/g, ", ");
    while (adr.includes('  ')) {
      adr = adr.replace('  ', ' ').trim();
    }
    while (adr.includes(' ,')) {
      adr = adr.replace(' ,', ',').trim();
    }
    while (adr.includes(',,')) {
      adr = adr.replace(',,', ',');
    }
    while (adr.includes('.,')) {
      adr = adr.replace('.,', '.');
    }
    (inputName.current as unknown as HTMLInputElement).value = name;
    (inputTel.current as unknown as HTMLInputElement).value = tel;
    (inputAdr.current as unknown as HTMLInputElement).value = adr;
    (inputInfo.current as unknown as HTMLInputElement).value = note;
  }

  function setClientDescShopee(input: string) {
    if (input.includes(' Order SN: ')) {
      let code = input.substring(1, 3);
      let no = input.substring(input.indexOf(' Order SN: ') + 11, input.length - 1);
      let t = input.substring(0, input.length - no.length - 11 - 6);
      let telp = t.substring(t.indexOf(' Buyer phone: ') + 14, input.length);
      t = t.substring(0, t.length - telp.length - 14 - 5);
      let name = t.substring(t.indexOf(' Buyer name: ') + 13, input.length);
      t = t.substring(0, t.length - name.length - 13 - 5);
      let remark = t.substring(t.indexOf(' Buyer remark: ') + 15, input.length - 1);

      let note = remark.length > 0 ? ";" + remark : '';
      let n = " " + code + " Order SN:;" + no + ";------------------------------; Buyer:;" + name + ";" + telp + note;
      setClientInfo(n);
      (inputSaleNote.current as unknown as HTMLInputElement).value = note.substring(1);
    }
    else {
      let res = formatShopee(input);
      (inputName.current as unknown as HTMLInputElement).value = res.name;
      (inputTel.current as unknown as HTMLInputElement).value = res.tel;
      (inputAdr.current as unknown as HTMLInputElement).value = res.adr;
      (inputInfo.current as unknown as HTMLInputElement).value = res.info;
    }
  }

  function setClientDesc(e: CustomEvent<InputChangeEventDetail>) {
    try {
      sale.shop === TOKOPEDIA ? setClientDescTokopedia(e.detail.value as string) : sale.shop === SHOPEE ? setClientDescShopee(e.detail.value as string) : setClientDescBukalapak(e.detail.value as string);
    } catch (error) {
      alert(error);
    }
  }

  function setkurir(e: CustomEvent<FocusEvent>) {
    if (sale.shipmentNo.length > 0) {
      let resi = sale.shipmentNo;
      setKurirFromResi(resi, sale);
      (e.currentTarget as HTMLIonInputElement).value = resi;
      (selectKurirRef.current as unknown as HTMLIonSelectElement).value = sale.shipment;
    }
  }

  function setResi(e: CustomEvent<InputChangeEventDetail>) {
    let resi = e.detail.value as string;
    resi = resi.trim();
    while (resi.includes('#')) {
      resi = resi.replace('#', '').trim();
    }
    resi = resi.split(' ')[0].trim();
    sale.shipmentNo = resi;
    (e.currentTarget as HTMLIonInputElement).value = resi;
  }

  function selectStaff() {
    let select: AlertInput[] = [];
    for (var i = 0; i < users.length; i++) {
      select.push({
        type: 'radio',
        label: users[i]._id,
        value: users[i]._id,
        checked: user.staff === users[i]._id
      });
    }
    return select;
  }

  function addBankKonto() {
    let el = (inputSaleNote.current! as HTMLIonTextareaElement);
    let konto = shop.shop === BIAS ? BIASREK : shop.shop === MEGAKON ? MEGAKONREK : MEKUYAREK;
    el.value = el.value!.length > 0 ? konto + '. ' + el.value : konto;
  }

  useEffect(() => {
    if (isBias()) sale.shop = BIAS;
    setShop({ shop: sale.shop, reload: !shop.reload });
    setModeTrx(isPurchase(sale) ? LIST_PURCHASES.toString() : LIST_SALES.toString());
  }, []);

  function setShowToPrint({ name: n, list: l }: { name: string, list: Sale[] }) {
    if (l.length > 0) {
      setNPList({ name: n, list: l });
    }
    else setNPList({ name: n, list: getUserTrx(isBias() ? biassalesHistory : salesHistory, user.staff).toPrint });
    setShowNP(true);
  }

  function printItem(idx: number, item: SalesItem) {
    return <IonLabel key={idx} className="paddingt fullwidth smaller center">{item.quantity} | {item.item.ProductName}</IonLabel>;
  }

  function fill(s: Sale, idx: number) {
    return <SalesCard key={idx} sale={s} type={ULREMOVE} rel={updateUL} po={false} />;
  }

  function fillNP(s: Sale, idx: number) {
    return <SalesCard key={idx} sale={s} type={PAY} rel={updateUL} po={false} />;
  }

  function setTelp(input: HTMLIonInputElement, n: string) {
    if (n.startsWith('62')) {
      n = '0' + n.substring(2);
      input.value = n;
    }
    sale.client.telp = n;
  }

  function toFormat(sales: Sale[]) {
    let res = [];
    for (var i = 0; i < sales.length; i++) {
      res.push({ sale: sales[i], data: '' });
    }
    return res;
  }

  function printKurirCount(sales: Sale[]) {
    const shipdata = sortToShipReport(toFormat(sales));
    let text = '';
    if (shipdata) {
      for (var i = 0; i < shipdata.kurir.length; i++) {
        if (text.length > 0) text += ', ';
        text += shipdata.kurir[i] + '(' + shipdata.resi[i].length + ')';
      }
    }
    return text;
  }

  function generatePrintButtons() {
    /*if (printmode.state && !mprint && !isPO(sale!) && !isPurchase(sale)) {
      return (
        <IonButton className="fullwidth" onClick={() => generateReceiptLabel(sale!)}>
          <IonLabel>{STR_ThermalPrint}</IonLabel>
          <IonIcon slot="icon-only" icon={printOutline} />
        </IonButton>
      )
    }
    else */return (<>
      {isAdmin() && !isPurchase(sale) ? <IonButton className="fullwidth" onClick={e => popPrint(e, false)}>
        <IonCheckbox className="marginlr" slot="start" checked={wShippingL} onClick={e => { e.stopPropagation(); setWShippingL(!wShippingL) }} />
        <IonLabel>{STR_ThermalPrint}</IonLabel>
        <IonIcon slot="icon-only" icon={printOutline} />
      </IonButton> : ''}
      {
        !isPurchase(sale) && !isPO(sale) ?
          <IonButton className="fullwidth" onClick={e => popPrint(e, true)}>
            {isAdmin() ? <IonCheckbox className="marginlr" slot="start" checked={wlabels} onClick={e => { e.stopPropagation(); setWlabels(!wlabels) }} /> : ''}
            <IonLabel>{STR_Labels}</IonLabel>
            <IonIcon slot="icon-only" icon={listOutline} />
          </IonButton> : ''
      }
    </>);
  }

  function enableEdit(e: React.MouseEvent, k: courier) {
    e.stopPropagation();
    let el = e.currentTarget.previousElementSibling as HTMLInputElement;

    if (!el.disabled) {
      if (el.value) {
        if (!k.startsWith) { k.startsWith = []; }
        if (!k.startsWith.includes(el.value)) {
          k.startsWith.push(el.value);
          saveKurir(k);
        }
      }
      el.value = '';
      el.disabled = true;
      let ic = e.currentTarget.firstElementChild as HTMLIonIconElement;
      ic.icon = add;
      ic.color = "primary";
      setReload(!reload);
    }
    else {
      el.disabled = false;
      let ic = e.currentTarget.firstElementChild as HTMLIonIconElement;
      ic.icon = checkmark;
      ic.color = "success";
      el.focus();
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonLabel slot='start'>&nbsp;&nbsp;&nbsp;</IonLabel>
          {po.length > 0 ? <><IonButtons slot="end">
            <IonButton hidden={!isAdmin()} onClick={e => setShow(STR_All)}>
              <IonIcon icon={hourglassOutline} />
              <IonLabel className="marginr">{po.length}</IonLabel>
            </IonButton>
            {fillPOShop()}
          </IonButtons></> : ''}
          {isAdmin() ? <IonSegment value={mode.toString()}>
            <IonSegmentButton value={LIST_SALES.toString()} layout="icon-start" onClick={e => setModeTrx(e.currentTarget.value)}>
              <IonLabel>{isPlatform('hybrid') ? STR_Sale.charAt(0) : STR_Sale}</IonLabel>
              {isPlatform('hybrid') ? '' : <IonIcon icon={cart} />}
            </IonSegmentButton>
            {sale.shop === BIAS ? '' :
              <IonSegmentButton value={LIST_PURCHASES.toString()} layout="icon-start" onClick={e => setModeTrx(e.currentTarget.value)}>
                <IonLabel>{isPlatform('hybrid') ? STR_Purchase.charAt(0) : STR_Purchase}</IonLabel>
                {isPlatform('hybrid') ? '' : <IonIcon icon={basket} />}
              </IonSegmentButton>
            }
          </IonSegment> : <IonTitle className="center">{STR_Sale} <span className="risk">{user.staff}</span></IonTitle>}
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar ref={searchEl} slot="start" placeholder={STR_Name + "/" + STR_Key} onIonChange={e => searchItem(e, e.detail.value!)} ></IonSearchbar>
          <IonButton slot="end" fill="clear" size="large" onClick={openScanner} >
            <IonIcon icon={barcodeOutline} />
          </IonButton>
        </IonToolbar>
        <div className="suggestions hidden">
          <ItemList uRel={updateTotalEl} cat={ALL} data={sug} type={mode} sale={sale} />
        </div>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
        </IonHeader>
        <div>
          <SalesList mode={mode} sales={sale.items} sale={sale}></SalesList>
        </div>
        <IonButton ref={updateTotalEl} hidden onClick={updateNewTotal} />
        <IonGrid className={poMode ? 'bgPO' : !mprint && printmode.state ? 'bgPrintMode' : 'bgSale'}>
          <IonRow>{isPlatform("desktop") ? <IonCol size="3" /> : ''}
            <IonCol>
              <IonSegment value={payment === '' ? "pay" : payment}>
                <IonSegmentButton value="pay" layout="icon-start" onClick={e => setPayment(e.currentTarget.value!)}>
                  <IonLabel>{STR_Paid}</IonLabel>
                  <IonIcon icon={cashOutline} />
                </IonSegmentButton>
                <IonSegmentButton value="multipay" layout="icon-start" onClick={e => setPayment(e.currentTarget.value!)}>
                  <IonLabel>{STR_Instalments}</IonLabel>
                  <IonIcon icon={cardOutline} />
                </IonSegmentButton>
              </IonSegment>
            </IonCol>
          </IonRow>
          <IonRow>{isPlatform("desktop") ? <IonCol size="3" /> : ''}
            <IonCol class="total">
              <IonButton shape="round" size="small" color="danger" onClick={() => setCut()}><IonIcon icon={cut} /></IonButton>
              <label>&nbsp;&nbsp;{STR_Total} ({sale.items.length})</label>
            </IonCol><IonCol class="right">
              <label>{formatCurrency(getShop(shop.shop)?.currency!, tprice.total)}</label>
            </IonCol>
          </IonRow>
          <IonRow hidden={!tprice.rabatt}>{isPlatform("desktop") ? <IonCol size="3" /> : ''}
            <IonCol class="middle" size="3">
              <label>&nbsp;&nbsp;{STR_Discount}&nbsp;&nbsp;</label>
              <IonInput class="ion-text-end input" type="number" min="0" max="100" color="primary" placeholder="%" onIonBlur={e => clear(e)} onIonInput={e => clearInput(e)} onIonChange={e => setPercentageRabatt(e, e.detail.value!)} ></IonInput>
              <label>%=</label>
            </IonCol><IonCol class="end">
              <IonInput required class="ion-text-end input" type="number" min="0" color="primary" value={tprice.discount} placeholder="Discount" onIonChange={e => setAmountRabatt(e, Number.parseFloat(e.detail.value as string))}></IonInput>
            </IonCol>
          </IonRow>
          <IonRow hidden={!tprice.rabatt}>{isPlatform("desktop") ? <IonCol size="3" /> : ''}
            <IonCol class="total" size="3">
              <label>&nbsp;&nbsp;</label>
            </IonCol>
            <IonCol class="right">{formatCurrency(getShop(shop.shop)?.currency!, tprice.topay)}</IonCol>
          </IonRow>
          <IonRow hidden={payment !== 'multipay'}>{isPlatform("desktop") ? <IonCol size="3" /> : ''}
            <IonCol class="middle" size="3">
              <label>&nbsp;&nbsp;{STR_Initial}&nbsp;&nbsp;</label>
              <IonInput class="ion-text-end input" type="number" min="0" max="100" color="primary" placeholder="%" onIonBlur={e => clear(e)} onIonInput={e => clearInput(e)} onIonChange={e => setPercentageDP(e, e.detail.value!)}></IonInput>
              <label>%=</label>
            </IonCol><IonCol class="end">
              <IonInput required class="ion-text-end input" type="number" min="0" color="primary" value={tprice.dp} placeholder="Down Payment" onIonChange={e => setAmountDP(e, Number.parseFloat(e.detail.value as string))}></IonInput>
            </IonCol>
          </IonRow>
          <IonRow hidden={payment !== 'multipay'}>{isPlatform("desktop") ? <IonCol size="3" /> : ''}
            <IonCol class="total" size="3">
              <label>&nbsp;&nbsp;{STR_Debt}</label>
            </IonCol>
            <IonCol class="right">{formatCurrency(getShop(shop.shop)?.currency!, tprice.debt)}</IonCol>
          </IonRow>
          <IonRow>
            {isPlatform("desktop") ? <IonCol size="3" class="center"><div className='shop'><img alt='' src={shop.shop === '' ? '' : "assets/img/" + shop.shop + ".png"}></img></div></IonCol> : ''}
            <IonCol>
              {shops.map((shop, idx) => fillShop(shop._id, idx))}
              <IonChip outline={true} onClick={e => {
                e.stopPropagation();
                setShowYNAlert(STR_Shop);
              }}>+</IonChip>
              {mode === LIST_SALES || sale.shop === MEKUYAID ?
                <IonChip outline={true}><IonCheckbox className="marginlr" checked={bca} onClick={() => setBCA(true)} />
                  <IonLabel>{STR_BankPayment}</IonLabel></IonChip> : ''}
              <IonGrid><IonRow>
                <IonCol>
                  <IonLabel position="stacked" className="middle">
                    {STR_Shipment}
                    <IonButton className="marginlr" size="small" onClick={() => setShowPopover(1)}><IonIcon icon={pencil} /></IonButton>
                    <IonButton className="marginlr" size="small" onClick={() => setShowYNAlert(STR_Shipment)}><IonIcon icon={add} /></IonButton>
                    <IonButton className="marginlr" size="small" onClick={() => { sale.shipment = 'SPX Instant'; setReload(!reload) }}>{isPlatform('hybrid') ? 'SPX' : 'SPX Instant'}</IonButton>
                    <IonButton className="marginlr" size="small" onClick={() => { sale.shipment = 'Gojek'; setReload(!reload) }}>Gojek</IonButton>
                    <IonButton className="marginlr" size="small" onClick={() => { sale.shipment = 'Grab'; setReload(!reload) }}>Grab</IonButton>
                    <IonButton className="marginlr" size="small" onClick={() => { sale.shipment = ''; setReload(!reload) }}><IonIcon icon={refresh} /></IonButton>
                  </IonLabel>
                </IonCol>
              </IonRow>
                <IonRow>
                  <IonCol>
                    <IonSelect ref={selectKurirRef} interface="action-sheet" className="inputE fleft fullwidth" value={sale.shipment} onIonChange={e => { sale.shipment = e.detail.value; (kurirCB.current as unknown as HTMLIonCheckboxElement).checked = !selfPayCourier(sale.shipment) }}>
                      {kurir.map((k, idx) => <IonSelectOption key={idx} value={k._id}>{k._id}</IonSelectOption>)}
                    </IonSelect>
                    <div className="fleft middle small" >
                      <IonCheckbox ref={kurirCB} className="marginlr" value={STR_Cashless} checked={!selfPayCourier(sale.shipment)} onClick={() => toggleCashlessKurir(getCourier(sale.shipment)!)} />
                      <IonLabel>{STR_Cashless}</IonLabel>
                      <IonCheckbox className="marginlr" checked={sale.safepack} onClick={() => sale.safepack = !sale.safepack} />
                      <IonLabel>{STR_SAFE}</IonLabel>
                    </div>
                  </IonCol>
                  <IonCol className="smaller">
                    <div className="fullwidth quantity inputE"><IonInput placeholder={STR_Resi} value={sale.shipmentNo} onIonBlur={e => setkurir(e)} onIonChange={e => { setResi(e) }}></IonInput></div>
                  </IonCol>
                  <IonCol className="smaller">
                    <div className="clearboth fullwidth price inputE"><IonInput type="number" min="0" step="500" value={sale.shipping} onIonChange={e => sale.shipping = Number.parseFloat((e.detail.value as string).replace(".", ""))}>{isPlatform('desktop') ? STR_Cost + ' :' : ''}</IonInput></div>
                  </IonCol>
                  <IonCol className="smaller">
                    <div className="clearboth fullwidth price inputE"><IonInput type="number" min="0" step="500" value={sale.cod} onIonChange={e => sale.cod = Number.parseFloat((e.detail.value as string).replace(".", ""))}>{STR_COD} :</IonInput></div>
                  </IonCol></IonRow>
                <IonRow>
                  <IonCheckbox className="marginlr margint10" disabled={sale.dropshipper === '' || sale.dropshipper === DROPSHIPTAG} checked={showDSName} onClick={e => { (e.currentTarget.nextElementSibling as HTMLIonInputElement).value = sale.dropshipper.startsWith(DROPSHIPTAG) ? sale.dropshipper.substr(1) : DROPSHIPTAG + sale.dropshipper; setShowDSName(!showDSName) }} />
                  <IonInput ref={inputDropship} placeholder={STR_Dropshipper} onIonChange={e => { sale.dropshipper = e.detail.value as string; ((e.currentTarget as HTMLIonInputElement).previousElementSibling as HTMLIonCheckboxElement).disabled = sale.dropshipper === '' || sale.dropshipper === DROPSHIPTAG; }}></IonInput>
                </IonRow>
              </IonGrid>
              <div className="fullwidth">
                <IonToolbar className='resetZ paddingR10 bgtooltrans'>
                  <IonSearchbar slot="start" ref={inputKey} searchIcon={person} placeholder={STR_UserName} value={getPersonID(sale.client)} onIonInput={e => { sale.client._id = ((e.currentTarget as HTMLIonInputElement).value as string).trimStart() }} onIonChange={e => searchClient(e, (e.currentTarget as HTMLInputElement).value)}></IonSearchbar>
                  <IonButton hidden={shop.shop !== BIAS} slot="end" size="default" onClick={() => { sale.client = getContact(MEKUYAID)!; setClient(sale.client) }}><img className="icon marginr" alt={MEKUYAID} src={"assets/img/" + MEKUYAID + ".png"}></img></IonButton><br />
                  <IonButton slot="end" size="default" onClick={e => addClient(e)}><IonIcon icon={personAdd} /></IonButton><br />
                  <IonButton slot="end" size="default" onClick={() => setShowYNAlert(STR_Info)}><IonIcon icon={airplaneOutline} /></IonButton><br />
                  {isAdmin() ? <><IonButton slot="end" size="default" onClick={() => addBankKonto()}><IonIcon icon={cardOutline} /></IonButton><br /></> : ''}
                  {isPlatform("desktop") ? '' : <IonThumbnail className="middle center" slot="end"><img className="icon" alt='' src={shop.shop === '' ? '' : "assets/img/" + shop.shop + ".png"}></img></IonThumbnail>}
                </IonToolbar>
                <div ref={clientSugEl} hidden={psug.length === 0 || (psug.length === 1 && psug[0]._id === sale.client._id)} className="suggestions hidden posrelative">
                  <IonList>
                    {psug.map((c, idx) => <IonItem className="bgsug" button key={idx} onClick={() => setClient(c)}>{getPersonID(c)} : {c.name} | {c.telp} | {c.address}</IonItem>)}
                  </IonList>
                </div>
                {sale.shop === TOKOPEDIA || sale.shop === SHOPEE || sale.shop === BUKALAPAK ?
                  <div>
                    <IonInput color={sale.shop === TOKOPEDIA ? 'success' : sale.shop === SHOPEE ? 'warning' : 'danger'} placeholder={STR_Client + ' ' + STR_Address + ' ' + STR_Info} onIonChange={e => setClientDesc(e)}></IonInput>
                  </div> : ''}
                <div className="roundBorder inputE marginb">
                  <IonInput ref={inputName} placeholder={STR_Name} value={sale.client.name} onIonChange={e => sale.client.name = e.detail.value as string}></IonInput>
                  <IonInput ref={inputTel} inputmode="tel" type="number" placeholder={STR_tel} value={sale.client.telp} onIonChange={e => setTelp(e.currentTarget as HTMLIonInputElement, e.detail.value as string)}></IonInput>
                  <IonTextarea ref={inputAdr} placeholder={STR_Address} value={sale.client.address} onIonChange={e => sale.client.address = e.detail.value as string}></IonTextarea>
                  <IonTextarea ref={inputInfo} placeholder={STR_Info} value={sale.client.info} onIonChange={e => sale.client.info = e.detail.value as string}></IonTextarea>
                </div>
              </div>
              <IonTextarea ref={inputSaleInfo} placeholder={STR_Notes} value={sale.info} onIonChange={e => sale.info = e.detail.value as string}></IonTextarea>
              <IonTextarea ref={inputSaleNote} placeholder={STR_SaleNote} value={sale.note} onIonChange={e => sale.note = e.detail.value as string}></IonTextarea>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter className={poMode ? 'bgPO' : 'bgSale'}>
        <IonGrid>
          <IonRow>
            {isPlatform("desktop") ? <IonCol size="2" /> : ''}
            <IonCol class="center"><IonButton shape="round" color="danger" onClick={() => setShowYNAlert(STR_CancelSale)}><IonIcon icon={close} /></IonButton></IonCol>
            <IonCol class="center">{mode === LIST_PURCHASES ?
              <IonButton shape="round" color="warning" onClick={() => setShowYNAlert(STR_SaveExpenseSale)}><IonIcon icon={cashOutline} /><IonIcon icon={checkmark} /></IonButton>
              : sale.shop === BIAS ? '' : <IonButton shape="round" color="warning" onClick={() => setShowYNAlert(STR_SaveSale)}><IonIcon icon={cartOutline} /><IonIcon icon={checkmark} /></IonButton>}
            </IonCol>
            <IonCol class="center"><IonButton hidden={mode === LIST_SALES && !inStockForSale()} shape="round" color="success" onClick={() => setShowYNAlert(STR_SaveUpdateSale)}><IonIcon icon={checkmark} /></IonButton></IonCol>
          </IonRow>
        </IonGrid>
        <IonToolbar className="paddinglr bgtool">
          <IonLabel onClick={() => setShowToPrint({ name: STR_toPrint, list: [] })} slot="start" className="marginr">{STR_Last} : {last}{po.length > 0 ? ' | ' + getLastPO() : ''}{' | ' + getUserTrx(isBias() ? biassalesHistory : salesHistory, user.staff).toPrint.length}</IonLabel>
          <IonCheckbox className="marginlr" slot="start" checked={wShippingL} onClick={e => { e.stopPropagation(); setWShippingL(!wShippingL) }} />
          {isAdmin() || isBias() ? <IonButton slot="start" className={poMode ? "npaid" : "paid"} onClick={e => { e.stopPropagation(); setPOMode(!poMode) }}>
            <IonIcon slot="icon-only" icon={hourglassOutline} /></IonButton> : ''}
          <IonButton slot="start" className={DOMode ? "npaid" : "paid"} onClick={e => { e.stopPropagation(); setShowYNAlert(STR_DeliveryOrder) }}>
            <IonIcon slot="icon-only" icon={documentLockOutline} /></IonButton>
          <IonButton slot="start" color={printmode.state ? "primary" : "medium"} onClick={e => { e.stopPropagation(); setShowYNAlert(STR_print); (e.currentTarget as HTMLIonButtonElement).color = printmode.state ? "primary" : "medium"; setShop({ shop: sale.shop, reload: !shop.reload }) }}>
            <IonIcon slot="icon-only" icon={printSharp} />{printmode.list.length}</IonButton>
          <IonButton slot="start" color={mprint ? "primary" : "medium"} onClick={e => { e.stopPropagation(); toggleMPrint(); (e.currentTarget as HTMLIonButtonElement).color = mprint ? "primary" : "medium"; (toPrintButton.current! as HTMLIonButtonElement).hidden = !mprint || toPrint.length === 0; setShop({ shop: sale.shop, reload: !shop.reload }); }}>
            <IonIcon slot="icon-only" icon={checkboxOutline} /></IonButton>
          <IonButton slot="start" hidden={toSList.length < 1} className={toSList.length > 0 ? "npaid" : "paid"} onClick={() => setShowYNAlert(STR_dsList)}>
            <IonIcon slot="icon-only" icon={listOutline} />
            {isAdmin() ? <IonCheckbox className="marginlr" slot="start" checked={wlabels} onClick={e => { e.stopPropagation(); setWlabels(!wlabels) }} /> : ''}
            <IonIcon className="marginlr" icon={trashBinOutline} /></IonButton>
          <IonButton slot="start" ref={toPrintButton} hidden={!isAdmin() || (isAdmin() && !mprint) || toPrint.length === 0} onClick={() => printMultipleLabels()}>
            <IonIcon slot="icon-only" icon={printOutline} /><IonLabel>{toPrint.length}</IonLabel></IonButton>
          <IonButton slot="start" hidden={toSList.length < 1} onClick={() => { setShowYNAlert(STR_LoginUser) }}>
            <IonIcon slot="icon-only" icon={listOutline} />
            {isAdmin() ? <IonCheckbox className="marginlr" checked={wlabels} onClick={e => { e.stopPropagation(); setWlabels(!wlabels) }} /> : ''}
            {toSList.length > 0 ? <IonLabel>{toSList.length}</IonLabel> : ''}</IonButton>
          <IonSegment scrollable slot='end'>
            {getCurrentUserSavedList() ? getCurrentUserSavedList()!.map((l, idx) => <IonButton key={idx} onClick={e => { e.stopPropagation(); setShowToPrint(l) }}>
              <IonLabel>{isPlatform('desktop') ? l.name : l.name.substring(0, l.name.indexOf('/'))} [{l.list.length}]</IonLabel></IonButton>) : ''}
            <IonButton onClick={e => { e.stopPropagation(); setShowPopover(2) }}>
              <IonIcon slot="icon-only" icon={addOutline} /></IonButton>
          </IonSegment>
        </IonToolbar>
        <IonModal isOpen={showNP} swipeToClose={true} backdropDismiss={true} onDidDismiss={() => { setShowNP(false) }}>
          <IonHeader>
            <IonToolbar>
              <IonButton slot="start" onClick={() => setShowNP(false)}><IonIcon slot="icon-only" icon={close} /></IonButton>
              <IonTitle onClick={() => setShowYNAlert(STR_SaveList)}>{STR_printed} : {npList.name}</IonTitle>
              <IonButton hidden={npList.name === STR_toPrint} slot="end" color="warning" onClick={e => { e.stopPropagation(); setShowYNAlert(STR_Remove) }}><IonIcon icon={trash} /></IonButton>
              <IonButton slot="end" color="warning" onClick={e => { e.stopPropagation(); npList && npList.list.length > 0 ? toggleMPSales(npList.list, true, !(e.currentTarget.firstElementChild as HTMLIonCheckboxElement).checked) : removeSavedList(npList.name); setShop({ shop: sale.shop, reload: !shop.reload }); }}><IonCheckbox hidden={!isAdmin()} className="marginlr" slot="start" checked={true} onClick={e => e.stopPropagation()} />{npList.list.length}</IonButton>
              <IonButton slot="end" onClick={() => npList.list.length > 0 ? npList.name === STR_print || !mprint ? generateReceiptItemList(toFormat(npList.list), user.staff) : generateShipmentLabel(toFormat(npList.list), withdetails, false, '', printmode.state) : {}}><IonCheckbox slot="start" hidden={npList.name === STR_print} checked={withdetails} onClick={e => { e.stopPropagation(); setWithdetails(!withdetails) }} /><IonIcon slot="icon-only" icon={listOutline} /></IonButton>
              {getSelectKurir(npList.list, "end")}
            </IonToolbar>
          </IonHeader>
          <IonHeader className="TitleBar padding10">
            <IonLabel>{printKurirCount(npList.list)}</IonLabel>
          </IonHeader>
          <IonContent>
            <IonButton ref={updateUL} hidden onClick={() => { removeUserList(npList.name, toULRemove!); setToULRemove(null); updateList(); }} />
            <List data={npList.list} fill={npList.name === STR_toPrint ? fillNP : fill} />
          </IonContent>
          <IonFooter hidden={npList.name === STR_toPrint} className="DangerBar padding10">
            <IonLabel class="fulltext risk smaller center">{STR_WarningDoNOTUpdateHere}</IonLabel>
          </IonFooter>
        </IonModal>
        <IonModal isOpen={show !== ''} cssClass='printModal' swipeToClose={true} backdropDismiss={true} onDidDismiss={() => setShow('')}>
          <ListModal type={LIST_PO} shop={show} />
        </IonModal>
        <IonAlert cssClass={poMode ? 'addSaleAlert' : ''} isOpen={showYNAlert !== ''} onDidDismiss={() => setShowYNAlert('')} message={getMessage()}
          inputs={
            showYNAlert === STR_Shop ? [
              {
                name: 'shopname',
                placeholder: STR_Shop + ' ' + STR_Name
              }
            ]
              :
              showYNAlert === STR_SaveList ? [
                {
                  name: 'listname',
                  value: npList.name,
                  placeholder: STR_Name
                }
              ]
                : showYNAlert === STR_LoginUser && isAdmin() ? selectStaff()
                  :
                  showYNAlert === STR_Info ?
                    [
                      {
                        name: 'code',
                        //value: "PH",
                        placeholder: STR_Code
                      },
                      {
                        name: 'no',
                        placeholder: STR_No
                      },
                      {
                        name: 'name',
                        placeholder: STR_Name
                      },
                      {
                        name: 'telp',
                        placeholder: STR_tel
                      }
                      ,
                      {
                        name: 'note',
                        placeholder: STR_Note
                      }
                    ]
                    :
                    showYNAlert === STR_Shipment ? [
                      {
                        name: 'kurir',
                        placeholder: STR_Shipment
                      }
                    ] : []}
          buttons={[
            {
              text: printmode.state && showYNAlert === STR_print ? STR_CANCEL + ' & ' + STR_show + ' ' + STR_SaveList : STR_CANCEL,
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                if (printmode.state && showYNAlert === STR_print) {
                  setShowToPrint({ name: STR_print, list: printmode.list });
                }
              }
            }, {
              text: STR_OK,
              handler: (data) => {
                try {
                  if (showYNAlert.startsWith(STR_ChangeInventory)) {
                    let shopID = showYNAlert.substring(STR_ChangeInventory.length + STR_To.length + 2);
                    sale.items = [];
                    sale.total = 0;
                    setInventoryID(shopID === BIAS ? LIST_BIASINVENTORY : LIST_INVENTORY);
                    sale.shop = shopID;
                    updateNewTotal();
                    setShop({ shop: shopID, reload: shop.reload });
                  }
                  else if (showYNAlert.startsWith(STR_DeleteResiStart)) {
                    let resistart = showYNAlert.substring(STR_DeleteResiStart.length, showYNAlert.indexOf(' ['));
                    let kurirname = showYNAlert.substring(showYNAlert.indexOf(' [') + 2, showYNAlert.length - 1);
                    deleteResiStart(kurirname, resistart);
                  }
                  else if (showYNAlert.startsWith(STR_RemoveKurir)) {
                    let id = showYNAlert.substring(STR_RemoveKurir.length);
                    removeKurir(kurir, id);
                    setShowPopover(-1);
                  }
                  else {
                    switch (showYNAlert) {
                      case STR_dsList: {
                        clearSList();
                        break;
                      }
                      case STR_CancelSale: {
                        reset();
                        break;
                      }
                      case STR_SaveSale: {
                        markDropShip(sale);
                        poMode ? addPO(mode) : addSale(mode, sale.shop === BIAS ? LIST_BIASINVENTORY : LIST_INVENTORY);
                        break;
                      }
                      case STR_SaveUpdateSale: {
                        if (poMode) {
                          addPO(mode);
                        }
                        else {
                          let biaspurchasesale = sale;
                          updateInventory(mode, biaspurchasesale, biaspurchasesale.shop === BIAS ? LIST_BIASINVENTORY : LIST_INVENTORY);
                          addSale(mode, biaspurchasesale.shop === BIAS ? LIST_BIASINVENTORY : LIST_INVENTORY);
                          if (biaspurchasesale.shop === BIAS && biaspurchasesale.client._id === MEKUYAID) addPurchaseFromBias(biaspurchasesale);
                        }
                        break;
                      }
                      case STR_SaveExpenseSale: {
                        if (poMode) {
                          addPO(mode);
                        }
                        else {
                          updateInventory(mode, sale, LIST_INVENTORY);
                          addSale(mode, LIST_INVENTORY);
                          addExpense(printSale(sale), sale.total - sale.discount + sale.shipping);
                        }
                        break;
                      }
                      case STR_SaveClient: {
                        (clientSugEl.current as unknown as HTMLElement).style.display = "none";
                        saveClient(personToAdd);
                        setPersonToAdd(null as unknown as Person);
                        break;
                      }
                      case STR_Shipment: {
                        addKurir(data.kurir);
                        break;
                      }
                      case STR_Info: {
                        let code = ('' + data.code).trim().length === 0 ? '' : " " + data.code;
                        let buyer = ('' + data.name + data.telp).trim().length === 0 ? '' : ";------------------------------; Buyer:;" + data.name + ";" + data.telp;
                        let note = ('' + data.note).trim().length === 0 ? '' : ";" + data.note;
                        let n = code + " Order SN:;" + data.no + buyer + note;
                        setClientInfo(n);
                        break;
                      }
                      case STR_LoginUser: {
                        let userstaff = isAdmin() ? data : user.staff;
                        generateShipmentLabel(toSList, false, wlabels, userstaff, false);
                        break;
                      }
                      case STR_DeliveryOrder: {
                        clearMPrint();
                        clearSList();
                        setDOMode(!DOMode);
                        setShop({ shop: sale.shop, reload: !shop.reload });
                        break;
                      }
                      case STR_SaveList: {
                        renameSavedList(npList.name, data.listname);
                        setNPList({ name: data.listname, list: npList.list });
                        break;
                      }
                      case STR_Shop: {
                        addShop(data.shopname);
                        break;
                      }
                      case STR_Remove: {
                        removeSavedList(npList.name);
                        setNPList({ name: '', list: [] });
                        setShowNP(false);
                        break;
                      }
                      case STR_print: {
                        if (printmode.state && printmode.list.length > 0) {
                          generateReceiptItemList(toFormat(printmode.list), user.staff);
                        }
                        toggleprintMode();
                        break;
                      }
                      default: break;
                    }
                  }
                } catch (error) {
                  alert(error);
                }
              }
            }
          ]}></IonAlert>
        <IonModal ref={popover} isOpen={showPopover > -1} onDidDismiss={() => { setShowPopover(-1); reset() }}>
          {showPopover === 1 ? <IonHeader className='center middle'>{STR_Shipment}</IonHeader> : ''}
          <IonContent>
            {showPopover === 1 ? '' : showPopover === 2 ? '' : sale.shipmentNo === '' ? <IonLabel className="paddingt fullwidth warning center">{getNumberID(sale._id)} | {STR_EmptyResi}</IonLabel> :
              <IonLabel className="paddingt fullwidth risk center">{getNumberID(sale._id)} | {sale.shipment} {sale.shipmentNo}</IonLabel>}
            <IonLabel className="paddingt fullwidth center">{formatCurrency(getShop(shop.shop)?.currency!, sale.total + +sale.shipping)}</IonLabel>
            {sale.cod > 0 ? <IonLabel className={sale.cod > 0 ? "paddingt fullwidth center cod" : "paddingt fullwidth center"}>{STR_COD + ' : ' + formatCurrency(getShop(shop.shop)?.currency!, sale.cod)}</IonLabel> : ''}
            {sale.items.map((item, idx) => printItem(idx, item))}
            {showPopover === 0 ?
              <IonList className="margin10">
                {isPurchase(sale) ? <IonButton className="fullwidth" onClick={e => {
                  e.stopPropagation();
                  generatePurchasePDF(sale);
                }}><IonIcon slot="icon-only" icon={printOutline} /></IonButton>
                  : ''}
                {generatePrintButtons()}
              </IonList>
              : showPopover === 1 ?
                <>
                  <IonList>
                    {kurir.map((k, idx) =>
                      <IonItem key={idx}>
                        <IonLabel>
                          <div className='TitleBar padding10'>{k._id}</div>
                          <div className='showoverflow'>
                            {!k.startsWith ? '' : k.startsWith.map((startswith, i) => <IonChip key={i} outline={true} className='roundBorder borderblue inputE' onClick={e => {
                              e.stopPropagation();
                              setShowYNAlert(STR_DeleteResiStart + startswith + ' [' + k._id + ']');
                            }}>{startswith}</IonChip>)}
                          </div>
                          <div>
                            <IonChip outline={true} className='inputE'><IonInput className='inputE' disabled={true} required={true} placeholder='resi starts with'></IonInput>
                              <IonButton className='inputE' fill="clear" size="large" onClick={e => enableEdit(e, k)}><IonIcon icon={add} /></IonButton></IonChip>
                          </div>
                        </IonLabel>
                        <IonButton slot="end" onClick={() => { setShowYNAlert(STR_RemoveKurir + k._id) }}><IonIcon icon={trash} /></IonButton>
                      </IonItem>
                    )}
                  </IonList>
                  <IonButton expand='full' onClick={() => setShowPopover(-1)}>{STR_CANCEL}</IonButton>
                </>
                : showPopover === 2 ?
                  <IonContent>
                    <IonHeader className='center middle'>{STR_SaveList}</IonHeader>
                    <IonItem hidden={toPrint.length < 1 && toSList.length < 1}><IonLabel position="stacked">{STR_Name}</IonLabel><IonInput ref={savedListName}></IonInput></IonItem>
                    {toPrint.length < 1 && toSList.length < 1 ? <IonLabel className='center middle'>{STR_SelectSale}</IonLabel> : ''}
                    <IonList>
                      <IonButton hidden={toPrint.length < 1} onClick={() => { addSaveList((savedListName.current! as HTMLInputElement).value, toPrint); setShowPopover(-1) }}><IonIcon slot="start" icon={checkboxOutline} /><IonLabel>{toPrint.length}</IonLabel></IonButton>
                      <IonButton hidden={toSList.length < 1} onClick={() => { addSaveList((savedListName.current! as HTMLInputElement).value, toSList); setShowPopover(-1) }}><IonIcon slot="start" icon={listOutline} /><IonLabel>{toSList.length}</IonLabel></IonButton>
                    </IonList>
                    <IonButton expand='full' onClick={() => setShowPopover(-1)}>{STR_CANCEL}</IonButton>
                  </IonContent>
                  : ''
            }
          </IonContent></IonModal>
      </IonFooter>
    </IonPage >
  );
};

export default Tab2;
