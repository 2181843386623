import React, { useEffect, useRef, useState } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonMenuButton, IonLabel, IonInput, IonFooter, IonSegment, IonSegmentButton, IonButton, IonIcon, IonList, IonAlert, IonCheckbox, IonFab, IonFabButton, IonFabList, isPlatform, IonSelect, IonSelectOption, AlertInput, IonModal } from '@ionic/react';
import './Tab3.css';
import { STR_Log, STR_Transaction, STR_Fee, STR_OK, STR_CANCEL, STR_Paid, STR_All, STR_archive, STR_SelectCats, STR_Name, STR_New, STR_Add, STR_Edit, STR_Remove, STR_Chart, STR_Sales, STR_Profit } from '../lang/en';
import { addBooks, categories, checkboxList, deleteBook, editBook, editBookName, getBookCats, getCategoryNames, getCategoryNamesFull, getCategorySalesItems, getCatMTrx, getCurrentBook, getCurrentUserBooks, getCurrentUserShopKonto, getShop, getShopSalesItems, getShowList, getUser, isAdmin, kurir, LIST_SALES, makeSalesArchives, printDateMonth, printMonth, salesHistory, selectCourier, setAll, shops, toggleMPSales, toggleOne } from '../functions/DataFunctions';
import { setItemsImage, cleanSalesHistory, showPrevYear, updateAccount, saveSalesLocal, uploadDB, BOOK_ABENG, updateUser } from '../functions/LocalStorage';
import { Shop, Sale, formatCurrency } from '../functions/DataModel';
import SalesCard, { PAY } from '../components/SalesCard';
import { generatePDF, makeChart, printID } from '../functions/Output';
import { add, archiveOutline, arrowDown, arrowUp, barChartSharp, bicycleOutline, cashOutline, checkmark, close, cutOutline, ellipsisHorizontal, listCircleOutline, pencil, printOutline, saveOutline, send, trash } from 'ionicons/icons';
import { getExpense, payAll, profitBG, setProfit, updateSalesLog } from '../functions/Log';
import { MONTHRANGE } from '../components/ListModal';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js'
import { user } from '../components/AppContextProvider';
import { InputChangeEventDetail } from '@ionic/core';
Chart.register(ArcElement, Tooltip, Legend);


export var update = () => { };

export function getSelectKurir(data: Sale[], slot: string) {
  return (
    <IonFab vertical="bottom" horizontal="end" slot="fixed">
      <IonButton slot={slot} color="success">
        <IonIcon slot="start" icon={bicycleOutline} />
        <IonSelect interface="action-sheet" value={STR_All} onIonChange={e => selectCourier(e.detail.value, data)}>
          <IonSelectOption value={null}>{STR_All}</IonSelectOption>
          {kurir.map((k, idx) => <IonSelectOption key={idx} value={k}>{k._id}</IonSelectOption>)}
        </IonSelect>
      </IonButton>
    </IonFab>
  );
}

const Tab3: React.FC = () => {
  const curDate = new Date();

  const [selected, setSelected] = useState({ book: { name: '', cats: [] as string[] }, prevYear: false, data: [] as any[], reload: false });

  var limit = 10;
  const [items, setItems] = useState({ month: -1, list: [] as Sale[], page: 1, data: [] as Sale[], reload: false })

  const PAIDALL = 0;
  const ARCHIVE = 1;
  const NEU = 2;
  const EDIT = 3;
  const EDITNAME = 4;
  const DELETE = 5;
  const EDITMG = 6;
  const EDITMGProfit = 7;
  const [showYNAlert, setShowYNAlert] = useState(-1);

  const [showChart, setShowChart] = useState(false);
  const [range, setRange] = useState(0);

  const updateLogB = useRef(null);

  const [chartdata, setChartdata] = useState(makeChart(getCategorySalesItems(MONTHRANGE[range])));

  update = () => {
    setSelecteds(selected.book, true);
    updateChart(range);
  }

  const fill = (sale: Sale, idx: number) => {
    return (
      <SalesCard key={idx} sale={sale} type={PAY} rel={updateLogB} po={false} />)
  }

  function getMaxPage() {
    let m = Math.floor(items.list.length / limit);
    if (items.list.length % limit > 0) m = m + 1;
    return m;
  }

  function setList(month: number, list: any[], next: number, listlimit: any[], reload: boolean) {
    setItemsImage(listlimit).then(() =>
      setItems({ month: month, list: list, page: next, data: listlimit, reload: reload ? !items.reload : items.reload }));
  }

  function scroll(down: boolean) {
    let next = down ? items.page + 1 : items.page - 1;
    if (next > getMaxPage() || next < 0) next = 0;
    if (next > 0) {
      setList(items.month, items.list, next, getShowList(items.list, limit, next), false);
    }
  }

  function fillMonth(month: number, data: { trx: Sale[]; total: number; profit: number; topay: number; fee: number, toPrint: Sale[] }, cats: string[]) {
    let trx = data.trx;
    let total = data.total;
    let topay = data.topay;
    let profit = data.profit;
    let fee = data.fee;
    let needPrint = data.toPrint.length;
    if (trx.length > 0) {
      let date = new Date(trx[0].date);
      let expense = getExpense(date.getMonth(), date.getFullYear());

      const shop = getShop(trx[0].shop);
      return (
        <div key={month} className="fullwidth" onClick={e => { e.stopPropagation(); setMonth(month) }}>
          <div className="TitleBar padding10">
            <div className="fleft larger"><b>{trx[0].date.substring(0, 4) + ' ' + printMonth(month)}</b>{topay === 0 ? '' : " | D: " + formatCurrency(shop?.currency!, topay)}</div>
            <IonButton className="fright mTop-4px paid" slot="end" onClick={() => generatePDF(cats, trx, total, profit, topay)}>
              <IonIcon icon={printOutline} />
            </IonButton>
            {isPlatform('hybrid') ? '' : <><IonButton className="fright mTop-4px paid" slot="end" onClick={() => updateSalesLog(trx)}>
              <IonIcon icon={cashOutline} />
            </IonButton></>}
            {needPrint > 0 ? <IonButton className="printactive fright mTop-4px paid marginl" slot="end" onClick={e => { e.stopPropagation(); toggleMPSales(data.toPrint, true, !(e.currentTarget.firstElementChild as HTMLIonCheckboxElement).checked) }}>
              <IonCheckbox hidden={!isAdmin()} className="marginlr" slot="start" checked={true} onClick={e => e.stopPropagation()} />{needPrint}</IonButton> : ''}
            <div className={isPlatform('hybrid') ? "textr smaller fullwidth" : "textr smaller"}>{formatCurrency(shop?.currency!, total)} :T<br />
              <span hidden={true}>{formatCurrency(shop?.currency!, profit)} - <span className='warning'>{formatCurrency(shop?.currency!, fee)}</span> - <span className='risk'>{formatCurrency(shop?.currency!, expense.total)}</span> = </span><span className='available' onClick={e => { e.stopPropagation(); (e.currentTarget.previousElementSibling as HTMLElement).hidden = !(e.currentTarget.previousElementSibling as HTMLElement).hidden }}><span className={(profit - fee - expense.total) < 0 ? 'warning' : 'available'}>{formatCurrency(shop?.currency!, profit - fee - expense.total)}</span></span> :P</div>
          </div>
          <IonList hidden={items.month !== month} id={printID}>
            {items.month !== month ? '' :
              <>
                <IonButton className="no-print" hidden={items.page === 1} expand='full' onClick={e => { e.stopPropagation(); scroll(false) }}><IonIcon icon={arrowUp} /></IonButton>
                {items.data.map((item, idx) => fill(item, idx))}
                <IonButton className="no-print" hidden={items.page === getMaxPage() || items.list.length === 0} expand='full' onClick={e => { e.stopPropagation(); scroll(true) }}><IonIcon icon={arrowDown} /></IonButton>
              </>
            }
          </IonList>
        </div>
      );
    }
    //else return <div hidden={items.month !== month} key={month} className="placeholder">{STR_Empty}</div>;
  }

  function setPaidAll(list: { trx: Sale[]; total: number; profit: number; topay: number; fee: number }[]) {
    try {
      let upd: Sale[] = [];
      const now = new Date();
      list.forEach(m => {
        if (m.topay > 0) {
          payAll(m.topay, now);
          m.trx.forEach(t => {
            if (t.paid == null) {
              t.paid = now.toISOString();
              upd.push(t);
            }
          });
        }
      });
      saveSalesLocal(upd).then(() => { uploadDB(LIST_SALES); (updateLogB.current! as HTMLIonButtonElement).click() });
    } catch (error) {
      console.error(error);
    }
  }

  function getList(cats: string[]) {
    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{getCategoryNamesFull(cats)}</IonTitle>
            {cats.length > 2 ?
              <IonButton slot="end" onClick={e => { (e.target as HTMLIonButtonElement).disabled = true; setShowYNAlert(PAIDALL) }}>
                <IonIcon icon={send} />
              </IonButton>
              : ''}
          </IonToolbar>
        </IonHeader>
        {selected.data.map((data, i) => fillMonth((selected.data.length - 1 - i) % 12, data, cats))}
      </>
    );
  }

  function enableEdit(e: React.MouseEvent, shop: Shop) {
    e.stopPropagation();
    let el = e.currentTarget.previousElementSibling as HTMLInputElement;

    if (!el.disabled) {
      if ((e.currentTarget.firstElementChild as HTMLIonIconElement).icon === close && shop.fee !== Number.parseFloat(el.value)) {
        el.value = shop.fee.toString();
      }
      else { shop.fee = Number.parseFloat(el.value); updateAccount(shop); }
      el.disabled = true;
      let ic = e.currentTarget.firstElementChild as HTMLIonIconElement;
      ic.icon = pencil;
      ic.color = "primary";
    }
    else {
      el.disabled = false;
      let ic = e.currentTarget.firstElementChild as HTMLIonIconElement;
      if (shop.fee === Number.parseFloat(el.value)) {
        ic.icon = close;
        ic.color = "danger";
      }
      else {
        ic.icon = checkmark;
        ic.color = "success";
      }
      el.focus();
    }
  }

  function checkEdit(e: CustomEvent<InputChangeEventDetail>, shop: Shop) {
    e.stopPropagation();
    let el = e.currentTarget as HTMLInputElement;
    let ic = el.nextElementSibling!.firstElementChild as HTMLIonIconElement;

    if (shop.fee === Number.parseFloat(el.value)) {
      ic.icon = close;
      ic.color = "danger";
    }
    else {
      ic.icon = checkmark;
      ic.color = "success";
    }
  }

  //  <IonInput disabled={true} value={shop.fee} type="number" min="0" onIonChange={e => checkEdit(e, shop)}></IonInput>
  //  <IonButton slot="end" fill="clear" onClick={e => enableEdit(e, shop)}><IonIcon icon={pencil} /></IonButton>

  function fillShop(shop: Shop, idx: number) {
    const imgpath = "assets/img/" + shop._id + ".png";
    return (
      <td className="textcenter" key={idx}><>
        <IonButton fill="clear" onClick={e => { (e.currentTarget.nextElementSibling as unknown as HTMLInputElement).disabled = false; ((e.currentTarget.nextElementSibling as unknown as HTMLInputElement).nextElementSibling as HTMLElement).hidden = false }}><img alt={shop._id.toUpperCase()} src={imgpath} width="30px" height="30px"></img></IonButton>
        <IonInput disabled={true} value={shop.fee} type="number" min="0"></IonInput>
        <div hidden={true}>
          <IonButton fill="clear" color="success" onClick={e => { shop.fee = Number.parseFloat((e.currentTarget.parentElement!.previousElementSibling as HTMLInputElement).value); updateAccount(shop); (e.currentTarget.parentElement!.previousElementSibling as HTMLInputElement).disabled = true; e.currentTarget.parentElement!.hidden = true }}><IonIcon icon={checkmark} /></IonButton>
          <IonButton fill="clear" color="danger" onClick={e => { (e.currentTarget.parentElement!.previousElementSibling as HTMLInputElement).value = shop.fee.toString(); (e.currentTarget.parentElement!.previousElementSibling as HTMLInputElement).disabled = true; e.currentTarget.parentElement!.hidden = true }}><IonIcon icon={close} /></IonButton>
        </div>
      </>
      </td>
    );
  }

  function fillShopFee() {
    return <IonToolbar>
      <IonSegment scrollable color="success">
        {shops.map((shopname, idx) => getCurrentUserShopKonto().includes(shopname._id) ? <>
          <IonSegmentButton key={idx} onClick={e => { (e.currentTarget.firstElementChild?.nextElementSibling as unknown as HTMLInputElement).disabled = false; (e.currentTarget.lastElementChild as HTMLElement).hidden = false }}><img alt={shopname._id.toUpperCase()} src={"assets/img/" + shopname._id + ".png"} width="30px" height="30px"></img>
            <IonInput disabled={true} className='inputE' value={shopname.fee} type="number" min="0"></IonInput>
            <div hidden={true}>
              <IonButton fill="clear" color="success" onClick={e => { e.stopPropagation(); shopname.fee = Number.parseFloat((e.currentTarget.parentElement!.previousElementSibling as HTMLInputElement).value); updateAccount(shopname); (e.currentTarget.parentElement!.previousElementSibling as HTMLInputElement).disabled = true; e.currentTarget.parentElement!.hidden = true }}><IonIcon icon={checkmark} /></IonButton>
              <IonButton fill="clear" color="danger" onClick={e => { e.stopPropagation(); (e.currentTarget.parentElement!.previousElementSibling as HTMLInputElement).value = shopname.fee.toString(); (e.currentTarget.parentElement!.previousElementSibling as HTMLInputElement).disabled = true; e.currentTarget.parentElement!.hidden = true }}><IonIcon icon={close} /></IonButton>
            </div>
          </IonSegmentButton>
        </> : '')}
      </IonSegment>
    </IonToolbar>;
  }


  function setSelecteds(book: { name: string, cats: string[] }, reload: boolean) {
    let filteredData = getBookList(book, selected.prevYear);
    setSelected({ book: book, prevYear: selected.prevYear, data: filteredData, reload: reload ? !selected.reload : selected.reload });
    setList(curDate.getMonth(), filteredData[0].trx, 1, getShowList(filteredData[0].trx, limit, 1), reload);
  }

  function togglePreyYear() {
    let filteredData = getBookList(selected.book, !selected.prevYear);
    setSelected({ book: selected.book, prevYear: !selected.prevYear, data: filteredData, reload: selected.reload });
    setList(curDate.getMonth(), filteredData[0].trx, 1, getShowList(filteredData[0].trx, limit, 1), false);
  }

  function setMonth(month: number) {
    if (month !== items.month) {
      let filteredData = selected.data;
      let m = filteredData.length - 1 - month;
      m = m % 12;
      setList(month, filteredData[m].trx, 1, getShowList(filteredData[m].trx, limit, 1), false);
    }
  }

  function getBookList(book: { name: string, cats: string[] }, prevYear: boolean) {
    let monthL: { trx: Sale[]; total: number; profit: number; topay: number; fee: number, toPrint: Sale[] }[] = [];
    if (prevYear) {
      for (var j = 0; j < 12; j++) {
        monthL.unshift(getCatMTrx(salesHistory, book.cats, j, curDate.getFullYear() - 1));
      }
    }
    for (var i = 0; i <= curDate.getMonth(); i++) {
      monthL.unshift(getCatMTrx(salesHistory, book.cats, i, curDate.getFullYear()));
    }
    return monthL;
  }

  function selectCats(n: number) {
    let select: AlertInput[] = [];
    for (var i = 0; i < categories.length; i++) {
      select.push({
        type: 'checkbox',
        label: categories[i].name,
        value: categories[i]._id,
        checked: n === EDIT ? selected.book.cats.includes(categories[i]._id) : getBookCats(BOOK_ABENG).includes(categories[i]._id)
      });
    }
    return select;
  }

  function getMessage() {
    switch (showYNAlert) {
      case PAIDALL: return STR_Paid + ' ' + STR_All + '?';
      case ARCHIVE: return STR_archive;
      case NEU: return STR_Add + ' ' + STR_New;
      case EDIT: return STR_SelectCats;
      case EDITNAME: return STR_Edit + ' ' + STR_Name;
      case DELETE: return STR_Remove + ' "' + selected.book.name + '" ?';
      default: return '';
    }
  }

  function setChecked(key: string) {
    if (key === checkboxList[0].val) {
      setAll(!checkboxList[0].isChecked);
      if (!checkboxList[0].isChecked) { update(); return; }
    }
    else {
      toggleOne(key);
    }
    updateChart(range);
  }

  function updateChart(range: number) {
    setChartdata(makeChart(getCategorySalesItems(MONTHRANGE[range])));
  }

  useEffect(() => {
    if (getCurrentUserBooks().length > 0) setSelecteds(getCurrentUserBooks()[0], true);
  }, []);

  /**
   * <div className="margint marginlr fullwidth">
          <table><tbody><tr><td><span className="small marginr">{STR_Fee}:</span></td>
            {shops.map((shopname, idx) => getCurrentUserShopKonto().includes(shopname._id) ? fillShop(shopname, idx) : '')}
          </tr></tbody></table>
        </div>

   */

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{STR_Transaction} {STR_Log}</IonTitle>
          <IonButton slot='end' fill="clear" className="marginr maxw30" onClick={() => setShowYNAlert(EDITMGProfit)}>{isPlatform('hybrid') ? '' : <img className="icon marginr" src={"assets/img/mekuya.png"} />}<IonLabel className='risk smaller wrap-text'>{profitBG}</IonLabel></IonButton>
          <IonButton slot='end' fill="clear" className="marginr maxw30" onClick={() => setShowYNAlert(EDITMG)}>{isPlatform('hybrid') ? '' : <img className="icon marginr" src={"assets/img/mekuya.png"} />}<IonLabel className='risk smaller wrap-text'>[{getCategoryNames(getBookCats(BOOK_ABENG))}]</IonLabel></IonButton>
          <IonCheckbox slot="end" className="marginr" checked={selected.prevYear} onClick={e => { e.stopPropagation(); togglePreyYear() }} />
          <IonLabel slot="end" className="marginr">{curDate.getFullYear() - 1}</IonLabel>
          {isPlatform('hybrid') ? '' : <>
            <IonButton slot='end' className="marginr" onClick={() => showPrevYear(new Date().getFullYear() - 1)}><IonIcon icon={archiveOutline} />1</IonButton>
            <IonButton slot='end' className="marginr" onClick={() => setShowYNAlert(ARCHIVE)}><IonIcon icon={cutOutline} /></IonButton>
            <IonButton slot='end' className="marginr" onClick={() => makeSalesArchives()}><IonIcon icon={saveOutline} /></IonButton>
          </>}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonButton ref={updateLogB} hidden onClick={() => update()} />
        {fillShopFee()}
        {getList(selected.book.cats)}
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton color="success" >
            <IonIcon icon={ellipsisHorizontal} />
          </IonFabButton>
          <IonFabList side="start">
            {selected.book.name.length > 0 ? <>
              <IonFabButton color="success" onClick={() => setShowChart(true)}><IonIcon icon={barChartSharp}></IonIcon></IonFabButton>
              <IonFabButton color="success" onClick={() => setShowYNAlert(EDIT)}><IonIcon icon={listCircleOutline}></IonIcon></IonFabButton>
              <IonFabButton color="success" onClick={() => setShowYNAlert(EDITNAME)}><IonIcon icon={pencil}></IonIcon></IonFabButton>
            </>
              : ''}
            {selected.book.name.length > 0 && getCurrentUserBooks().length > 1 ? <IonFabButton color="success" onClick={() => setShowYNAlert(DELETE)}><IonIcon icon={trash}></IonIcon></IonFabButton> : ''}
            <IonFabButton color="success" onClick={() => setShowYNAlert(NEU)}><IonIcon icon={add}></IonIcon></IonFabButton>
          </IonFabList>
        </IonFab>
        {/*getSelectKurir(items.data, '')*/}
      </IonContent>
      <IonFooter>
        <IonSegment value={selected.book.name}>
          {getCurrentUserBooks().map((book, idx) =>
            <IonSegmentButton key={idx} value={book.name} onClick={() => setSelecteds(book, false)}>
              <IonLabel>{book.name}</IonLabel>
            </IonSegmentButton>
          )}
        </IonSegment>
        <IonModal isOpen={showChart} swipeToClose={false} backdropDismiss={false} onDidDismiss={() => { setShowChart(false) }}>
          <IonHeader className="no-print">
            <IonToolbar>
              <IonButton slot="start" onClick={() => setShowChart(false)}><IonIcon slot="icon-only" icon={close} /></IonButton>
              <IonTitle>{STR_Sales} {STR_Chart}</IonTitle>
              <IonButton slot="end" onClick={() => window.print()}><IonIcon slot="icon-only" icon={printOutline} /></IonButton>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <div className="TitleBar"><IonSelect className="middle center fullwidth" slot="end" interface="action-sheet" value={range} onIonChange={e => { e.stopPropagation(); setRange(e.detail.value); updateChart(e.detail.value) }}>
              {MONTHRANGE.map((d, idx) => <IonSelectOption key={idx} value={MONTHRANGE.indexOf(d)}>{printDateMonth(d.toISOString())}</IonSelectOption>)}
            </IonSelect></div>
            <div className="padding10 no-print">{checkboxList.map(({ val, name, isChecked }, idx) =>
              <div className="fleft middle" key={idx}>
                <IonCheckbox className="marginlr" slot="start" value={val} checked={isChecked} onClick={() => setChecked(val)} />
                <IonLabel>{name}</IonLabel>
              </div>
            )}</div>
            <Pie className="padding10" data={chartdata} options={{
              plugins: {
                legend: {
                  display: true,
                  labels: {
                    font: {
                      size: 12,
                    },
                  },
                },
              },
              maintainAspectRatio: true
            }} />
            <Pie className="padding10" data={makeChart(getShopSalesItems(MONTHRANGE[range]))} options={{
              plugins: {
                legend: {
                  display: true,
                  labels: {
                    font: {
                      size: 12,
                    },
                  },
                },
              },
              maintainAspectRatio: true
            }} />
          </IonContent>
        </IonModal>
        <IonAlert isOpen={showYNAlert !== -1} onDidDismiss={() => setShowYNAlert(-1)} message={getMessage()}
          inputs={showYNAlert === EDITMGProfit ?
            [
              {
                name: 'profitmg',
                placeholder: STR_Profit,
                value: profitBG
              }
            ]
            : showYNAlert === EDIT ? selectCats(EDIT)
              : showYNAlert === EDITMG ? selectCats(EDITMG)
                : showYNAlert === NEU || showYNAlert === EDITNAME ? [
                  {
                    name: 'bookname',
                    placeholder: STR_Name,
                    value: showYNAlert === EDITNAME ? selected.book.name : ''
                  }]
                  : []}
          buttons={[
            {
              text: STR_CANCEL,
              role: 'cancel',
              cssClass: 'secondary',
            }, {
              text: STR_OK,
              handler: (data) => {
                try {
                  if (showYNAlert === PAIDALL) {
                    setPaidAll(selected.data)
                  }
                  else if (showYNAlert === ARCHIVE) {
                    cleanSalesHistory();
                  }
                  else if (showYNAlert === NEU) {
                    if (data.bookname.length > 0) {
                      let b = { name: data.bookname, cats: [] };
                      addBooks(b);
                      setSelecteds(b, true);
                    }
                  }
                  else if (showYNAlert === EDIT) {
                    editBook(selected.book.name, data);
                    setSelecteds({ name: selected.book.name, cats: data }, true);
                  }
                  else if (showYNAlert === EDITNAME) {
                    if (data.bookname.length > 0) {
                      editBookName(selected.book.name, data.bookname);
                      setSelecteds({ name: data.bookname, cats: selected.book.cats }, true);
                    }
                  }
                  else if (showYNAlert === DELETE) {
                    deleteBook(selected.book.name);
                    setSelecteds(getCurrentBook(), true);
                  }
                  else if (showYNAlert === EDITMG) {
                    let u = getUser(user.staff);
                    if (u) {
                      u.isMGProvided = data;
                      updateUser(u);
                      update();
                    }
                  }
                  else if (showYNAlert === EDITMGProfit) {
                    setProfit(data.profitmg);
                    update();
                  }
                } catch (error) {
                  alert(error);
                }
              }
            }
          ]
          }></IonAlert>
      </IonFooter>
    </IonPage>
  );
};

export default Tab3;
